import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { Image, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import BulkImportBlock from '../components/BulkImportBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { isOnboarding: true };

const AuthContactsImportScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Contacts Import');
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (!isLoggedIn(Variables)) {
        return;
      }
      /* hidden 'If/Else' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={true}>
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            flex: 1,
            justifyContent: [
              { minWidth: Breakpoints.Desktop, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'space-between' },
            ],
            marginLeft: 20,
            marginRight: 20,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <BulkImportBlock
          onComplete={() => {
            try {
              navigation.navigate('AddressesScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        />
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'space-evenly', marginBottom: 20 },
            dimensions.width
          )}
        >
          {/* SkipButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('AddressesScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors.background.brand,
                color: theme.colors.text.light,
                flexDirection: 'row',
                fontFamily: 'Poppins_500Medium',
                justifyContent: 'center',
                marginTop: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'SKIP'}
          >
            {'Sign Up'}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthContactsImportScreen);
