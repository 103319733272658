import React from 'react';
import {
  CheckboxRow,
  Icon,
  MultiSelectPicker,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Table,
  TableCell,
  TableRow,
  withTheme,
} from '@draftbit/ui';
import { H3 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Charts from '../custom-files/Charts';
import formatDate from '../global-functions/formatDate';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { promotion_id: 1112 };

const PromotionStatsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [aggregates, setAggregates] = React.useState({});
  const [selectedAgeGroups, setSelectedAgeGroups] = React.useState([
    'unknown',
    '0-18',
    '18-30',
    '30-50',
    '50+',
  ]);
  const [selectedGenders, setSelectedGenders] = React.useState([
    'male',
    'female',
    'other',
    'prefer-not-to-say',
  ]);
  const [selectedStatsType, setSelectedStatsType] = React.useState([
    'clicked',
    'viewed',
    'received',
  ]);
  const toggleAction = action => {
    if (selectedStatsType.includes(action)) {
      if (selectedStatsType.length === 1) return;
      setSelectedStatsType(prev => prev.filter(item => item !== action));
    } else {
      setSelectedStatsType(prev => [...prev, action]);
    }
  };

  const convertFetchToDataset = data => {
    const dataset = data.filter(d => selectedStatsType.includes(d.action));

    const filteredDataset = dataset
      .filter(d => selectedGenders.includes(d.gender))
      .filter(d => selectedAgeGroups.includes(d.age_group));

    const uniqueActions = [
      ...new Set(filteredDataset.map(item => item.action)),
    ];
    const uniqueDates = [
      ...new Set(filteredDataset.map(item => item.date_created)),
    ];

    const groupedByAction = uniqueActions.map((action, index) => {
      const data = uniqueDates.map(date => {
        const item = filteredDataset.reduce((acc, cur) => {
          if (cur.action === action && cur.date_created === date) {
            return acc + cur.count;
          }
          return acc;
        }, 0);
        return item || 0;
      });
      return {
        data,
        color: (opacity = 1) => {
          const hue = Math.floor((360 * index) / filteredDataset.length);
          const pastelSaturation = 70;
          const pastelLightness = 90;
          return `hsla(${hue}, ${pastelSaturation}%, ${pastelLightness}%, ${opacity})`;
        },
      };
    });
    return groupedByAction;
  };

  const convertFetchToLabels = data => {
    const dataset = data.filter(d => selectedStatsType.includes(d.action));

    const filteredDataset = dataset
      .filter(d => selectedGenders.includes(d.gender))
      .filter(d => selectedAgeGroups.includes(d.age_group));

    return [...new Set(filteredDataset.map(item => item.date_created))];
  };

  const groupFetchByDate = dataset => {
    const filteredDataset = dataset
      .filter(d => selectedGenders.includes(d.gender))
      .filter(d => selectedAgeGroups.includes(d.age_group));

    const groupObj = filteredDataset.reduce((acc, curr) => {
      const { date_created, action, count } = curr;
      if (!acc[date_created]) acc[date_created] = {};
      acc[date_created][action] = acc[date_created][action]
        ? acc[date_created][action] + count
        : count;
      return acc;
    }, {});

    const result = Object.keys(groupObj).map(date => {
      return {
        dateText: date,
        ...groupObj[date],
      };
    });

    return result;
  };

  const groupByAction = (dataset, genders, ageGroups) => {
    const filteredDataset = dataset
      .filter(d => (genders || selectedGenders).includes(d.gender))
      .filter(d => (ageGroups || selectedAgeGroups).includes(d.age_group));

    const groupObj = filteredDataset.reduce((acc, curr) => {
      const { action, count } = curr;
      if (!acc[action]) acc[action] = 0;

      acc[action] = acc[action] ? acc[action] + count : count;
      return acc;
    }, {});

    console.log(groupObj);

    //   const result=Object.keys(groupObj).map(date => {
    //     return {
    //       dateText: date,
    //       ...groupObj[date],
    //     };
    //   })

    return groupObj;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role === 'user') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Promotion Stats');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'mypromotions'} withGoBack={false} />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle 2 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <>
              {!!null ? null : (
                <Pressable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PressableStyles(theme)['BackButton'].style,
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App['Custom Color_9']}
                      name={'Ionicons/chevron-back'}
                      size={28}
                    />
                  </View>
                </Pressable>
              )}
            </>
          </View>
        )}
      </>
      <PromotionsServiceApi.FetchGetPromotionStatsGET
        handlers={{
          onData: fetchData => {
            try {
              setAggregates(
                groupByAction(
                  fetchData?.stats,
                  selectedGenders,
                  selectedAgeGroups
                )
              );
            } catch (err) {
              console.error(err);
            }
          },
        }}
        promotion_id={
          props.route?.params?.promotion_id ?? defaultProps.promotion_id
        }
      >
        {({ loading, error, data, refetchGetPromotionStats }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DashboardRow'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DashboardRow'].style,
                    {
                      alignItems: 'flex-start',
                      alignSelf: {
                        minWidth: Breakpoints.Desktop,
                        value: 'center',
                      },
                      backgroundColor: {
                        minWidth: Breakpoints.Desktop,
                        value: theme.colors.background.brand,
                      },
                      flexDirection: 'column',
                      marginLeft: { minWidth: Breakpoints.Desktop, value: 0 },
                      marginRight: { minWidth: Breakpoints.Desktop, value: 0 },
                      marginTop: { minWidth: Breakpoints.Desktop, value: 0 },
                      maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
                      paddingLeft: { minWidth: Breakpoints.Desktop, value: 12 },
                      paddingRight: {
                        minWidth: Breakpoints.Desktop,
                        value: 12,
                      },
                      paddingTop: { minWidth: Breakpoints.Desktop, value: 12 },
                      width: { minWidth: Breakpoints.Desktop, value: '100%' },
                    }
                  ),
                  dimensions.width
                )}
              >
                <H3
                  selectable={false}
                  {...GlobalStyles.H3Styles(theme)['H3'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.H3Styles(theme)['H3'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.title}
                </H3>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {formatDate(fetchData?.created_at)}
                  {' - '}
                  {formatDate(fetchData?.expires_at)}
                </Text>
              </View>
              {/* Body */}
              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: {
                      minWidth: Breakpoints.Desktop,
                      value: 'center',
                    },
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.CustomGray,
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: theme.colors.background.brand,
                      },
                    ],
                    flex: 1,
                    maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
                    width: { minWidth: Breakpoints.Desktop, value: '100%' },
                  },
                  dimensions.width
                )}
              >
                {/* Row 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 0, marginRight: 0, zIndex: 10 },
                    dimensions.width
                  )}
                >
                  {/* Col */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['DashboardCol'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['DashboardCol'].style,
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Desktop,
                            value: theme.colors.background.brand,
                          },
                          borderRadius: 0,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Row */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Tablet,
                            value: 'flex-start',
                          },
                          flexDirection: [
                            { minWidth: Breakpoints.Tablet, value: 'row' },
                            { minWidth: Breakpoints.Mobile, value: 'column' },
                          ],
                          justifyContent: {
                            minWidth: Breakpoints.Tablet,
                            value: 'space-between',
                          },
                          marginBottom: {
                            minWidth: Breakpoints.Desktop,
                            value: 12,
                          },
                          zIndex: 15,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Cart Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: { minWidth: Breakpoints.Desktop, value: 1 } },
                          dimensions.width
                        )}
                      >
                        {/* CardTitle */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['Custom black'],
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Promotion Statistics'}
                        </Text>
                      </View>
                      {/* Filters */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: { minWidth: Breakpoints.Desktop, value: 1 },
                            zIndex: 15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Tablet,
                                value: 'row',
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Desktop,
                                value: 'space-between',
                              },
                              width: [
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Mobile, value: 140 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Pressable
                            onPress={() => {
                              try {
                                toggleAction('received');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* ReceivedCheckbox */}
                            <CheckboxRow
                              {...GlobalStyles.CheckboxRowStyles(theme)[
                                'Checkbox Row'
                              ].props}
                              defaultValue={selectedStatsType.includes(
                                'received'
                              )}
                              direction={'row-reverse'}
                              disabled={true}
                              label={'Sent Count'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ].style,
                                dimensions.width
                              )}
                            />
                          </Pressable>
                          {/* Pressable 2 */}
                          <Pressable
                            onPress={() => {
                              try {
                                toggleAction('viewed');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* ViewedCheckbox */}
                            <CheckboxRow
                              {...GlobalStyles.CheckboxRowStyles(theme)[
                                'Checkbox Row'
                              ].props}
                              defaultValue={selectedStatsType.includes(
                                'viewed'
                              )}
                              direction={'row-reverse'}
                              disabled={true}
                              label={'View Count'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ].style,
                                dimensions.width
                              )}
                            />
                          </Pressable>
                          {/* Pressable 3 */}
                          <Pressable
                            onPress={() => {
                              try {
                                toggleAction('clicked');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* ClickedCheckbox */}
                            <CheckboxRow
                              {...GlobalStyles.CheckboxRowStyles(theme)[
                                'Checkbox Row'
                              ].props}
                              defaultValue={selectedStatsType.includes(
                                'clicked'
                              )}
                              direction={'row-reverse'}
                              disabled={true}
                              label={'Click Count'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ].style,
                                dimensions.width
                              )}
                            />
                          </Pressable>
                        </View>
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'column',
                                },
                                { minWidth: Breakpoints.Tablet, value: 'row' },
                              ],
                              justifyContent: 'space-evenly',
                              zIndex: {
                                minWidth: Breakpoints.Desktop,
                                value: 999,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* GenderPicker */}
                          <MultiSelectPicker
                            autoDismissKeyboard={true}
                            dropDownBackgroundColor={
                              theme.colors.background.brand
                            }
                            dropDownBorderColor={theme.colors.border.brand}
                            dropDownBorderRadius={8}
                            dropDownBorderWidth={1}
                            dropDownTextColor={theme.colors.text.strong}
                            iconSize={24}
                            leftIconMode={'inset'}
                            onValueChange={newGenderPickerValue => {
                              try {
                                setSelectedGenders(newGenderPickerValue);
                                /* hidden 'Refetch Data' action */
                                setAggregates(
                                  groupByAction(
                                    fetchData?.stats,
                                    newGenderPickerValue,
                                    selectedAgeGroups
                                  )
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            selectedIconColor={theme.colors.text.strong}
                            selectedIconName={'Feather/check'}
                            selectedIconSize={20}
                            leftIconName={
                              'MaterialCommunityIcons/gender-male-female-variant'
                            }
                            options={[
                              { label: 'Male', value: 'male' },
                              { label: 'Female', value: 'female' },
                              { label: 'Other', value: 'other' },
                              {
                                label: 'Prefer not to say',
                                value: 'prefer-not-to-say',
                              },
                            ]}
                            placeholder={'Gender'}
                            style={StyleSheet.applyWidth(
                              {
                                fontFamily: 'Poppins_200ExtraLight',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                            type={'underline'}
                            value={selectedGenders}
                          />
                          {/* AgeGroupPicker */}
                          <MultiSelectPicker
                            autoDismissKeyboard={true}
                            dropDownBackgroundColor={
                              theme.colors.background.brand
                            }
                            dropDownBorderColor={theme.colors.border.brand}
                            dropDownBorderRadius={8}
                            dropDownBorderWidth={1}
                            dropDownTextColor={theme.colors.text.strong}
                            iconSize={24}
                            leftIconMode={'inset'}
                            onValueChange={newAgeGroupPickerValue => {
                              try {
                                setSelectedAgeGroups(newAgeGroupPickerValue);
                                setAggregates(
                                  groupByAction(
                                    fetchData?.stats,
                                    selectedGenders,
                                    newAgeGroupPickerValue
                                  )
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            selectedIconColor={theme.colors.text.strong}
                            selectedIconName={'Feather/check'}
                            selectedIconSize={20}
                            leftIconName={'Ionicons/people-sharp'}
                            options={[
                              { label: 'Unknown', value: 'unknown' },
                              { label: '0-18', value: '0-18' },
                              { label: '18-30', value: '18-30' },
                              { label: '30-50', value: '30-50' },
                              { label: '50+', value: '50+' },
                            ]}
                            placeholder={'Age Group'}
                            style={StyleSheet.applyWidth(
                              {
                                fontFamily: 'Poppins_200ExtraLight',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                            type={'underline'}
                            value={selectedAgeGroups}
                          />
                        </View>
                      </View>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      <Utils.CustomCodeErrorBoundary>
                        <Charts.Line
                          theme={props.theme}
                          labels={convertFetchToLabels(fetchData?.stats)}
                          datasets={convertFetchToDataset(fetchData.stats)}
                        />
                      </Utils.CustomCodeErrorBoundary>
                    </View>
                  </View>
                </View>
                {/* Row 3 */}
                <>
                  {!(fetchData?.stats?.length > 0) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: 1,
                          paddingLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: 16,
                          },
                          paddingRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 16,
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <Table
                        borderColor={theme.colors.border.brand}
                        borderStyle={'solid'}
                        borderWidth={1}
                        cellHorizontalPadding={10}
                        cellVerticalPadding={10}
                        data={groupFetchByDate(fetchData?.stats)}
                        drawBottomBorder={false}
                        drawEndBorder={false}
                        drawStartBorder={false}
                        drawTopBorder={true}
                        keyExtractor={(tableData, index) =>
                          tableData?.id ??
                          tableData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(tableData)
                        }
                        listKey={'9QNnqYdj'}
                        renderItem={({ item, index }) => {
                          const tableData = item;
                          return (
                            <>
                              <TableRow
                                drawBottomBorder={true}
                                drawEndBorder={false}
                                drawStartBorder={true}
                                drawTopBorder={false}
                                isTableHeader={true}
                              >
                                <TableCell
                                  drawBottomBorder={false}
                                  drawEndBorder={true}
                                  drawStartBorder={false}
                                  drawTopBorder={false}
                                  {...GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        { color: palettes.App.White }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Date'}
                                  </Text>
                                </TableCell>
                                {/* Table Cell 2 */}
                                <>
                                  {!selectedStatsType.includes(
                                    'received'
                                  ) ? null : (
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawEndBorder={true}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { color: palettes.App.White }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Sent'}
                                      </Text>
                                    </TableCell>
                                  )}
                                </>
                                {/* Table Cell 3 */}
                                <>
                                  {!selectedStatsType.includes(
                                    'viewed'
                                  ) ? null : (
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawEndBorder={true}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { color: palettes.App.White }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'View'}
                                      </Text>
                                    </TableCell>
                                  )}
                                </>
                                {/* Table Cell 4 */}
                                <>
                                  {!selectedStatsType.includes(
                                    'clicked'
                                  ) ? null : (
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawEndBorder={true}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { color: palettes.App.White }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Click'}
                                      </Text>
                                    </TableCell>
                                  )}
                                </>
                              </TableRow>
                              {/* Table Row 2 */}
                              <TableRow
                                drawBottomBorder={true}
                                drawEndBorder={false}
                                drawStartBorder={true}
                                drawTopBorder={false}
                                isTableHeader={false}
                              >
                                <TableCell
                                  drawBottomBorder={false}
                                  drawEndBorder={true}
                                  drawStartBorder={false}
                                  drawTopBorder={false}
                                  {...GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {tableData?.dateText}
                                  </Text>
                                </TableCell>
                                {/* Table Cell 2 */}
                                <>
                                  {!selectedStatsType.includes(
                                    'received'
                                  ) ? null : (
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawEndBorder={true}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {tableData?.received}
                                      </Text>
                                    </TableCell>
                                  )}
                                </>
                                {/* Table Cell 3 */}
                                <>
                                  {!selectedStatsType.includes(
                                    'viewed'
                                  ) ? null : (
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawEndBorder={true}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {tableData?.viewed}
                                      </Text>
                                    </TableCell>
                                  )}
                                </>
                                {/* Table Cell 4 */}
                                <>
                                  {!selectedStatsType.includes(
                                    'clicked'
                                  ) ? null : (
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawEndBorder={true}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {tableData?.clicked}
                                      </Text>
                                    </TableCell>
                                  )}
                                </>
                              </TableRow>
                              {/* Summary */}
                              <>
                                {!(
                                  index ===
                                  groupFetchByDate(fetchData?.stats)?.length - 1
                                ) ? null : (
                                  <TableRow
                                    drawBottomBorder={true}
                                    drawEndBorder={false}
                                    drawStartBorder={true}
                                    drawTopBorder={false}
                                    isTableHeader={false}
                                  >
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawEndBorder={true}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              alignSelf: {
                                                minWidth: Breakpoints.Desktop,
                                                value: 'flex-end',
                                              },
                                              fontFamily: 'Poppins_600SemiBold',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Total'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 2 */}
                                    <>
                                      {!selectedStatsType.includes(
                                        'received'
                                      ) ? null : (
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawEndBorder={true}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TableCellStyles(theme)[
                                              'Table Cell'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  fontFamily:
                                                    'Poppins_600SemiBold',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {aggregates?.received}
                                          </Text>
                                        </TableCell>
                                      )}
                                    </>
                                    {/* Table Cell 3 */}
                                    <>
                                      {!selectedStatsType.includes(
                                        'viewed'
                                      ) ? null : (
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawEndBorder={true}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TableCellStyles(theme)[
                                              'Table Cell'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  fontFamily:
                                                    'Poppins_600SemiBold',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {aggregates?.viewed}
                                          </Text>
                                        </TableCell>
                                      )}
                                    </>
                                    {/* Table Cell 4 */}
                                    <>
                                      {!selectedStatsType.includes(
                                        'clicked'
                                      ) ? null : (
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawEndBorder={true}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TableCellStyles(theme)[
                                              'Table Cell'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  fontFamily:
                                                    'Poppins_600SemiBold',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {aggregates?.clicked}
                                          </Text>
                                        </TableCell>
                                      )}
                                    </>
                                  </TableRow>
                                )}
                              </>
                            </>
                          );
                        }}
                        showsVerticalScrollIndicator={true}
                        {...GlobalStyles.TableStyles(theme)['Table'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TableStyles(theme)['Table'].style,
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
              </SimpleStyleScrollView>
            </>
          );
        }}
      </PromotionsServiceApi.FetchGetPromotionStatsGET>
    </ScreenContainer>
  );
};

export default withTheme(PromotionStatsScreen);
