import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Keyboard,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AdminBusinessCategoriesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [editingRow, setEditingRow] = React.useState({});
  const [idToDelete, setIdToDelete] = React.useState(0);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [newCategoryNameValue, setNewCategoryNameValue] = React.useState('');
  const [newRowTitle, setNewRowTitle] = React.useState('');
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const xanoServiceUpdateBusinessCategoryNamePOST =
    XanoServiceApi.useUpdateBusinessCategoryNamePOST();
  const xanoServiceCreateBusinessCategoryPOST =
    XanoServiceApi.useCreateBusinessCategoryPOST();
  const xanoServiceDeleteBusinessCategoryDELETE =
    XanoServiceApi.useDeleteBusinessCategoryDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
      trackScreen(segment, 'Admin - Business Categories');
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role !== 'admin') {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'business_categories'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="business_categories"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: theme.colors.background.brand,
            flex: 1,
            maxWidth: 1024,
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <View style={StyleSheet.applyWidth({ flex: 0.9 }, dimensions.width)}>
          <XanoServiceApi.FetchGetBussinessCategoriesGET excludeEmpty={false}>
            {({ loading, error, data, refetchGetBussinessCategories }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) =>
                    listData?.id ??
                    listData?.uuid ??
                    index?.toString() ??
                    JSON.stringify(listData)
                  }
                  keyboardShouldPersistTaps={'never'}
                  listKey={'pNHHVCfL'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* Address Display */}
                        <View
                          {...GlobalStyles.ViewStyles(theme)[
                            'Address Display 4'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ViewStyles(theme)[
                                'Address Display 4'
                              ].style,
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor: palettes.App.CustomGray,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* LeftView */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 0.8, flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <>
                              {!(editingRow?.id !== listData?.id) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'TitleText'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'TitleText'
                                      ].style,
                                      { fontFamily: 'Poppins_200ExtraLight' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.name}
                                </Text>
                              )}
                            </>
                            <>
                              {!(editingRow?.id === listData?.id) ? null : (
                                <TextInput
                                  autoCapitalize={'none'}
                                  autoCorrect={true}
                                  changeTextDelay={500}
                                  onChangeText={newTextInputValue => {
                                    try {
                                      setNewRowTitle(newTextInputValue);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  placeholder={'Enter a value...'}
                                  webShowOutline={true}
                                  {...GlobalStyles.TextInputStyles(theme)[
                                    'Text Input'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextInputStyles(theme)[
                                        'Text Input'
                                      ].style,
                                      { width: '100%' }
                                    ),
                                    dimensions.width
                                  )}
                                  value={newRowTitle}
                                />
                              )}
                            </>
                          </View>
                          {/* RightView */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flex: 0.2,
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            {/* EditButton */}
                            <>
                              {!(editingRow?.id !== listData?.id) ? null : (
                                <IconButton
                                  onPress={() => {
                                    try {
                                      setEditingRow(listData);
                                      setNewRowTitle(listData?.name);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  icon={'AntDesign/edit'}
                                  size={24}
                                />
                              )}
                            </>
                            {/* DeleteButton */}
                            <>
                              {!(editingRow?.id !== listData?.id) ? null : (
                                <IconButton
                                  onPress={() => {
                                    try {
                                      setIdToDelete(listData?.id);
                                      setShowDeleteModal(true);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  color={theme.colors.background.danger}
                                  icon={'AntDesign/delete'}
                                  size={24}
                                />
                              )}
                            </>
                            {/* SaveButton */}
                            <>
                              {!(editingRow?.id === listData?.id) ? null : (
                                <IconButton
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        const updateNameResult = (
                                          await xanoServiceUpdateBusinessCategoryNamePOST.mutateAsync(
                                            {
                                              business_category_id:
                                                editingRow?.id,
                                              name: newRowTitle,
                                            }
                                          )
                                        )?.json;
                                        trackAction(
                                          Variables,
                                          segment,
                                          'Edited business category',
                                          newRowTitle
                                        );
                                        if (!updateNameResult?.message) {
                                          setEditingRow(null);
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  icon={'AntDesign/save'}
                                  size={24}
                                />
                              )}
                            </>
                            {/* CloseButton */}
                            <>
                              {!(editingRow?.id === listData?.id) ? null : (
                                <IconButton
                                  onPress={() => {
                                    try {
                                      setEditingRow(null);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  color={theme.colors.background.danger}
                                  icon={'AntDesign/closecircleo'}
                                  size={24}
                                />
                              )}
                            </>
                          </View>
                        </View>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              );
            }}
          </XanoServiceApi.FetchGetBussinessCategoriesGET>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { flex: 0.1, justifyContent: 'center', paddingTop: 10 },
            dimensions.width
          )}
        >
          {/* AddBusinessCategoryButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Add Business Category Button',
                  undefined
                );
                setShowModal(true);
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                { marginBottom: 20, marginLeft: 20, marginRight: 20 }
              ),
              dimensions.width
            )}
            title={'Add Business Category'}
          />
        </View>
      </View>
      {/* AddCategoryModal */}
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={showModal}
      >
        {/* BGView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.ViewBG,
              flex: 1,
              justifyContent: 'center',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App.CustomGray,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* Modal Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.CustomGray,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_500Medium' }
                  ),
                  dimensions.width
                )}
              >
                {'Add New Category'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'Ionicons/close'}
              />
            </View>
            {/* Modal Body */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              {/* NewCategoryName */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newNewCategoryNameValue => {
                  try {
                    setNewCategoryNameValue(newNewCategoryNameValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={'Enter Category Name'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  dimensions.width
                )}
                value={newCategoryNameValue}
              />
            </View>
            {/* Modal Footer */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* CancelView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* ConfirmView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsLoading(true);
                        (
                          await xanoServiceCreateBusinessCategoryPOST.mutateAsync(
                            { name: newCategoryNameValue }
                          )
                        )?.json;
                        setIsLoading(false);
                        setNewCategoryNameValue('');
                        setShowModal(false);
                        trackAction(
                          Variables,
                          segment,
                          'Added New Business Category',
                          undefined
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={!newCategoryNameValue}
                  loading={isLoading}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    dimensions.width
                  )}
                  title={'Add'}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
      {/* DeleteModal */}
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={showDeleteModal}
      >
        {/* BGView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.ViewBG,
              flex: 1,
              justifyContent: 'center',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App.CustomGray,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* Modal Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.CustomGray,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_500Medium' }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure?'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowDeleteModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'Ionicons/close'}
              />
            </View>
            {/* Modal Body */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'You are about to delete this business category. You can not undo this action. Do you want to proceed? '
                }
              </Text>
            </View>
            {/* Modal Footer */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* CancelView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowDeleteModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* ConfirmView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        (
                          await xanoServiceDeleteBusinessCategoryDELETE.mutateAsync(
                            { business_category_id: idToDelete }
                          )
                        )?.json;
                        setIdToDelete(false);
                        setIsDeleting(false);
                        setShowDeleteModal(false);
                        trackAction(
                          Variables,
                          segment,
                          'Deleted Business Category',
                          idToDelete
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: theme.colors.background.danger }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Delete!'}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(AdminBusinessCategoriesScreen);
