import React from 'react';
import {
  Button,
  Divider,
  Icon,
  Pressable,
  ScreenContainer,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { ActivityIndicator, Image, ScrollView, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const NotificationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [isLoading, setIsLoading] = React.useState(false);
  const xanoServiceTogglePushTokenPOST =
    XanoServiceApi.useTogglePushTokenPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Notification');
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'stretch',
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* BackButton */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['BackButton'].style,
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['Custom Color_9']}
              name={'Ionicons/chevron-back'}
              size={28}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: 1,
            justifyContent: 'space-between',
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <View>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingBottom: 24,
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 34,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 32,
                  marginBottom: 6,
                  textAlign: 'center',
                  typography: theme.typography.headline4,
                },
                dimensions.width
              )}
            >
              {'Permissions'}
            </Text>
          </View>
          {/* PermissionsForm */}
          <ScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            contentContainerStyle={StyleSheet.applyWidth(
              {
                paddingBottom: 14,
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 14,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    setIsLoading(true);
                    if (Constants['USER_DATA']?.push_token) {
                      const updateResult = (
                        await xanoServiceTogglePushTokenPOST.mutateAsync({
                          push_token: '',
                        })
                      )?.json;
                      setGlobalVariableValue({
                        key: 'USER_DATA',
                        value: updateResult,
                      });
                    } else {
                      const push_token = await getPushTokenUtil({
                        permissionErrorMessage:
                          'Sorry! We are unable to enable notification settings for you. Ensure you have granted the notification permissions to the Mailboxx app. This can be verified and updated from device settings.',
                        deviceErrorMessage:
                          'Must use physical device for Push Notifications',
                        showAlertOnPermissionError: true,
                        showAlertOnDeviceError: true,
                      });

                      const updateTokenResult = (
                        await xanoServiceTogglePushTokenPOST.mutateAsync({
                          push_token: push_token,
                        })
                      )?.json;
                      setGlobalVariableValue({
                        key: 'USER_DATA',
                        value: updateTokenResult,
                      });
                    }

                    setIsLoading(false);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 12,
                    marginTop: 12,
                    minHeight: 60,
                    paddingBottom: 12,
                    paddingTop: 12,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      maxWidth: '80%',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'stretch', width: '100%' },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.text.strong,
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 15,
                          typography: theme.typography.headline6,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {'Notifications'}
                    </Text>

                    <Text
                      accessible={true}
                      selectable={false}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.text.medium,
                          fontFamily: 'Poppins_400Regular',
                          typography: theme.typography.caption,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {
                        "Enable notifications so you don't miss any exciting news."
                      }
                    </Text>
                  </View>
                </View>
                <>
                  {isLoading ? null : (
                    <Switch
                      activeTrackColor={theme.colors.branding.primary}
                      color={theme.colors.branding.primary}
                      defaultValue={Constants['USER_DATA']?.push_token}
                      disabled={true}
                    />
                  )}
                </>
                <>
                  {!isLoading ? null : (
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                  )}
                </>
              </View>
            </Touchable>
            <Divider
              color={theme.colors.border.brand}
              height={1}
              style={StyleSheet.applyWidth({ height: 1 }, dimensions.width)}
            />
          </ScrollView>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              paddingBottom: 14,
              paddingLeft: 32,
              paddingRight: 32,
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* Button Outline */}
          <Button
            iconPosition={'left'}
            title={'Get Started'}
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                backgroundColor: 'transparent',
                fontFamily: 'Poppins_400Regular',
                justifyContent: 'center',
                paddingLeft: 12,
                paddingRight: 12,
              },
              dimensions.width
            )}
            type={'outline'}
          >
            {'View Notification Settings'}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(NotificationScreen);
