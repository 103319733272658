import React from 'react';
import {
  Icon,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthSelectSignupTypeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Select Signup Type');
      if (!isLoggedIn(Variables)) {
        return;
      }
      if (Constants['USER_DATA']?.role === 'admin') {
        navigation.navigate('AdminDashboardScreen');
      } else {
        if (Constants['USER_DATA']?.role === 'business') {
          navigation.navigate('MyPromotionsScreen');
        } else {
          navigation.navigate('InboxScreen');
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={true}>
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  height: [
                    { minWidth: Breakpoints.Mobile, value: 30 },
                    { minWidth: Breakpoints.Tablet, value: 40 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: 120 },
                    { minWidth: Breakpoints.Tablet, value: 160 },
                  ],
                }
              ),
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            flex: [
              { minWidth: Breakpoints.Tablet, value: 1 },
              { minWidth: Breakpoints.Mobile, value: 1 },
            ],
            flexGrow: { minWidth: Breakpoints.Tablet, value: 1 },
            justifyContent: [
              { minWidth: Breakpoints.Mobile, value: 'center' },
              { minWidth: Breakpoints.Desktop, value: 'center' },
            ],
            marginLeft: 20,
            marginRight: 20,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', justifyContent: 'center', padding: 16 },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.text.strong,
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 18 },
                  { minWidth: Breakpoints.Laptop, value: 26 },
                ],
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Please select your profile type to continue '}
          </Text>
        </View>
        {/* PropmotionTypePickerView */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', justifyContent: 'center', marginTop: 20 },
            dimensions.width
          )}
        >
          {/* Row */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', maxHeight: 300 },
              dimensions.width
            )}
          >
            {/* NormalUser */}
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['PromotionCard'].props}
              elevation={2}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['PromotionCard'].style,
                  {
                    padding: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Laptop, value: 30 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked User Profile Button',
                      undefined
                    );
                    navigation.navigate('AuthSignUpUserScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                {/* Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'center',
                      padding: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.PrimarySelected,
                        borderRadius: 10,
                        padding: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.Laptop, value: 30 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App.PrimaryDark}
                      name={'FontAwesome/user-circle-o'}
                      size={StyleSheet.getWidthValue(
                        [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 80 },
                        ],
                        dimensions.width
                      )}
                    />
                  </View>

                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.PrimaryDark,
                          fontFamily: 'Poppins_500Medium',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Laptop, value: 26 },
                          ],
                          marginBottom: 20,
                          marginTop: 20,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'User\nProfile'}
                  </Text>
                </View>
              </Touchable>
            </Surface>
            {/* Business */}
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['PromotionCard'].props}
              elevation={2}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['PromotionCard'].style,
                  {
                    backgroundColor: theme.colors.branding.secondary,
                    padding: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Laptop, value: 30 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Business Profile Button',
                      undefined
                    );
                    navigation.navigate('AuthSignUpBusinessScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                {/* Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'center',
                      padding: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.SecondarySelected,
                        borderRadius: 10,
                        padding: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.Laptop, value: 30 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand['Secondary Dark']}
                      name={'Ionicons/business'}
                      size={StyleSheet.getWidthValue(
                        [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 80 },
                        ],
                        dimensions.width
                      )}
                    />
                  </View>

                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.Brand['Secondary Dark'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Laptop, value: 26 },
                          ],
                          marginBottom: 20,
                          marginTop: 20,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Business\nProfile'}
                  </Text>
                </View>
              </Touchable>
            </Surface>
          </View>
        </View>
        {/* Footer */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', height: 150, justifyContent: 'center' },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.text.strong,
                fontSize: { minWidth: Breakpoints.Laptop, value: 20 },
              }),
              dimensions.width
            )}
          >
            {'Already have an account?'}
          </Text>
          {/* LogInButton */}
          <Link
            accessible={true}
            onPress={() => {
              try {
                navigation.navigate('AuthLoginScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            selectable={false}
            {...GlobalStyles.LinkStyles(theme)['Link'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
                alignSelf: 'center',
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 18 },
                  { minWidth: Breakpoints.Laptop, value: 26 },
                ],
                textAlign: 'center',
              }),
              dimensions.width
            )}
            title={'Log In'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthSelectSignupTypeScreen);
