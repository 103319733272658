import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const getBalanceGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/balance`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetBalanceGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['balance', args],
    () => getBalanceGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['balances']),
    }
  );
};

export const FetchGetBalanceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBalanceGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBalance: refetch });
};

export const getTransactionsGET = async (
  Constants,
  { from, to },
  handlers = {}
) => {
  const paramsDict = {};
  if (from !== undefined) {
    paramsDict['from'] = renderParam(from);
  }
  if (to !== undefined) {
    paramsDict['to'] = renderParam(to);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/transaction${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetTransactionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['transactions', args],
    () => getTransactionsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetTransactionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  from,
  to,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTransactionsGET(
    { from, to },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTransactions: refetch });
};

export const updateBalancePOST = async (
  Constants,
  { amount, note, type },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/balance`;
  const options = {
    body: JSON.stringify({ amount: amount, note: note, type: type }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateBalancePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateBalancePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('balance', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('balance');
        queryClient.invalidateQueries('balances');
      },
    }
  );
};

export const FetchUpdateBalancePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  amount,
  note,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateBalancePOST(
    { amount, note, type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateBalance: refetch });
};
