import React from 'react';
import {
  Button,
  Checkbox,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import getPercent from '../global-functions/getPercent';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AdminAddressApprovalScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [currentApprovalImageUrl, setCurrentApprovalImageUrl] =
    React.useState('');
  const [isApproving, setIsApproving] = React.useState(false);
  const [reloadData, setReloadData] = React.useState('');
  const [selectedAddressIds, setSelectedAddressIds] = React.useState([]);
  const [showApprovalImageModal, setShowApprovalImageModal] =
    React.useState(false);
  const toggleID = id => {
    if (selectedAddressIds.includes(id)) {
      setSelectedAddressIds(prev => prev.filter(f => f !== id));
    } else {
      setSelectedAddressIds(prev => [...prev, id]);
    }
  };

  const rand = () => {
    return Math.floor(Math.random() * 10000);
  };
  const xanoServiceApproveAddressesPOST =
    XanoServiceApi.useApproveAddressesPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role !== 'admin') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Admin - Address Approval');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'addressapproval'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="addressapproval"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: palettes.App.CustomGray,
            flex: 1,
            maxWidth: 1024,
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <XanoServiceApi.FetchGetPendingAddressesGET refresh={reloadData}>
          {({ loading, error, data, refetchGetPendingAddresses }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <SimpleStyleFlatList
                data={fetchData}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ??
                  listData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(listData)
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'JBavJXE9'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <Surface
                      elevation={0}
                      {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                          {
                            alignItems: 'center',
                            borderRadius: 8,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                            marginLeft: 10,
                            marginRight: 10,
                            overflow: 'hidden',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Pressable 2 */}
                      <Pressable
                        onPress={() => {
                          try {
                            toggleID(listData?.id);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Checkbox
                            defaultValue={selectedAddressIds.includes(
                              listData?.id
                            )}
                            disabled={true}
                          />
                          {/* Address Display */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'Address Display 4'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'Address Display 4'
                                ].style,
                                { flex: 1 }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['TitleText']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['TitleText']
                                    .style,
                                  { marginBottom: 12 }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.title}
                            </Text>
                            {/* RoadText */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {listData?.road}
                              {/* HouseNumber */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {', '}
                                {listData?.house_number}
                              </Text>
                            </Text>
                            {/* CityText */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {listData?.city}
                              {/* AddressText */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {', '}
                                {listData?.state}
                              </Text>
                            </Text>
                            {/* Zip */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  { lineHeight: 18 }
                                ),
                                dimensions.width
                              )}
                            >
                              {null}
                            </Text>
                          </View>
                        </View>
                      </Pressable>

                      <Pressable
                        onPress={() => {
                          try {
                            setCurrentApprovalImageUrl(
                              listData?.approval_image?.url
                            );
                            setShowApprovalImageModal(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={imageSource(
                            `${listData?.approval_image?.url}`
                          )}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              {
                                borderColor: theme.colors.text.light,
                                borderWidth: 1,
                              }
                            ),
                            dimensions.width
                          )}
                        />
                      </Pressable>
                    </Surface>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              />
            );
          }}
        </XanoServiceApi.FetchGetPendingAddressesGET>
      </View>
      {/* ApproveSelectedAddresses */}
      <Button
        iconPosition={'left'}
        onPress={() => {
          const handler = async () => {
            try {
              console.log(selectedAddressIds);
              setIsApproving(true);
              (
                await xanoServiceApproveAddressesPOST.mutateAsync({
                  ids: selectedAddressIds,
                })
              )?.json;
              setIsApproving(false);
              setReloadData(rand());
              trackAction(
                Variables,
                segment,
                'Approved Address',
                selectedAddressIds
              );
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
        disabled={selectedAddressIds?.length === 0}
        loading={isApproving}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'].style, {
            marginBottom: 10,
            marginLeft: 20,
            marginRight: 20,
          }),
          dimensions.width
        )}
        title={'Approve Selected Addresses'}
      />
      {/* ApprovalImageModal */}
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={showApprovalImageModal}
      >
        {/* BGView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.ViewBG,
              flex: 1,
              justifyContent: 'center',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App.CustomGray,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* Modal Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.CustomGray,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_700Bold' }
                  ),
                  dimensions.width
                )}
              >
                {'Approval Image'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowApprovalImageModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'Ionicons/close'}
              />
            </View>
            {/* Modal Body */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(`${currentApprovalImageUrl}`)}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    {
                      height: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: getPercent(dimensions.height, 75),
                        },
                      ],
                      width: '100%',
                    }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(AdminAddressApprovalScreen);
