import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useAnalytics } from '@segment/analytics-react-native';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import * as GlobalVariables from './config/GlobalVariableContext';
import isLoggedIn from './global-functions/isLoggedIn';
import AddAddressScreen from './screens/AddAddressScreen';
import AddPromotionFormScreen from './screens/AddPromotionFormScreen';
import AddPromotionSelectTypeScreen from './screens/AddPromotionSelectTypeScreen';
import AddressesScreen from './screens/AddressesScreen';
import AdminAddressApprovalScreen from './screens/AdminAddressApprovalScreen';
import AdminBusinessCategoriesScreen from './screens/AdminBusinessCategoriesScreen';
import AdminDashboardScreen from './screens/AdminDashboardScreen';
import AuthBankAccountsScreen from './screens/AuthBankAccountsScreen';
import AuthContactsImportScreen from './screens/AuthContactsImportScreen';
import AuthDoNotCallScreen from './screens/AuthDoNotCallScreen';
import AuthForgetPasswordScreen from './screens/AuthForgetPasswordScreen';
import AuthForgetPasswordUpdateScreen from './screens/AuthForgetPasswordUpdateScreen';
import AuthLoginScreen from './screens/AuthLoginScreen';
import AuthNotificationPermissionsScreen from './screens/AuthNotificationPermissionsScreen';
import AuthSelectSignupTypeScreen from './screens/AuthSelectSignupTypeScreen';
import AuthSignUpBusinessScreen from './screens/AuthSignUpBusinessScreen';
import AuthSignUpUserScreen from './screens/AuthSignUpUserScreen';
import BalanceScreen from './screens/BalanceScreen';
import BalanceUpdateScreen from './screens/BalanceUpdateScreen';
import BankAccountsScreen from './screens/BankAccountsScreen';
import BusinessFollowingScreen from './screens/BusinessFollowingScreen';
import BusinessProfileScreen from './screens/BusinessProfileScreen';
import BusinessSearchScreen from './screens/BusinessSearchScreen';
import CallbackScreen from './screens/CallbackScreen';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import ComponentTransactionRowScreen from './screens/ComponentTransactionRowScreen';
import ContactsImportScreen from './screens/ContactsImportScreen';
import DeleteAccountScreen from './screens/DeleteAccountScreen';
import GetInContactScreen from './screens/GetInContactScreen';
import InboxScreen from './screens/InboxScreen';
import MyPromotionsScreen from './screens/MyPromotionsScreen';
import NotificationScreen from './screens/NotificationScreen';
import PermissionsScreen from './screens/PermissionsScreen';
import PreviewExplorePromotionScreen from './screens/PreviewExplorePromotionScreen';
import PreviewPromotionScreen from './screens/PreviewPromotionScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ProfileUpdateScreen from './screens/ProfileUpdateScreen';
import PromotionStatsScreen from './screens/PromotionStatsScreen';
import SavedScreen from './screens/SavedScreen';
import SettingsScreen from './screens/SettingsScreen';
import TermsandConditionsScreen from './screens/TermsandConditionsScreen';
import UnsubscribeScreen from './screens/UnsubscribeScreen';
import ViewPromotionScreen from './screens/ViewPromotionScreen';
import WelcomeScreen from './screens/WelcomeScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="WelcomeScreen"
        presentation="modal"
        screenOptions={({ navigation }) => ({
          animationEnabled: false,
          cardStyle: { flex: 1 },
          gestureEnabled: false,
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerMode: 'none',
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="AuthLoginScreen"
          component={AuthLoginScreen}
          options={({ navigation }) => ({
            title: 'Login',
          })}
        />
        <Stack.Screen
          name="AuthForgetPasswordScreen"
          component={AuthForgetPasswordScreen}
          options={({ navigation }) => ({
            title: 'Password',
          })}
        />
        <Stack.Screen
          name="BalanceScreen"
          component={BalanceScreen}
          options={({ navigation }) => ({
            title: 'Balance',
          })}
        />
        <Stack.Screen
          name="SettingsScreen"
          component={SettingsScreen}
          options={({ navigation }) => ({
            title: 'Settings',
          })}
        />
        <Stack.Screen
          name="SavedScreen"
          component={SavedScreen}
          options={({ navigation }) => ({
            title: 'Saved',
          })}
        />
        <Stack.Screen
          name="ViewPromotionScreen"
          component={ViewPromotionScreen}
          options={({ navigation }) => ({
            title: 'Promotion',
          })}
        />
        <Stack.Screen
          name="ProfileUpdateScreen"
          component={ProfileUpdateScreen}
          options={({ navigation }) => ({
            title: 'Profile',
          })}
        />
        <Stack.Screen
          name="TermsandConditionsScreen"
          component={TermsandConditionsScreen}
          options={({ navigation }) => ({
            title: 'Terms and Conditions',
          })}
        />
        <Stack.Screen
          name="PrivacyPolicyScreen"
          component={PrivacyPolicyScreen}
          options={({ navigation }) => ({
            title: 'Privacy Policy',
          })}
        />
        <Stack.Screen
          name="AddressesScreen"
          component={AddressesScreen}
          options={({ navigation }) => ({
            title: 'Addresses',
          })}
        />
        <Stack.Screen
          name="AuthNotificationPermissionsScreen"
          component={AuthNotificationPermissionsScreen}
          options={({ navigation }) => ({
            title: 'Notifications',
          })}
        />
        <Stack.Screen
          name="ChangePasswordScreen"
          component={ChangePasswordScreen}
          options={({ navigation }) => ({
            title: 'Change Password',
          })}
        />
        <Stack.Screen
          name="NotificationScreen"
          component={NotificationScreen}
          options={({ navigation }) => ({
            title: 'Notifications',
          })}
        />
        <Stack.Screen
          name="MyPromotionsScreen"
          component={MyPromotionsScreen}
          options={({ navigation }) => ({
            title: 'My Promotions',
          })}
        />
        <Stack.Screen
          name="AddAddressScreen"
          component={AddAddressScreen}
          options={({ navigation }) => ({
            title: 'Add Address',
          })}
        />
        <Stack.Screen
          name="AddPromotionSelectTypeScreen"
          component={AddPromotionSelectTypeScreen}
          options={({ navigation }) => ({
            title: 'New Promotion',
          })}
        />
        <Stack.Screen
          name="AuthSignUpBusinessScreen"
          component={AuthSignUpBusinessScreen}
          options={({ navigation }) => ({
            title: 'Sign Up',
          })}
        />
        <Stack.Screen
          name="AuthSelectSignupTypeScreen"
          component={AuthSelectSignupTypeScreen}
          options={({ navigation }) => ({
            title: 'Sign Up',
          })}
        />
        <Stack.Screen
          name="AdminDashboardScreen"
          component={AdminDashboardScreen}
          options={({ navigation }) => ({
            title: 'Dashboard',
          })}
        />
        <Stack.Screen
          name="AdminAddressApprovalScreen"
          component={AdminAddressApprovalScreen}
          options={({ navigation }) => ({
            title: 'Address Approval',
          })}
        />
        <Stack.Screen
          name="AdminBusinessCategoriesScreen"
          component={AdminBusinessCategoriesScreen}
          options={({ navigation }) => ({
            title: 'Business Categories',
          })}
        />
        <Stack.Screen
          name="ComponentTransactionRowScreen"
          component={ComponentTransactionRowScreen}
          options={({ navigation }) => ({
            title: 'Component_TransactionRow',
          })}
        />
        <Stack.Screen
          name="BalanceUpdateScreen"
          component={BalanceUpdateScreen}
          options={({ navigation }) => ({
            title: 'Balance',
          })}
        />
        <Stack.Screen
          name="AuthBankAccountsScreen"
          component={AuthBankAccountsScreen}
          options={({ navigation }) => ({
            title: 'Bank Accounts',
          })}
        />
        <Stack.Screen
          name="BankAccountsScreen"
          component={BankAccountsScreen}
          options={({ navigation }) => ({
            title: 'Bank Accounts',
          })}
        />
        <Stack.Screen
          name="AuthForgetPasswordUpdateScreen"
          component={AuthForgetPasswordUpdateScreen}
          options={({ navigation }) => ({
            title: 'Password',
          })}
        />
        <Stack.Screen
          name="PreviewPromotionScreen"
          component={PreviewPromotionScreen}
          options={({ navigation }) => ({
            title: 'Preview',
          })}
        />
        <Stack.Screen
          name="PromotionStatsScreen"
          component={PromotionStatsScreen}
          options={({ navigation }) => ({
            title: 'Promotion Stats',
          })}
        />
        <Stack.Screen
          name="AuthSignUpUserScreen"
          component={AuthSignUpUserScreen}
          options={({ navigation }) => ({
            title: 'SignUp User',
          })}
        />
        <Stack.Screen
          name="WelcomeScreen"
          component={WelcomeScreen}
          options={({ navigation }) => ({
            title: 'Welcome',
          })}
        />
        <Stack.Screen
          name="GetInContactScreen"
          component={GetInContactScreen}
          options={({ navigation }) => ({
            title: 'Get in Contact',
          })}
        />
        <Stack.Screen
          name="InboxScreen"
          component={InboxScreen}
          options={({ navigation }) => ({
            title: 'Inbox',
          })}
        />
        <Stack.Screen
          name="PreviewExplorePromotionScreen"
          component={PreviewExplorePromotionScreen}
          options={({ navigation }) => ({
            title: 'Preview',
          })}
        />
        <Stack.Screen
          name="AddPromotionFormScreen"
          component={AddPromotionFormScreen}
          options={({ navigation }) => ({
            title: 'Add Promotion',
          })}
        />
        <Stack.Screen
          name="AuthDoNotCallScreen"
          component={AuthDoNotCallScreen}
          options={({ navigation }) => ({
            title: 'Sign Up',
          })}
        />
        <Stack.Screen
          name="DeleteAccountScreen"
          component={DeleteAccountScreen}
          options={({ navigation }) => ({
            title: 'Delete Account',
          })}
        />
        <Stack.Screen
          name="BusinessProfileScreen"
          component={BusinessProfileScreen}
          options={({ navigation }) => ({
            title: 'Business Profile',
          })}
        />
        <Stack.Screen
          name="BusinessSearchScreen"
          component={BusinessSearchScreen}
          options={({ navigation }) => ({
            title: 'Business Search',
          })}
        />
        <Stack.Screen
          name="BusinessFollowingScreen"
          component={BusinessFollowingScreen}
          options={({ navigation }) => ({
            title: 'Following',
          })}
        />
        <Stack.Screen
          name="CallbackScreen"
          component={CallbackScreen}
          options={({ navigation }) => ({
            title: 'Callback',
          })}
        />
        <Stack.Screen
          name="AuthContactsImportScreen"
          component={AuthContactsImportScreen}
          options={({ navigation }) => ({
            title: 'Contacts Import',
          })}
        />
        <Stack.Screen
          name="UnsubscribeScreen"
          component={UnsubscribeScreen}
          options={({ navigation }) => ({
            title: 'Unsubscribe',
          })}
        />
        <Stack.Screen
          name="PermissionsScreen"
          component={PermissionsScreen}
          options={({ navigation }) => ({
            title: 'Permissions',
          })}
        />
        <Stack.Screen
          name="ContactsImportScreen"
          component={ContactsImportScreen}
          options={({ navigation }) => ({
            title: 'Contacts Import',
          })}
        />
    
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
