import React from 'react';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Circle,
  Icon,
  IconButton,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleMasonryFlashList,
  SimpleStyleScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import AddressDisplayBlock from '../components/AddressDisplayBlock';
import CardBlock from '../components/CardBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import startNavigation from '../global-functions/startNavigation';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const defaultProps = { business_id: 2 };

const BusinessProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [currentlyTogglingId, setCurrentlyTogglingId] = React.useState(0);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [searchBoxFocused, setSearchBoxFocused] = React.useState(false);
  const [searchDisplayValue, setSearchDisplayValue] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedBusinessCategoryId, setSelectedBusinessCategoryId] =
    React.useState(0);
  const [totalPromotions, setTotalPromotions] = React.useState(-1);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Business Profile');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: [
            {
              minWidth: Breakpoints.Mobile,
              value: theme.colors.background.brand,
            },
            { minWidth: Breakpoints.Desktop, value: palettes.App.CustomGray },
          ],
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock
            currentScreen={'business_profile'}
            withGoBack={true}
          />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['Header'].style,
                { width: '100%' }
              ),
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Custom Color_9']}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
            {/* EditProfileButton */}
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('ProfileUpdateScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'FontAwesome/edit'}
              size={28}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: palettes.App.White,
            flex: 1,
            maxWidth: 1024,
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <XanoServiceApi.FetchGetBusinessProfileGET
          business_id={
            props.route?.params?.business_id ?? defaultProps.business_id
          }
          handlers={{
            on404: fetchData => {
              const handler = async () => {
                try {
                  await waitUtil({ milliseconds: 5000 });
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            },
            onData: fetchData => {
              try {
                setTotalPromotions(fetchData?.active_promotions?.length);
              } catch (err) {
                console.error(err);
              }
            },
          }}
        >
          {({ loading, error, data, refetchGetBusinessProfile }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return (
                <>
                  {/* ErrorView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        justifyContent: 'center',
                        marginLeft: 20,
                        marginRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'An error occurred.'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { marginTop: 10, textAlign: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'We could not find the business profile that you requested'
                      }
                    </Text>
                  </View>
                </>
              );
            }

            return (
              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors.background.brand },
                  dimensions.width
                )}
              >
                {/* Body */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, paddingTop: 32 },
                    dimensions.width
                  )}
                >
                  {/* profile container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 20, paddingRight: 20 },
                      dimensions.width
                    )}
                  >
                    {/* cover image */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 12,
                          height: [
                            { minWidth: Breakpoints.Mobile, value: 130 },
                            { minWidth: Breakpoints.Tablet, value: 240 },
                          ],
                          overflow: 'hidden',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!fetchData?.header_image?.attachment?.url ? null : (
                          <Image
                            resizeMode={'cover'}
                            {...GlobalStyles.ImageStyles(theme)['Image'].props}
                            source={imageSource(
                              `${fetchData?.header_image?.attachment?.url}`
                            )}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'].style,
                                { height: '100%', width: '100%' }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      <>
                        {fetchData?.header_image?.attachment?.url ? null : (
                          <LinearGradient
                            endY={100}
                            startX={0}
                            startY={0}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'Linear Gradient'
                            ].props}
                            color1={palettes.App.PrimarySelected}
                            color2={theme.colors.branding.primary}
                            color3={palettes.App.PrimaryDark}
                            endX={0}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].style,
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <Image
                              resizeMode={'cover'}
                              {...GlobalStyles.ImageStyles(theme)['Image']
                                .props}
                              source={imageSource(Images['WihteLogoMailboxx'])}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image']
                                    .style,
                                  { width: 300 }
                                ),
                                dimensions.width
                              )}
                            />
                          </LinearGradient>
                        )}
                      </>
                    </View>
                    {/* profile container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', marginBottom: 16 },
                        dimensions.width
                      )}
                    >
                      {/* profile image */}
                      <Circle
                        {...GlobalStyles.CircleStyles(theme)['Circle'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.CircleStyles(theme)['Circle'].style,
                            {
                              backgroundColor: palettes.Brand.Light_Inverse,
                              borderColor: theme.colors.branding.primary,
                              borderWidth: 3,
                              marginTop: -45,
                              overflow: 'hidden',
                              width: 85,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={imageSource(
                            `${fetchData?._user_of_business?.account_image?.attachment?.url}`
                          )}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { height: 85, width: 85 }
                            ),
                            dimensions.width
                          )}
                        />
                      </Circle>
                      {/* text container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { gap: 4, marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['HeaderTitleText']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['HeaderTitleText']
                                .style,
                              {
                                color: theme.colors.text.strong,
                                fontFamily: 'Poppins_600SemiBold',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.name}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['WideHeaderText']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['WideHeaderText']
                                .style,
                              {
                                color: theme.colors.foreground.brand,
                                fontFamily: 'Poppins_400Regular',
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?._business_categories?.name}
                        </Text>
                      </View>
                    </View>
                    {/* Chip Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row', gap: 10, marginBottom: 16 },
                        dimensions.width
                      )}
                    >
                      {/* FollowersChip */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.Brand.Light_Inverse,
                            borderRadius: 12,
                            flex: 1,
                            height: 100,
                            justifyContent: 'center',
                            padding: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['Custom black'],
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?._followers_count}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: 40,
                              justifyContent: 'center',
                              marginTop: 4,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['Custom black'],
                                  fontFamily: 'Poppins_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Followers'}
                          </Text>
                        </View>
                      </View>
                      {/* ActivePromotionsChip */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.Brand.Light_Inverse,
                            borderRadius: 12,
                            flex: 1,
                            height: 100,
                            justifyContent: 'center',
                            padding: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['Custom black'],
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.active_promotions?.length}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: 40,
                              justifyContent: 'center',
                              marginTop: 4,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['Custom black'],
                                  fontFamily: 'Poppins_400Regular',
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Active Promotions'}
                          </Text>
                        </View>
                      </View>
                      {/* TotalPromotionsChip */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.Brand.Light_Inverse,
                            borderRadius: 12,
                            flex: 1,
                            height: 100,
                            justifyContent: 'center',
                            padding: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['Custom black'],
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?._total_promotions_created}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: 40,
                              justifyContent: 'center',
                              marginTop: 4,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['Custom black'],
                                  fontFamily: 'Poppins_400Regular',
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Total Promotions'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* FollowButton */}
                    <>
                      {!(
                        fetchData?.user_id !== Constants['USER_DATA']?.id
                      ) ? null : (
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                setIsUpdating(true);
                                (
                                  await XanoServiceApi.toggleFollowGET(
                                    Constants,
                                    {
                                      business_id:
                                        props.route?.params?.business_id ??
                                        defaultProps.business_id,
                                    }
                                  )
                                )?.json;
                                setIsUpdating(false);
                                await refetchGetBusinessProfile();
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          disabled={isUpdating}
                          iconSize={16}
                          loading={isUpdating}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                borderRadius: 12,
                                fontSize: 16,
                                height: 50,
                                marginBottom: 16,
                              }
                            ),
                            dimensions.width
                          )}
                          title={`${
                            fetchData?._is_following ? 'Unfollow' : 'Follow'
                          }`}
                        />
                      )}
                    </>
                    <Pressable
                      onPress={() => {
                        try {
                          startNavigation(
                            fetchData?._primary_address?.coords?.data?.lat,
                            fetchData?._primary_address?.coords?.data?.lng
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Address Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.Brand.Light_Inverse,
                            borderRadius: 12,
                            flexDirection: 'row',
                            gap: 8,
                            height: 120,
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        {/* address */}
                        <View
                          style={StyleSheet.applyWidth(
                            { margin: 16, width: '60%' },
                            dimensions.width
                          )}
                        >
                          <AddressDisplayBlock
                            city={fetchData?._primary_address?.city}
                            country={fetchData?._primary_address?.country}
                            house_number={
                              fetchData?._primary_address?.house_number
                            }
                            isPrimary={fetchData?._primary_address?.is_primary}
                            isVerified={false}
                            road={fetchData?._primary_address?.road}
                            showPrimaryInformation={false}
                            state={fetchData?._primary_address?.state}
                            title={'Address'}
                            titleColor={palettes.Brand.Primary}
                            zip={fetchData?._primary_address?.zip}
                          />
                        </View>

                        <Surface
                          {...GlobalStyles.SurfaceStyles(theme)['Surface']
                            .props}
                          elevation={3}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface']
                                .style,
                              {
                                borderRadius: 140,
                                height: 140,
                                overflow: 'hidden',
                                width: 140,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                height: '100%',
                                position: 'absolute',
                                width: '100%',
                                zIndex: 10,
                              },
                              dimensions.width
                            )}
                          />
                          <MapView
                            apiKey={'AIzaSyCR_GOL9ujGH5RYmiZzwvNxwEYVzvICEfg'}
                            autoClusterMarkers={false}
                            autoClusterMarkersDistanceMeters={10000}
                            customMapStyle={'Lorem ipsum dolor sit amet'}
                            loadingEnabled={true}
                            moveOnMarkerPress={true}
                            showsCompass={false}
                            {...GlobalStyles.MapViewStyles(theme)['Map View']
                              .props}
                            latitude={
                              fetchData?._primary_address?.coords?.data?.lat
                            }
                            longitude={
                              fetchData?._primary_address?.coords?.data?.lng
                            }
                            rotateEnabled={false}
                            scrollEnabled={false}
                            showsPointsOfInterest={false}
                            showsUserLocation={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.MapViewStyles(theme)['Map View']
                                  .style,
                                { height: 150, width: 150 }
                              ),
                              dimensions.width
                            )}
                            zoom={15}
                            zoomEnabled={false}
                          >
                            <MapMarker
                              androidUseDefaultIconImplementation={false}
                              flat={false}
                              pinImageSize={50}
                              tracksViewChanges={true}
                              latitude={
                                fetchData?._primary_address?.coords?.data?.lat
                              }
                              longitude={
                                fetchData?._primary_address?.coords?.data?.lng
                              }
                              pinImage={imageSource(Images['Pngaaacom5421128'])}
                            />
                          </MapView>
                        </Surface>
                      </View>
                    </Pressable>
                  </View>

                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    {/* EmptyView */}
                    <>
                      {!(totalPromotions === 0) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', margin: 20 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: 'Poppins_400Regular',
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.user_id === Constants['USER_DATA']?.id
                              ? 'You have not yet created any promotions'
                              : 'This company has not yet created any promotions'}
                            {'\n'}
                          </Text>
                        </View>
                      )}
                    </>
                    <>
                      {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                        <SimpleStyleMasonryFlashList
                          data={fetchData?.active_promotions}
                          estimatedItemSize={50}
                          keyExtractor={(masonryListData, index) =>
                            masonryListData?.id ??
                            masonryListData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(masonryListData)
                          }
                          listKey={'3kfiOiDw'}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const masonryListData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    margin: 12,
                                    marginLeft: 20,
                                    marginRight: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                <CardBlock
                                  item={masonryListData}
                                  mode={'card'}
                                  onCardSelect={
                                    /* no custom function named navigateToViewPromotion */ undefined
                                  }
                                />
                              </View>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          numColumns={2}
                          style={StyleSheet.applyWidth(
                            {
                              margin: {
                                minWidth: Breakpoints.Tablet,
                                value: 2,
                              },
                            },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <>
                      {dimensions.width >= Breakpoints.Tablet ? null : (
                        <SimpleStyleFlatList
                          data={fetchData?.active_promotions}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'Ds8e4tqZ'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 20, marginRight: 20 },
                                  dimensions.width
                                )}
                              >
                                <CardBlock
                                  item={listData}
                                  mode={'card'}
                                  onCardSelect={
                                    /* no custom function named navigateToViewPromotion */ undefined
                                  }
                                />
                              </View>
                            );
                          }}
                          showsVerticalScrollIndicator={true}
                          showsHorizontalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            { gap: 12, marginBottom: 10, marginTop: 24 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                </View>
              </SimpleStyleScrollView>
            );
          }}
        </XanoServiceApi.FetchGetBusinessProfileGET>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BusinessProfileScreen);
