import React from 'react';
import { Pressable, withTheme } from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import ReelsPlayerBlock from '../components/ReelsPlayerBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { address_id: 7, limit: 10, onClose: () => {} };

const ExplorePromotionsBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [items, setItems] = React.useState([]);
  const [totalInboxPromotions, setTotalInboxPromotions] = React.useState(-1);
  const onEndReachedFunction = async () => {
    try {
      // console.log('calling on end reached', props.address_id, props.limit)

      if (props.address_id) {
        const promotionsResult =
          (
            await PromotionsServiceApi.getPromotionsForExploreGET(Constants, {
              addresses_id: props.address_id || 1,
              limit: props.limit || 10,
            })
          )?.json || [];

        setItems(prev => [...prev, ...promotionsResult]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onUnlockFunction = async currentItemId => {
    // console.log('unlock pressed and current items are ', currentItemId, items.map(i => i.id))

    try {
      const result = (
        await PromotionsServiceApi.consumePromotionGET(Constants, {
          promotion_id: currentItemId,
        })
      )?.json;

      // console.log('result', result )

      setItems(prev => {
        prev.forEach(item => {
          if (item.id === currentItemId) {
            item._is_consumed_by_user = true;
          }
        });
        return prev;
      });
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    const handler = async () => {
      try {
        let promotionsResult = (
          await PromotionsServiceApi.getPromotionsForExploreGET(Constants, {
            addresses_id: props.address_id ?? defaultProps.address_id,
            limit: props.limit ?? defaultProps.limit,
          })
        )?.json;
        setItems(promotionsResult);
        setTotalInboxPromotions(promotionsResult?.length);
        /* Can't update action result: no updated value set */
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, []);

  return (
    <View>
      <ReelsPlayerBlock
        items={items}
        onClose={props.onClose}
        onEndReached={() => onEndReachedFunction()}
        onUnlock={currentItemId => onUnlockFunction(currentItemId)}
      />
      {/* EmptyView */}
      <>
        {!(totalInboxPromotions === 0) ? null : (
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'center', padding: 20 },
              dimensions.width
            )}
          >
            {/* EnterValidAddressWarning 2 */}
            <>
              {!(items?.length === 0) ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 20, textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'Sorry, there are no explorable promotions for you to display at the moment'
                  }
                </Text>
              )}
            </>
          </View>
        )}
      </>
      {/* PressableForApiEndpoint */}
      <Pressable
        onPress={() => {
          const handler = async () => {
            try {
              trackAction(Variables, segment, 'Promotions Consumed', undefined);
              (
                await PromotionsServiceApi.consumePromotionGET(Constants, {
                  promotion_id: 1116,
                })
              )?.json;
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
      />
    </View>
  );
};

export default withTheme(ExplorePromotionsBlock);
