import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Link,
  Pressable,
  SimpleStyleFlatList,
  SimpleStyleMasonryFlashList,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Alert,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Adobe from '../custom-files/Adobe';
import * as Lib from '../custom-files/Lib';
import getPercent from '../global-functions/getPercent';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openImagePickerUtil from '../utils/openImagePicker';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  file: {
    id: 91,
    user_id: 75,
    adobe_urn: '',
    attachment: {
      url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/7Q5AJK2Fggo9V01vktd6knHKrmI/vpmP_Q../file-a60cb6.png',
      meta: { width: 940, height: 480 },
      mime: 'image/png',
      name: 'file-a60cb6.png',
      path: '/vault/cH9onT_o/7Q5AJK2Fggo9V01vktd6knHKrmI/vpmP_Q../file-a60cb6.png',
      size: 212253,
      type: 'image',
      access: 'public',
    },
    created_at: 1723725012263,
  },
  fileType: 'image',
  label: 'Label',
  multi: true,
  orientation: 'banner',
  setFile: '',
};

const ImageGalleryPickerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [adobeAction, setAdobeAction] = React.useState('');
  const [adobeInitialImageAsset, setAdobeInitialImageAsset] =
    React.useState('');
  const [adobeUploadsId, setAdobeUploadsId] = React.useState(0);
  const [adobeUrn, setAdobeUrn] = React.useState('');
  const [cardHeight, setCardHeight] = React.useState(0);
  const [cardWidth, setCardWidth] = React.useState(0);
  const [fileToDelete, setFileToDelete] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState({});
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showPicker, setShowPicker] = React.useState(false);
  const urlToBase64 = async url => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const isWrongFormat = selectedFileType => {
    return (props.route?.params?.fileType || 'image') !== selectedFileType;
  };

  const setFunction = file => {
    // console.log(props?.route?.params?.setImage)
    props?.route?.params?.setFile && props?.route?.params?.setFile(file);
  };

  const toggleFunction = file => {
    if (props?.route?.params?.setFile) {
      props?.route?.params?.setFile(prev => {
        if (prev.findIndex(item => item.id === file.id) > -1) {
          return prev.filter(item => item.id !== file.id);
        } else {
          return [...prev, file];
        }
      });
    }
  };

  const getSelectedFileType = base64 => {
    // Remove the data:image/ prefix from the base64 string
    const type = base64.split(';')[0].split(':')[1];

    if (type.indexOf('image') !== -1 && type !== 'image/gif') {
      return 'image';
    } else if (type.indexOf('video') !== -1 || type === 'image/gif') {
      return 'video';
    } else {
      return 'unknown';
    }
  };

  const onSaveCallback = async localData => {
    // console.log(localData, adobeAction)
    setIsUploading(true);

    if (adobeAction === 'create') {
      (
        await xanoServiceUploadFilePOST.mutateAsync({
          file: localData.image,
          urn: localData.project,
        })
      )?.json;
    }

    if (adobeAction === 'edit' || adobeAction === 'createWithAsset') {
      (
        await xanoServiceUpdateUploadPOST.mutateAsync({
          file: localData.image,
          uploadsId: localData.uploadsId,
          urn: localData.project,
        })
      )?.json;
    }

    setIsUploading(false);
    setAdobeAction('');
  };

  const orientationLabel = item => {
    if (!item?.attachment?.meta) return null;

    const { type, meta } = item?.attachment;
    let width = 0,
      height = 0;

    if (type === 'image') {
      ({ width, height } = meta);
    } else if (type === 'video') {
      [width, height] = meta.video.res.split('x').map(Number);
    }

    if (height > width) return 'portrait';
    if (type === 'image' && (width < height * 1.9 || width > height * 2.1))
      return 'landscape';
    return 'banner';

    //  if(){
  };

  const isInvalidateImageRatio = item => {
    if (!item?.attachment?.meta) return null;

    const { type, meta } = item?.attachment;
    const orientation = props.orientation ?? 'portrait';
    let width = 0,
      height = 0;

    if (type === 'image') {
      ({ width, height } = meta);
    } else if (type === 'video') {
      [width, height] = meta.video.res.split('x').map(Number);
    }

    if (orientation === 'banner') {
      if (width < height * 1.9 || width > height * 2.1) {
        return `The ${type} ratio must be 2:1. (eg. 960 x 480)`;
      }

      if (height < 240) return `The ${type} size is too small`;
    }

    if (orientation === 'landscape') {
      if (width < height) {
        return `The selected ${type} is not landscape`;
      }

      if (height < 240) return `The ${type} size is too small`;
    }

    if (orientation === 'portrait') {
      if (width > height) {
        return `The selected ${type} is not portrait`;
      }

      if (width < 240) return `The ${type} size is too small`;
    }

    return false;
  };

  const openAdobeExpress = async urn => {
    const appStoreUrl =
      'https://play.google.com/store/apps/details?id=com.adobe.spark.post';
    const appStoreUrlIos =
      'https://apps.apple.com/us/app/adobe-express-ai-photo-video/id1051937863'; // Update with the correct iOS app store URL
    const contentUrl = urn
      ? `https://new.express.adobe.com/id/${urn}`
      : 'adobeexpress://schedule';

    try {
      Lib.Linking.openURL(contentUrl);
      // const supported = await Lib.Linking.canOpenURL(contentUrl);
      // if (supported) {
      //     Lib.Linking.openURL(contentUrl);
      // } else {
      //     Alert.alert(
      //         'Adobe Express not installed',
      //         'Adobe Express is not installed on your device. Would you like to install it from the App Store?',
      //         [
      //             { text: 'Cancel', style: 'cancel' },
      //             {
      //                 text: 'OK', onPress: () => {
      //                     if (Platform.OS === 'ios') {
      //                         Lib.Linking.openURL(appStoreUrlIos);
      //                     } else {
      //                         Lib.Linking.openURL(appStoreUrl);
      //                     }
      //                 }
      //             }
      //         ]
      //     );
      // }
    } catch (err) {
      console.error('Error occurred:', err);
    }
  };
  const xanoServiceUploadFilePOST = XanoServiceApi.useUploadFilePOST();
  const xanoServiceUpdateUploadPOST = XanoServiceApi.useUpdateUploadPOST();
  const xanoServiceDeleteUploadDELETE = XanoServiceApi.useDeleteUploadDELETE();
  React.useEffect(() => {
    try {
      if (props.file ?? defaultProps.file) {
        setSelectedFile(props.file ?? defaultProps.file);
      }
      const size = getPercent(
        dimensions.width,
        dimensions.width >= Breakpoints.Desktop
          ? 20
          : dimensions.width >= Breakpoints.Laptop
          ? 25
          : dimensions.width >= Breakpoints.Tablet
          ? 33
          : 50
      );
      setCardWidth(size);
      setCardHeight(size);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <View>
      {/* MultiImagePicker */}
      <>
        {!(props.multi ?? defaultProps.multi) ? null : (
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          >
            {/* AddNewImage */}
            <View
              {...GlobalStyles.ViewStyles(theme)['ImagePickerView'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['ImagePickerView'].style,
                  { marginRight: 8 }
                ),
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    setShowPicker(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { borderColor: palettes.App['Custom #aaa7a3'] },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-start', marginTop: 10 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App.CustomGray,
                        borderRadius: 8,
                        flexDirection: 'row',
                        height: 70,
                        justifyContent: 'center',
                        width: 70,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon size={24} name={'Ionicons/add-circle-outline'} />
                  </View>
                </View>
              </Pressable>

              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontSize: 16, marginTop: 10, textAlign: 'center' }
                  ),
                  dimensions.width
                )}
              >
                {props.label ?? defaultProps.label}
              </Text>
            </View>
            {/* ListView */}
            <View style={StyleSheet.applyWidth({ flex: 2 }, dimensions.width)}>
              <>
                {!(props.multi ?? defaultProps.multi) ? null : (
                  <SimpleStyleFlatList
                    data={props.file ?? defaultProps.file}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'Q4MGzwh7'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* ImagePickerView */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'ImagePickerView'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'ImagePickerView'
                                ].style,
                                { marginRight: 8 }
                              ),
                              dimensions.width
                            )}
                          >
                            <IconButton
                              onPress={() => {
                                try {
                                  toggleFunction(listData);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              color={theme.colors.background.danger}
                              icon={'AntDesign/closecircleo'}
                              size={20}
                              style={StyleSheet.applyWidth(
                                {
                                  position: 'absolute',
                                  right: 5,
                                  top: 5,
                                  zIndex: 999,
                                },
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-start', marginTop: 10 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor: palettes.App.CustomGray,
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    height: 70,
                                    justifyContent: 'center',
                                    width: 70,
                                  },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!listData?.attachment?.url ? null : (
                                    <View>
                                      <>
                                        {!(
                                          listData?.attachment?.type === 'image'
                                        ) ? null : (
                                          <Image
                                            {...GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                            ].props}
                                            resizeMode={'contain'}
                                            source={imageSource(
                                              `${listData?.attachment?.url}`
                                            )}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ].style,
                                                {
                                                  borderRadius: 8,
                                                  height: 70,
                                                  width: 70,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.attachment?.type === 'video'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors.text.medium}
                                            name={'Ionicons/videocam'}
                                            size={32}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                <>
                                  {listData?.attachment ? null : (
                                    <Icon
                                      size={24}
                                      name={'AntDesign/camerao'}
                                    />
                                  )}
                                </>
                              </View>
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    horizontal={true}
                  />
                )}
              </>
            </View>
          </View>
        )}
      </>
      {/* SingleImagePickerView */}
      <>
        {props.multi ?? defaultProps.multi ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['ImagePickerView'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['ImagePickerView'].style,
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  setShowPicker(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { borderColor: palettes.App['Custom #aaa7a3'] },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'flex-start', marginTop: 10 },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App.CustomGray,
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 70,
                      justifyContent: 'center',
                      width: 70,
                    },
                    dimensions.width
                  )}
                >
                  <>
                    {!selectedFile?.attachment?.url ? null : (
                      <View>
                        <>
                          {!(
                            selectedFile?.attachment?.type === 'image'
                          ) ? null : (
                            <Image
                              {...GlobalStyles.ImageStyles(theme)['Image']
                                .props}
                              resizeMode={'contain'}
                              source={imageSource(
                                `${selectedFile?.attachment?.url}`
                              )}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image']
                                    .style,
                                  { borderRadius: 8, height: 70, width: 70 }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {!(
                            selectedFile?.attachment?.type === 'video'
                          ) ? null : (
                            <Icon
                              color={theme.colors.text.medium}
                              name={'Ionicons/videocam'}
                              size={32}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                  <>
                    {selectedFile?.attachment ? null : (
                      <Icon size={24} name={'AntDesign/camerao'} />
                    )}
                  </>
                </View>
              </View>
            </Pressable>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontSize: 16, marginTop: 10, textAlign: 'center' }
                ),
                dimensions.width
              )}
            >
              {props.label ?? defaultProps.label}
            </Text>
          </View>
        )}
      </>
      {/* ImagepickerModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        transparent={false}
        animationType={'slide'}
        presentationStyle={'pageSheet'}
        visible={showPicker}
      >
        {/* UploadingIndicatorView */}
        <>
          {!isUploading ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['Transparent Overlay'],
                  flex: 1,
                  height: '100%',
                  justifyContent: 'center',
                  position: 'absolute',
                  width: '100%',
                  zIndex: 999,
                },
                dimensions.width
              )}
            >
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].props}
                size={'large'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].style,
                  dimensions.width
                )}
              />
            </View>
          )}
        </>
        <Utils.CustomCodeErrorBoundary>
          <Adobe.AdobeWrapper
            action={adobeAction}
            resetAction={() => setAdobeAction('')}
            assetType={props.fileType}
            urn={adobeUrn}
            uploadsId={adobeUploadsId}
            initialImageAsset={adobeInitialImageAsset}
            orientation={props.orientation || 'portrait'}
            onSave={onSaveCallback}
          >
            {/* Modal Container */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* Modal Header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['first row'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['first row'].style,
                    { marginBottom: 8, padding: 12 }
                  ),
                  dimensions.width
                )}
              >
                {/* ImageTitle */}
                <>
                  {!(
                    (props.fileType ?? defaultProps.fileType) === 'image'
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Poppins_700Bold', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Select an Image'}
                    </Text>
                  )}
                </>
                {/* VideoTitle */}
                <>
                  {!(
                    (props.fileType ?? defaultProps.fileType) === 'video'
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Poppins_700Bold', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Select a Video or a GIF file'}
                    </Text>
                  )}
                </>
                <IconButton
                  onPress={() => {
                    try {
                      setShowPicker(false);
                      setAdobeAction('');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'AntDesign/closecircleo'}
                />
              </View>
              {/* Modal Body */}
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                {/* BannerInfoText */}
                <>
                  {!(
                    (props.orientation ?? defaultProps.orientation) === 'banner'
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['Custom #d3536d'],
                            marginTop: 8,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'The required banner ratio is 2:1. (eg. 960x480)'}
                    </Text>
                  )}
                </>
                {/* UseWebWarning */}
                <>
                  {Platform.OS === 'web' ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.text.strong,
                            marginTop: 8,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'We support Adobe Express on our web app. You can open mailboxx using our '
                      }
                      <Link
                        accessible={true}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                'https://www.mailboxx.app'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        selectable={false}
                        {...GlobalStyles.LinkStyles(theme)['Link'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'].style,
                            { fontFamily: 'Poppins_500Medium' }
                          ),
                          dimensions.width
                        )}
                        title={'web app'}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors.text.strong }
                          ),
                          dimensions.width
                        )}
                      >
                        {' and continue creating your promotion'}
                      </Text>
                    </Text>
                  )}
                </>
                {/* PortraitInfoText */}
                <>
                  {!(
                    (props.orientation ?? defaultProps.orientation) ===
                    'portrait'
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['Custom #d3536d'],
                            marginTop: 8,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'The recommended portrait ratio is 9 : 16. (eg. 1080x1920)'
                      }
                    </Text>
                  )}
                </>
                {/* LandscapeInfoText */}
                <>
                  {!(
                    (props.orientation ?? defaultProps.orientation) ===
                    'landscape'
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['Custom #d3536d'],
                            marginTop: 8,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'The recommended ratio is 16:9. (eg. 1920x1080)'}
                    </Text>
                  )}
                </>
                <XanoServiceApi.FetchGetUploadsGET
                  fileType={props.fileType ?? defaultProps.fileType}
                >
                  {({ loading, error, data, refetchGetUploads }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* List */}
                        <SimpleStyleMasonryFlashList
                          data={[{}].concat([{}].concat(fetchData))}
                          estimatedItemSize={50}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          listKey={'P44hiqkL'}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* UploadImageButton */}
                                <>
                                  {!(index === 0) ? null : (
                                    <Pressable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            const pickedMedia =
                                              await openImagePickerUtil({
                                                mediaTypes: 'All',
                                                allowsEditing: false,
                                                quality: 0.2,
                                                allowsMultipleSelection: false,
                                                permissionErrorMessage:
                                                  'Sorry, we need media library permissions to make this work.',
                                                showAlertOnPermissionError: true,
                                                outputBase64: true,
                                              });

                                            if (
                                              isWrongFormat(
                                                getSelectedFileType(pickedMedia)
                                              )
                                            ) {
                                              showAlertUtil({
                                                title: 'Wrong Format',
                                                message:
                                                  'You can not upload this file type',
                                                buttonText: undefined,
                                              });
                                            } else {
                                              setIsUploading(true);
                                              (
                                                await xanoServiceUploadFilePOST.mutateAsync(
                                                  { file: pickedMedia, urn: '' }
                                                )
                                              )?.json;
                                              /* hidden 'API Request' action */
                                              setIsUploading(false);
                                            }
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: cardHeight,
                                            justifyContent: 'center',
                                            width: cardWidth,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          name={'AntDesign/pluscircleo'}
                                          size={70}
                                        />
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              { marginTop: 8 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Upload New'}
                                        </Text>
                                      </View>
                                    </Pressable>
                                  )}
                                </>
                                <>
                                  {!(index === 1) ? null : (
                                    <Pressable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            if (Platform.OS === 'web') {
                                              setAdobeAction('create');
                                            } else {
                                              await openAdobeExpress(
                                                listData?.adobe_urn
                                              );
                                            }
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: cardHeight,
                                            justifyContent: 'center',
                                            width: cardWidth,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          name={'MaterialIcons/design-services'}
                                          size={70}
                                        />
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              { marginTop: 8 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Create Design'}
                                        </Text>
                                      </View>
                                    </Pressable>
                                  )}
                                </>
                                <>
                                  {!(index > 1) ? null : (
                                    <Pressable
                                      onPress={() => {
                                        try {
                                          setSelectedFile(listData);
                                          const invalidResult =
                                            isInvalidateImageRatio(listData);
                                          if (!invalidResult) {
                                            if (
                                              props.multi ??
                                              defaultProps.multi
                                            ) {
                                              toggleFunction(listData);
                                            } else {
                                              setFunction(listData);
                                            }

                                            setShowPicker(false);
                                            setAdobeAction('');
                                          } else {
                                            showAlertUtil({
                                              title: undefined,
                                              message: invalidResult,
                                              buttonText: undefined,
                                            });
                                          }
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              palettes.Brand.Light_Inverse,
                                            borderColor:
                                              theme.colors.background.brand,
                                            borderWidth: 1,
                                            height: cardHeight,
                                            overflow: 'hidden',
                                            width: cardWidth,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!(
                                            listData?.attachment?.type ===
                                            'image'
                                          ) ? null : (
                                            <Image
                                              {...GlobalStyles.ImageStyles(
                                                theme
                                              )['Image'].props}
                                              resizeMode={'contain'}
                                              source={imageSource(
                                                `${listData?.attachment?.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'].style,
                                                  {
                                                    height: '100%',
                                                    width: '100%',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.attachment?.type ===
                                            'video'
                                          ) ? null : (
                                            <VideoPlayer
                                              isLooping={false}
                                              rate={1}
                                              useNativeControls={true}
                                              usePoster={false}
                                              volume={0.5}
                                              {...GlobalStyles.VideoPlayerStyles(
                                                theme
                                              )['Video'].props}
                                              isMuted={true}
                                              posterResizeMode={'contain'}
                                              resizeMode={'contain'}
                                              shouldPlay={true}
                                              source={imageSource(
                                                `${listData?.attachment?.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.VideoPlayerStyles(
                                                  theme
                                                )['Video'].style,
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        {/* EditView */}
                                        <>
                                          {!(Platform.OS === 'web') ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    palettes.App
                                                      .Opacity_Blue_for_Button,
                                                  borderRadius: 12,
                                                  left: 8,
                                                  padding: 4,
                                                  position: 'absolute',
                                                  top: 8,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Pressable
                                                onPress={() => {
                                                  const handler = async () => {
                                                    try {
                                                      if (
                                                        Platform.OS === 'web'
                                                      ) {
                                                        if (
                                                          listData?.adobe_urn
                                                        ) {
                                                          setAdobeUploadsId(
                                                            listData?.id
                                                          );
                                                          setAdobeUrn(
                                                            listData?.adobe_urn
                                                          );
                                                          setAdobeAction(
                                                            'edit'
                                                          );
                                                        } else {
                                                          setAdobeUploadsId(
                                                            listData?.id
                                                          );
                                                          if (
                                                            (props.fileType ??
                                                              defaultProps.fileType) ===
                                                            'image'
                                                          ) {
                                                            const base64 =
                                                              await urlToBase64(
                                                                listData
                                                                  ?.attachment
                                                                  ?.url
                                                              );
                                                            setAdobeInitialImageAsset(
                                                              base64
                                                            );
                                                          } else {
                                                            setAdobeInitialImageAsset(
                                                              listData
                                                                ?.attachment
                                                                ?.url
                                                            );
                                                          }

                                                          setAdobeAction(
                                                            'createWithAsset'
                                                          );
                                                        }
                                                      } else {
                                                        await openAdobeExpress(
                                                          listData?.adobe_urn
                                                        );
                                                      }
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  };
                                                  handler();
                                                }}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                      gap: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Image
                                                    resizeMode={'cover'}
                                                    {...GlobalStyles.ImageStyles(
                                                      theme
                                                    )['Image'].props}
                                                    source={imageSource(
                                                      Images['adobeexpresslogo']
                                                    )}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.ImageStyles(
                                                          theme
                                                        )['Image'].style,
                                                        {
                                                          height: 24,
                                                          width: 24,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  />
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Edit'}
                                                  </Text>
                                                </View>
                                              </Pressable>
                                            </View>
                                          )}
                                        </>
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              backgroundColor:
                                                palettes.App
                                                  .Opacity_Blue_for_Button,
                                              borderRadius: 40,
                                              padding: 4,
                                              position: 'absolute',
                                              right: 8,
                                              top: 8,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* DeleteButton */}
                                          <IconButton
                                            onPress={() => {
                                              try {
                                                setFileToDelete(listData);
                                                setShowDeleteModal(true);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            color={
                                              theme.colors.background.danger
                                            }
                                            icon={'Ionicons/trash-outline'}
                                            size={24}
                                            style={StyleSheet.applyWidth(
                                              { marginTop: 0 },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                        {/* View 2 */}
                                        <>
                                          {!(
                                            props.orientation ??
                                            defaultProps.orientation
                                          ) ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    'rgba(255, 255, 255, 0.75)',
                                                  borderRadius: 40,
                                                  bottom: 8,
                                                  left: 8,
                                                  padding: 4,
                                                  position: 'absolute',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].style,
                                                    { fontSize: 11 }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {orientationLabel(listData)}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                      </View>
                                    </Pressable>
                                  )}
                                </>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          numColumns={
                            dimensions.width >= Breakpoints.Desktop
                              ? 5
                              : dimensions.width >= Breakpoints.Laptop
                              ? 4
                              : dimensions.width >= Breakpoints.Tablet
                              ? 3
                              : 2
                          }
                        />
                      </>
                    );
                  }}
                </XanoServiceApi.FetchGetUploadsGET>
              </View>
              {/* Modal Footer */}
              <View />
            </View>
          </Adobe.AdobeWrapper>
        </Utils.CustomCodeErrorBoundary>
        {/* DeleteView */}
        <>
          {!showDeleteModal ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  height: '100%',
                  position: 'absolute',
                  width: '100%',
                  zIndex: 999999,
                },
                dimensions.width
              )}
            >
              {/* BGView */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App.ViewBG,
                    flex: 1,
                    justifyContent: 'center',
                    padding: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors.background.brand,
                      borderColor: palettes.App.CustomGray,
                      borderRadius: 16,
                      borderWidth: 1,
                      maxWidth: 400,
                      width: '90%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Modal Header */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 1,
                        borderColor: palettes.App.CustomGray,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 16,
                        paddingBottom: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Poppins_700Bold' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Are you sure?'}
                    </Text>
                    <IconButton
                      onPress={() => {
                        try {
                          setShowDeleteModal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      icon={'Ionicons/close'}
                    />
                  </View>
                  {/* Modal Body */}
                  <View
                    style={StyleSheet.applyWidth(
                      { padding: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Poppins_400Regular' }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'You are about to delete this image. You can not undo this action. Do you want to proceed? '
                      }
                    </Text>
                  </View>
                  {/* Modal Footer */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        padding: 16,
                      },
                      dimensions.width
                    )}
                  >
                    {/* CancelView */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginRight: 4 },
                        dimensions.width
                      )}
                    >
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShowDeleteModal(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['OutlineButton']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['OutlineButton']
                            .style,
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                    {/* ConfirmView */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 4 },
                        dimensions.width
                      )}
                    >
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setIsDeleting(true);
                              (
                                await xanoServiceDeleteUploadDELETE.mutateAsync(
                                  { uploads_id: fileToDelete?.id }
                                )
                              )?.json;
                              setIsDeleting(false);
                              setShowDeleteModal(false);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        disabled={isDeleting}
                        loading={isDeleting}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'].style,
                            { backgroundColor: theme.colors.background.danger }
                          ),
                          dimensions.width
                        )}
                        title={'Yes, Delete!'}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
        </>
      </Modal>
    </View>
  );
};

export default withTheme(ImageGalleryPickerBlock);
