import React from 'react';
import {
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleMasonryFlashList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import CardBlock from '../components/CardBlock';
import NarrowHeaderWithDrawerBlock from '../components/NarrowHeaderWithDrawerBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const SavedScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [data, setData] = React.useState([]);
  const navigateToViewPromotion = (navigation, cardId) => {
    if (cardId) {
      navigation.navigate('ViewPromotionScreen', {
        PromotionId: cardId,
      });
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Saved');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'saved'} />
        )}
      </>
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <NarrowHeaderWithDrawerBlock title={'Saved'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="saved"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: theme.colors.background.brand,
            flex: 1,
            maxWidth: 1024,
            paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
            width: '100%',
          },
          dimensions.width
        )}
      >
        <View>
          <PromotionsServiceApi.FetchGetSavedPromotionsGET>
            {({ loading, error, data, refetchGetSavedPromotions }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <>
                    {dimensions.width >= Breakpoints.Tablet ? null : (
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'Bsa8ZIbp'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: 20,
                                  marginLeft: 20,
                                  marginRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <CardBlock
                                item={listData?.promotion}
                                mode={'card'}
                                onCardSelect={cardId =>
                                  navigateToViewPromotion(navigation, cardId)
                                }
                              />
                            </View>
                          );
                        }}
                        showsVerticalScrollIndicator={true}
                        showsHorizontalScrollIndicator={false}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10, marginTop: 10 },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  <>
                    {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                      <SimpleStyleMasonryFlashList
                        data={fetchData}
                        estimatedItemSize={50}
                        keyExtractor={(masonryListData, index) =>
                          masonryListData?.id ??
                          masonryListData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(masonryListData)
                        }
                        listKey={'X4gybZTq'}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const masonryListData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: 20,
                                  marginLeft: 20,
                                  marginRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <CardBlock
                                item={masonryListData?.promotion}
                                mode={'card'}
                                onCardSelect={cardId =>
                                  navigateToViewPromotion(navigation, cardId)
                                }
                              />
                            </View>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        numColumns={2}
                      />
                    )}
                  </>
                  {/* EmptyView */}
                  <>
                    {!(fetchData?.length === 0) ? null : (
                      <View>
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { textAlign: 'center' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'You have not saved any promotion yet.'}
                        </Text>
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </PromotionsServiceApi.FetchGetSavedPromotionsGET>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SavedScreen);
