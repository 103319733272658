import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const deleteEmailDELETE = async (
  Constants,
  { email_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:IANhBpP4/delete_mail/${encodeQueryParam(
    email_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteEmailDELETE = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['mailslurpAPiDeleteEmailDELETE', args],
    () => deleteEmailDELETE(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['mailslurpAPiDeleteEmailDELETES']),
    }
  );
};

export const getMailBodyGET = async (
  Constants,
  { email_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:IANhBpP4/get_mail_body/${encodeQueryParam(
    email_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetMailBodyGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['mailslurpAPiGetMailBodyGET', args],
    () => getMailBodyGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['mailslurpAPiGetMailBodyGETS']),
    }
  );
};

export const FetchGetMailBodyGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetMailBodyGET(
    { email_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetMailBody: refetch });
};

export const toggleReadGET = async (
  Constants,
  { email_id, is_read },
  handlers = {}
) => {
  const paramsDict = {};
  if (is_read !== undefined) {
    paramsDict['is_read'] = renderParam(is_read);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:IANhBpP4/toggle_read/${encodeQueryParam(
    email_id
  )}${renderQueryString(paramsDict, 'brackets')}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleReadGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['mailslurpAPiToggleReadGET', args],
    () => toggleReadGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['mailslurpAPiToggleReadGETS']),
    }
  );
};

export const FetchToggleReadGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email_id,
  is_read,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useToggleReadGET(
    { email_id, is_read },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleRead: refetch });
};
