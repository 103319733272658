import React, { useState, useEffect } from 'react';
import { View, Platform } from 'react-native';
import * as Animatable from 'react-native-animatable';
// import * as GlobalVariableContext from '../config/GlobalVariableContext';

export function Panel({ children, showPanel, setShowPanel, maxHeight = 350 }) {
  if (!setShowPanel) return;
  if (!children) return;

  const [shouldRender, setShouldRender] = useState(showPanel);

  useEffect(() => {
    if (showPanel) {
      setShouldRender(true);
    } else {
      setTimeout(() => {
        setShouldRender(false);
      }, 500); // set this to the duration of your animation
    }
  }, [showPanel]);

  if (!shouldRender) return null;

  return (
    <Animatable.View
      animation={
        showPanel
          ? {
              from: {
                opacity: Platform.OS === 'android' ? 1 : 0,
                height: 20,
                scale: 0,
              },
              to: {
                opacity: 1,
                height: maxHeight,
                scale: 1,
              },
            }
          : {
              from: {
                opacity: 1,
                height: maxHeight,
                scale: 1,
              },
              to: {
                opacity: Platform.OS === 'android' ? 1 : 0,
                height: 20,
                scale: 0,
              },
            }
      }
      duration={750}
    >
      <View style={{ flex: 1 }}>{children}</View>
    </Animatable.View>
  );
}
