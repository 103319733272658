import React from 'react';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { Text } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import ReelsPlayerBlock from '../components/ReelsPlayerBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  asset_type: 'video',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ullamcorper nisi at orci rutrum, vulputate viverra purus ullamcorper. Vivamus sed fermentum ex, non varius diam. Phasellus vel auctor ex. Quisque aliquet euismod velit, vel placerat nisl ultrices posuere. Phasellus porta non purus id auctor. Praesent nec dolor varius, blandit ipsum a, suscipit sem. Duis vehicula nisl id lorem luctus, non sollicitudin sapien vulputate. Aliquam interdum euismod odio id mollis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam ornare diam at quam pulvinar placerat. Curabitur tempor in nulla in bibendum. Aliquam porta hendrerit nibh, et pulvinar erat ullamcorper sed.  Vivamus ornare at orci vitae mattis. Vivamus varius lectus id maximus iaculis. Cras fringilla augue leo, a gravida massa vehicula eu. Suspendisse finibus, dui sit amet commodo gravida, sem justo sollicitudin nisi, at rutrum velit sem id magna. Nullam a bibendum purus. Proin efficitur est vel libero gravida gravida euismod quis dolor. Aenean magna nunc, placerat non arcu vitae, accumsan convallis ligula.  Etiam pellentesque ipsum ligula, at pellentesque tortor pulvinar a. Donec sagittis ipsum felis, quis vehicula neque sollicitudin et. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus non ullamcorper metus. Nulla tincidunt leo non efficitur venenatis. Nunc luctus et nunc quis rhoncus. Nunc dictum sem a neque aliquam pellentesque. In laoreet blandit risus, et condimentum nisl pulvinar at. Suspendisse sit amet elementum nunc, nec dignissim neque. Curabitur eget mi sed orci tincidunt dictum. Quisque posuere nisi velit, pulvinar auctor nisl iaculis vel. Aliquam consequat neque et velit eleifend imperdiet. Quisque blandit elit metus, at feugiat turpis scelerisque efficitur. Quisque iaculis eu tellus a vulputate.',
  details_images: [
    {
      id: 1,
      user_id: 4,
      adobe_urn: '',
      asset_type: 'video',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/K7SLwPbWCHrWhG6C4m52tq2MObk/Qec51w../file-6debb5.mp4',
        meta: {
          video: {
            dar: false,
            fps: 30,
            par: false,
            res: '1080x1920',
            codec: 'h264 (High) (avc1 / 0x31637661)',
            bitrate: 406000,
            profile: 'yuv420p(tv',
            postWidth: 1080,
            postHeight: 1920,
          },
          duration: 3.87,
        },
        mime: 'video/mp4',
        name: 'file-6debb5.mp4',
        path: '/vault/cH9onT_o/K7SLwPbWCHrWhG6C4m52tq2MObk/Qec51w../file-6debb5.mp4',
        size: 201827,
        type: 'video',
        access: 'public',
      },
      created_at: 1715871025251,
    },
  ],
  logo: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/3qSThRU3b4dYmiEiTxLlcJ2_NmE/C6WmuA../zip-code.png',
  promoDiscount: 50,
  subTitle: 'New Business',
  title: 'Title of Promo',
};

const PreviewExplorePromotionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [isSaving, setIsSaving] = React.useState(false);
  const [promotionId, setPromotionId] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const goBack = () => {
    navigation.goBack();
  };

  const generateSample = () => {
    console.log('props', props.route?.params);
    const item = {
      id: 849,
      created_at: 1692200687000,
      details_images: props.route?.params?.details_images,
      redirect_link: 'https://promo.example.com/promo855',
      title: props.route?.params?.title,
      content: props.route?.params?.content,
      asset_type: props.route?.params?.asset_type,
      promotion_type: 'global',
      budget: 63574,
      remaining_budget: 63574,
      amount_to_user: 100,
      amount_to_mailboxx: 10,
      is_active: true,
      expires_at: 1717105184066,
      creator_id: 1040,
      addresses_id: 1041,
      radius: 56326,
      gender: ['male', 'female', 'other', 'prefer-not-to-say'],
      min_age: 1,
      max_age: 99,
      for_explore: false,
      business_name: props.route?.params?.subTitle && 'Global John Davis LLC',
      business_categories_id: 8,
      hero_image: {
        id: 115,
        created_at: 1709205752511,
        user_id: 310,
        attachment: {
          access: 'public',
          path: '/vault/cH9onT_o/lMJLx7NdqwMeEJXZj-jYUP8CF_w/QZsT2g../finance_bnaner.png',
          name: 'finance_bnaner.png',
          type: 'image',
          size: 137842,
          mime: 'image/png',
          meta: {
            width: 940,
            height: 480,
          },
          url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/lMJLx7NdqwMeEJXZj-jYUP8CF_w/QZsT2g../finance_bnaner.png',
        },
      },
      logo: props.route?.params?.logo,
      _is_consumed_by_user: false,
    };
    console.log(item);
    return [item];
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role === 'user') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Preview Explore Promotion');
      setPromotionId(null);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={true}>
      {/* ReelsPlayerModal */}
      <ReelsPlayerBlock items={generateSample()} onClose={() => goBack()} />
      <Text
        accessible={true}
        selectable={false}
        {...GlobalStyles.TextStyles(theme)['Text'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.TextStyles(theme)['Text'].style,
          dimensions.width
        )}
      >
        {props.route?.params?.title ?? defaultProps.title}
      </Text>
    </ScreenContainer>
  );
};

export default withTheme(PreviewExplorePromotionScreen);
