import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const autocompleteGET = async (
  Constants,
  { address },
  handlers = {}
) => {
  const paramsDict = {};
  if (address !== undefined) {
    paramsDict['address'] = renderParam(address);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:4A-RzAj1/autocomplete${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAutocompleteGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['googleGeocodingAutocompleteGET', args],
    () => autocompleteGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['googleGeocodingAutocompleteGETS']),
    }
  );
};

export const FetchAutocompleteGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  address,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAutocompleteGET(
    { address },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAutocomplete: refetch });
};

export const getAddressGET = async (Constants, { lat, lng }, handlers = {}) => {
  const paramsDict = {};
  if (lat !== undefined) {
    paramsDict['lat'] = renderParam(lat);
  }
  if (lng !== undefined) {
    paramsDict['lng'] = renderParam(lng);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:4A-RzAj1/get_address${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAddressGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['googleGeocodingGetAddressGET', args],
    () => getAddressGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['googleGeocodingGetAddressGETS']),
    }
  );
};

export const FetchGetAddressGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  lat,
  lng,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAddressGET(
    { lat, lng },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAddress: refetch });
};

export const getLocationGET = async (Constants, { address }, handlers = {}) => {
  const paramsDict = {};
  if (address !== undefined) {
    paramsDict['address'] = renderParam(address);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:4A-RzAj1/get_location${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetLocationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['googleGeocodingGetLocationGET', args],
    () => getLocationGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['googleGeocodingGetLocationGETS']),
    }
  );
};

export const FetchGetLocationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  address,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLocationGET(
    { address },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLocation: refetch });
};

export const getPlaceDetailsGET = async (
  Constants,
  { placeId },
  handlers = {}
) => {
  const paramsDict = {};
  if (placeId !== undefined) {
    paramsDict['place_id'] = renderParam(placeId);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:4A-RzAj1/get_place_details${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPlaceDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['googleGeocodingGetPlaceDetailsGET', args],
    () => getPlaceDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['googleGeocodingGetPlaceDetailsGETS']),
    }
  );
};

export const FetchGetPlaceDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  placeId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPlaceDetailsGET(
    { placeId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPlaceDetails: refetch });
};
