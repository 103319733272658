import React from 'react';
import {
  Button,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TabView,
  TabViewItem,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import CardBlock from '../components/CardBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import isLoggedIn from '../global-functions/isLoggedIn';
import subtractPadding from '../global-functions/subtractPadding';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const MyPromotionsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [activePromotionsCount, setActivePromotionsCount] = React.useState(-1);
  const [expiredPromotionsCount, setExpiredPromotionsCount] =
    React.useState(-1);
  const [refreshValue, setRefreshValue] = React.useState(0);
  const [showPromotionTypePicker, setShowPromotionTypePicker] =
    React.useState(false);
  const cardOnChange = () => {
    return () => setRefreshValue(Math.floor(Math.random() * 1000000) + 1);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role === 'user') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'My Promotions');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: [
            {
              minWidth: Breakpoints.Mobile,
              value: theme.colors.background.brand,
            },
            { minWidth: Breakpoints.Desktop, value: palettes.App.CustomGray },
          ],
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'mypromotions'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="mypromotions"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AddPromotionbutton */}
            <IconButton
              onPress={() => {
                try {
                  trackAction(
                    Variables,
                    segment,
                    'Clicked Add Promotion Button',
                    undefined
                  );
                  setShowPromotionTypePicker(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Ionicons/add-circle-sharp'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', flex: 1, marginTop: 12 },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Desktop,
                value: theme.colors.background.brand,
              },
              height:
                dimensions.width >= Breakpoints.Laptop
                  ? subtractPadding(dimensions.height, 56)
                  : dimensions.height,
              maxWidth: 1024,
              minHeight: { minWidth: Breakpoints.Desktop, value: 40 },
              width: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Desktop, value: '100%' },
              ],
            },
            dimensions.width
          )}
        >
          <TabView
            activeColor={theme.colors.branding.primary}
            iconPosition={'top'}
            indicatorColor={theme.colors.branding.primary}
            initialTabIndex={0}
            pressColor={theme.colors.branding.primary}
            scrollEnabled={false}
            tabBarPosition={'top'}
            inactiveColor={theme.colors.text.medium}
            keyboardDismissMode={'on-drag'}
            style={StyleSheet.applyWidth(
              { fontFamily: 'Poppins_400Regular' },
              dimensions.width
            )}
            swipeEnabled={false}
            tabsBackgroundColor={theme.colors.background.brand}
          >
            {/* ActivePromotions */}
            <TabViewItem
              {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.CustomGray,
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors.background.brand,
                      },
                    ],
                  }
                ),
                dimensions.width
              )}
              title={'Active Promotions'}
            >
              <PromotionsServiceApi.FetchGetMyPromotionsGET
                get_expired={false}
                handlers={{
                  onData: fetchData => {
                    try {
                      setActivePromotionsCount(fetchData?.length);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                refresh={refreshValue}
              >
                {({ loading, error, data, refetchGetMyPromotions }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Desktop,
                              value: 'center',
                            },
                            flex: { minWidth: Breakpoints.Desktop, value: 1 },
                            justifyContent: {
                              minWidth: Breakpoints.Desktop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].style,
                            dimensions.width
                          )}
                        />
                      </View>
                    );
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* EmptyView */}
                      <>
                        {!(activePromotionsCount === 0) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flex: 1,
                                justifyContent: 'center',
                                padding: 20,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: 'Poppins_300Light',
                                    fontSize: 18,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                'You currently have no active promotions. Click on the icon in the top right corner to get started and create your first promotion!'
                              }
                            </Text>
                          </View>
                        )}
                      </>
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'o5XUEV9y'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    marginBottom: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                <CardBlock
                                  item={listData}
                                  mode={'details'}
                                  onChange={cardOnChange()}
                                />
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10, marginTop: 10 },
                          dimensions.width
                        )}
                      />
                    </>
                  );
                }}
              </PromotionsServiceApi.FetchGetMyPromotionsGET>
            </TabViewItem>
            {/* ExpiredPromotions */}
            <TabViewItem
              {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors.background.brand,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.CustomGray,
                      },
                    ],
                  }
                ),
                dimensions.width
              )}
              title={'Expired Promotions'}
            >
              <PromotionsServiceApi.FetchGetMyPromotionsGET
                get_expired={true}
                handlers={{
                  onData: fetchData => {
                    try {
                      setExpiredPromotionsCount(fetchData?.length);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                refresh={refreshValue}
              >
                {({ loading, error, data, refetchGetMyPromotions }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return (
                      <>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Desktop,
                                value: 'center',
                              },
                              flex: { minWidth: Breakpoints.Desktop, value: 1 },
                              justifyContent: {
                                minWidth: Breakpoints.Desktop,
                                value: 'center',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            {...GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].style,
                              dimensions.width
                            )}
                          />
                        </View>
                      </>
                    );
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* EmptyView */}
                      <>
                        {!(activePromotionsCount === 0) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flex: 1,
                                justifyContent: 'center',
                                padding: 20,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: 'Poppins_300Light',
                                    fontSize: 18,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'You currently have no expired promotions.'}
                            </Text>
                          </View>
                        )}
                      </>
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'J4bLCGm3'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: 10,
                                  marginLeft: 20,
                                  marginRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <CardBlock
                                item={listData}
                                mode={'details'}
                                onChange={cardOnChange()}
                              />
                            </View>
                          );
                        }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10, marginTop: 10 },
                          dimensions.width
                        )}
                      />
                    </>
                  );
                }}
              </PromotionsServiceApi.FetchGetMyPromotionsGET>
            </TabViewItem>
          </TabView>
        </View>
        {/* Footer */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  backgroundColor: theme.colors.background.brand,
                  justifyContent: 'center',
                  paddingBottom: 4,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 4,
                },
                dimensions.width
              )}
            >
              {/* AddPromotionButton */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Add Promotion Button',
                      undefined
                    );
                    setShowPromotionTypePicker(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  dimensions.width
                )}
                title={'Add New Promotion'}
              />
            </View>
          )}
        </>
      </View>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        transparent={true}
        visible={showPromotionTypePicker}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
              backgroundColor: palettes.App['Transparent Overlay'],
              flex: 1,
              justifyContent: 'flex-end',
              maxWidth: 1024,
            },
            dimensions.width
          )}
        >
          {/* BottomSheetHeader View */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors.background.brand,
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 8,
              },
              dimensions.width
            )}
          >
            <View>
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_600SemiBold' }
                  ),
                  dimensions.width
                )}
              >
                {'Please select a promotion type'}
              </Text>
            </View>
            <IconButton
              onPress={() => {
                try {
                  setShowPromotionTypePicker(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/closecircleo'}
            />
          </View>
          {/* Buttons */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                flexDirection: 'row',
                gap: 8,
                justifyContent: 'space-evenly',
                padding: 8,
              },
              dimensions.width
            )}
          >
            {/* NearbyPromotion */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <Pressable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Location Based Promotion Button',
                      undefined
                    );
                    navigation.navigate('AddPromotionSelectTypeScreen', {
                      isNearBy: true,
                    });
                    setShowPromotionTypePicker(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors.text.light,
                      borderRadius: 8,
                      borderWidth: 1,
                      height: 400,
                      overflow: 'hidden',
                    },
                    dimensions.width
                  )}
                >
                  {/* ImageView */}
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['NearByPromotionsMin'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: '100%', width: '100%' }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* TextView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, padding: 8 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Poppins_500Medium' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Location Based Promotion'}
                    </Text>
                    {/* Description */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      ellipsizeMode={'head'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontSize: 12, marginTop: 8 }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'You have the ability to create a targeted promotion for users residing in a specific location'
                      }
                    </Text>
                  </View>
                </View>
              </Pressable>
            </View>
            {/* NationalPromotion */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <Pressable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked National Promotion Button',
                      undefined
                    );
                    navigation.navigate('AddPromotionSelectTypeScreen', {
                      isNearBy: false,
                    });
                    setShowPromotionTypePicker(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors.text.light,
                      borderRadius: 8,
                      borderWidth: 1,
                      height: 400,
                      overflow: 'hidden',
                    },
                    dimensions.width
                  )}
                >
                  {/* ImageView */}
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['NationalPromotionsMin'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: '100%', width: '100%' }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* TextView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, padding: 8 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Poppins_500Medium' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'National Promotion'}
                    </Text>
                    {/* Description */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      ellipsizeMode={'head'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontSize: 12, marginTop: 8 }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'We can develop a special offer for customers with verified addresses. You can still utilize various promotional strategies such as inbox or category listings to ensure the best possible match.'
                      }
                    </Text>
                  </View>
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(MyPromotionsScreen);
