import React from 'react';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleMasonryFlashList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import AddressDisplayBlock from '../components/AddressDisplayBlock';
import CardBlock from '../components/CardBlock';
import CategoryPromotionsBlock from '../components/CategoryPromotionsBlock';
import ExplorePromotionsBlock from '../components/ExplorePromotionsBlock';
import InboxRowBlock from '../components/InboxRowBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const InboxScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const [selectedCategory, setSelectedCategory] = React.useState(-1);
  const [show_addressPicker, setShow_addressPicker] = React.useState(false);
  const [startingCategoryPromotionId, setStartingCategoryPromotionId] =
    React.useState(-1);
  const [totalCategoryPromotions, setTotalCategoryPromotions] =
    React.useState(-1);
  const [totalInboxPromotions, setTotalInboxPromotions] = React.useState(-1);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [refreshingarWQUlG8, setRefreshingarWQUlG8] = React.useState(false);
  const hideCategoryPromotionsPlayer = () => {
    setStartingCategoryPromotionId(-1);
  };

  const onCategoryCardSelect = cardId => {
    setStartingCategoryPromotionId(cardId);
  };

  const filterVerifiedOnly = arr => {
    if (!Array.isArray(arr)) return arr;

    return (arr || [])?.filter(address => address?.verified) || arr;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Inbox');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'inbox'} />
        )}
      </>
      {/* Drawer */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="inbox"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <XanoServiceApi.FetchGetAddressesGET
          handlers={{
            onData: fetchData => {
              try {
                if (!selectedAddress?.id) {
                  setSelectedAddress(
                    filterVerifiedOnly(fetchData) &&
                      filterVerifiedOnly(fetchData)[0]
                  );
                }
                /* hidden 'Log to Console' action */
              } catch (err) {
                console.error(err);
              }
            },
          }}
        >
          {({ loading, error, data, refetchGetAddresses }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* FilterView */}
                <>
                  {!(fetchData?.length > 1) ? null : (
                    <View
                      {...GlobalStyles.ViewStyles(theme)['FilterView'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['FilterView'].style,
                          { marginLeft: 20, marginRight: 20 }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* AddressPicker */}
                      <Pressable
                        onPress={() => {
                          try {
                            setShow_addressPicker(!show_addressPicker);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App.CustomGray,
                              borderColor: palettes.App.CustomGray,
                              borderRadius: 12,
                              borderWidth: 1,
                              padding: 8,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { fontFamily: 'Poppins_700Bold' }
                              ),
                              dimensions.width
                            )}
                          >
                            {selectedAddress?.title}
                            {', '}
                            {selectedAddress?.city}
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  )}
                </>
                {/* AddressesView */}
                <>
                  {!show_addressPicker ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App.White,
                          borderColor: palettes.App.CustomGray,
                          borderWidth: 1,
                          position: 'absolute',
                          top: 50,
                          width: '100%',
                          zIndex: 999,
                        },
                        dimensions.width
                      )}
                    >
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'n916kiEf'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <Pressable
                              onPress={() => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Selected address',
                                    listData
                                  );
                                  setSelectedAddress(listData);
                                  setShow_addressPicker(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* SelectedView */}
                              <>
                                {!(
                                  selectedAddress?.id === listData?.id
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App.PrimarySelected,
                                        height: '100%',
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        width: '100%',
                                        zIndex: 0,
                                      },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Address Display */}
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'Address Display 3'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'Address Display 3'
                                    ].style,
                                    {
                                      borderColor: palettes.App.CustomGray,
                                      borderTopWidth: 1,
                                      overflow: 'hidden',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <AddressDisplayBlock
                                  addressId={listData?.id}
                                  city={listData?.city}
                                  country={listData?.country}
                                  house_number={listData?.house_number}
                                  isPrimary={listData?.is_primary}
                                  isVerified={listData?.verified}
                                  road={listData?.road}
                                  state={listData?.state}
                                  title={listData?.title}
                                  zip={listData?.zip}
                                />
                              </View>
                            </Pressable>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </View>
                  )}
                </>
                <>
                  {!(filterVerifiedOnly(fetchData)?.length === 0) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 18, textAlign: 'center' }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Please add an verified address first to receive promotions in your inbox'
                        }
                      </Text>
                      {/* AddressesBtn */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            trackAction(
                              Variables,
                              segment,
                              'Clicked Addresses Button',
                              undefined
                            );
                            navigation.navigate('AddressesScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'].style,
                            { marginTop: 20 }
                          ),
                          dimensions.width
                        )}
                        title={'Addresses'}
                      />
                    </View>
                  )}
                </>
              </>
            );
          }}
        </XanoServiceApi.FetchGetAddressesGET>
        <>
          {!selectedAddress?.id ? null : (
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* CategoryButtons */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 50 },
                      { minWidth: Breakpoints.Desktop, value: 60 },
                    ],
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Desktop, value: 12 },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Desktop, value: 12 },
                    ],
                    marginTop: 12,
                  },
                  dimensions.width
                )}
              >
                <XanoServiceApi.FetchGetBussinessCategoriesGET
                  excludeEmpty={true}
                >
                  {({
                    loading,
                    error,
                    data,
                    refetchGetBussinessCategories,
                  }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={fetchData}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        listKey={'ReR912tP'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* Inbox */}
                              <>
                                {!(index === 0) ? null : (
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        trackAction(
                                          Variables,
                                          segment,
                                          'Clicked Inbox Button',
                                          undefined
                                        );
                                        setSelectedCategory(-1);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Selected */}
                                    <>
                                      {!(selectedCategory === -1) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              backgroundColor:
                                                theme.colors.branding.primary,
                                              borderRadius: 32,
                                              flexDirection: 'row',
                                              height: 38,
                                              marginBottom: 16,
                                              marginRight: 8,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            color={palettes.App.White}
                                            name={'Feather/inbox'}
                                            size={20}
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 8 },
                                              dimensions.width
                                            )}
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                { color: palettes.App.White }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Inbox'}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* UnSelected */}
                                    <>
                                      {!(selectedCategory !== -1) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              backgroundColor:
                                                palettes.App.PrimarySelected,
                                              borderRadius: 32,
                                              flexDirection: 'row',
                                              height: 38,
                                              marginBottom: 16,
                                              marginRight: 8,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            color={palettes.App.White}
                                            name={'Feather/inbox'}
                                            size={20}
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 8 },
                                              dimensions.width
                                            )}
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                { color: palettes.App.White }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Inbox'}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                  </Pressable>
                                )}
                              </>
                              {/* Explore */}
                              <>
                                {!(index === 0) ? null : (
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        trackAction(
                                          Variables,
                                          segment,
                                          'Clicked Explore Button',
                                          undefined
                                        );
                                        setSelectedCategory(0);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Selected */}
                                    <>
                                      {!(selectedCategory === 0) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              backgroundColor:
                                                theme.colors.branding.primary,
                                              borderRadius: 32,
                                              flexDirection: 'row',
                                              height: 38,
                                              marginBottom: 16,
                                              marginRight: 8,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            color={palettes.App.White}
                                            name={'MaterialIcons/explore'}
                                            size={20}
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 8 },
                                              dimensions.width
                                            )}
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                { color: palettes.App.White }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Explore'}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* UnSelected */}
                                    <>
                                      {!(selectedCategory !== 0) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              backgroundColor:
                                                palettes.App.PrimarySelected,
                                              borderRadius: 32,
                                              flexDirection: 'row',
                                              height: 38,
                                              marginBottom: 16,
                                              marginRight: 8,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            color={palettes.App.White}
                                            name={'MaterialIcons/explore'}
                                            size={20}
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 8 },
                                              dimensions.width
                                            )}
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                { color: palettes.App.White }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Explore'}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                  </Pressable>
                                )}
                              </>
                              <Pressable
                                onPress={() => {
                                  try {
                                    if (selectedCategory === listData?.id) {
                                      /* hidden 'Set Variable' action */
                                    } else {
                                      setTotalCategoryPromotions(-1);
                                      setSelectedCategory(listData?.id);
                                      trackAction(
                                        Variables,
                                        segment,
                                        'Clicked Business Category Button',
                                        listData?.name
                                      );
                                    }
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Selected */}
                                <>
                                  {!(
                                    selectedCategory === listData?.id
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors.branding.secondary,
                                          borderRadius: 32,
                                          height: 38,
                                          justifyContent: 'center',
                                          marginBottom: 16,
                                          marginRight: 8,
                                          paddingLeft: 16,
                                          paddingRight: 16,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { color: palettes.App.White }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.name}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* UnSelected */}
                                <>
                                  {!(
                                    selectedCategory !== listData?.id
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App.SecondarySelected,
                                          borderRadius: 32,
                                          height: 38,
                                          justifyContent: 'center',
                                          marginBottom: 16,
                                          marginRight: 8,
                                          paddingLeft: 16,
                                          paddingRight: 16,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { color: palettes.App.White }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.name}
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </Pressable>
                            </>
                          );
                        }}
                        horizontal={true}
                        keyboardShouldPersistTaps={'always'}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={true}
                      />
                    );
                  }}
                </XanoServiceApi.FetchGetBussinessCategoriesGET>
              </View>
              {/* Inbox */}
              <>
                {!(selectedCategory === -1) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: [
                          { minWidth: Breakpoints.Desktop, value: 1 },
                          { minWidth: Breakpoints.Mobile, value: 1 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!selectedAddress?.id ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'stretch',
                              flex: 1,
                              marginLeft: 20,
                              marginRight: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!selectedAddress?.verified ? null : (
                              <PromotionsServiceApi.FetchGetPromotionsForInboxGET
                                addresses_id={selectedAddress?.id}
                                handlers={{
                                  onData: fetchData => {
                                    try {
                                      setTotalInboxPromotions(
                                        fetchData?.length
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  },
                                }}
                              >
                                {({
                                  loading,
                                  error,
                                  data,
                                  refetchGetPromotionsForInbox,
                                }) => {
                                  const fetchData = data?.json;
                                  if (loading) {
                                    return (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flex: 1,
                                            justifyContent: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <ActivityIndicator
                                          animating={true}
                                          hidesWhenStopped={true}
                                          {...GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].props}
                                          color={theme.colors.branding.primary}
                                          size={'large'}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ActivityIndicatorStyles(
                                              theme
                                            )['Activity Indicator'].style,
                                            dimensions.width
                                          )}
                                        />
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Loading Promotions'}
                                        </Text>
                                      </View>
                                    );
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <>
                                      {/* EmptyView */}
                                      <>
                                        {!(
                                          totalInboxPromotions === 0
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flex: 1,
                                                justifyContent: 'center',
                                                padding: 20,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* EnterValidAddressWarning 2 */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    fontSize: 20,
                                                    textAlign: 'center',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                'Sorry, there are no active promotions to view in your inbox at the moment'
                                              }
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      <>
                                        {dimensions.width >=
                                        Breakpoints.Tablet ? null : (
                                          <SimpleStyleFlatList
                                            data={fetchData}
                                            horizontal={false}
                                            inverted={false}
                                            keyExtractor={(listData, index) =>
                                              listData?.id ??
                                              listData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(listData)
                                            }
                                            keyboardShouldPersistTaps={'never'}
                                            listKey={'arWQUlG8'}
                                            nestedScrollEnabled={false}
                                            numColumns={1}
                                            onEndReachedThreshold={0.5}
                                            refreshControl={
                                              <RefreshControl
                                                refreshing={refreshingarWQUlG8}
                                                onRefresh={() => {
                                                  const handler = async () => {
                                                    try {
                                                      setRefreshingarWQUlG8(
                                                        true
                                                      );
                                                      await refetchGetPromotionsForInbox();
                                                      setRefreshingarWQUlG8(
                                                        false
                                                      );
                                                    } catch (err) {
                                                      console.error(err);
                                                      setRefreshingarWQUlG8(
                                                        false
                                                      );
                                                    }
                                                  };
                                                  handler();
                                                }}
                                              />
                                            }
                                            renderItem={({ item, index }) => {
                                              const listData = item;
                                              return (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flex: {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 1,
                                                      },
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* PromotionCard */}
                                                  <>
                                                    {listData?.inboxId ? null : (
                                                      <CardBlock
                                                        item={listData}
                                                        mode={'card'}
                                                      />
                                                    )}
                                                  </>
                                                  <>
                                                    {!listData?.inboxId ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            borderBottomWidth: 1,
                                                            borderColor:
                                                              palettes.Brand
                                                                .Divider,
                                                            flexDirection:
                                                              'row',
                                                            gap: 8,
                                                            paddingBottom: 6,
                                                            paddingTop: 6,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Checkbox
                                                          onPress={newCheckboxValue => {
                                                            const checkboxValue =
                                                              newCheckboxValue;
                                                            try {
                                                              setCheckboxValue(
                                                                checkboxValue
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          status={checkboxValue}
                                                        />
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            { flex: 1 },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <InboxRowBlock
                                                            email={listData}
                                                          />
                                                        </View>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                              );
                                            }}
                                            showsVerticalScrollIndicator={true}
                                            showsHorizontalScrollIndicator={
                                              false
                                            }
                                            style={StyleSheet.applyWidth(
                                              { flex: 1, gap: 15 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          dimensions.width >= Breakpoints.Tablet
                                        ) ? null : (
                                          <SimpleStyleMasonryFlashList
                                            data={fetchData}
                                            estimatedItemSize={50}
                                            keyExtractor={(
                                              masonryListData,
                                              index
                                            ) =>
                                              masonryListData?.id ??
                                              masonryListData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(masonryListData)
                                            }
                                            listKey={'ZGqF6DaJ'}
                                            onEndReachedThreshold={0.5}
                                            renderItem={({ item, index }) => {
                                              const masonryListData = item;
                                              return (
                                                <>
                                                  {/* View 2 */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        marginBottom: {
                                                          minWidth:
                                                            Breakpoints.Tablet,
                                                          value: 15,
                                                        },
                                                        marginRight: {
                                                          minWidth:
                                                            Breakpoints.Tablet,
                                                          value:
                                                            index % 2 === 1
                                                              ? 0
                                                              : 15,
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Card 2 */}
                                                    <CardBlock
                                                      item={masonryListData}
                                                      mode={'card'}
                                                    />
                                                  </View>
                                                </>
                                              );
                                            }}
                                            showsHorizontalScrollIndicator={
                                              true
                                            }
                                            showsVerticalScrollIndicator={true}
                                            numColumns={2}
                                          />
                                        )}
                                      </>
                                    </>
                                  );
                                }}
                              </PromotionsServiceApi.FetchGetPromotionsForInboxGET>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* CategoryViews */}
              <>
                {!(selectedCategory > 0) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 1 },
                          { minWidth: Breakpoints.Desktop, value: 1 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!selectedAddress?.id ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'stretch',
                              flex: 1,
                              paddingBottom: 50,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!selectedAddress?.verified ? null : (
                              <PromotionsServiceApi.FetchGetPromotionsForCategoryGET
                                addresses_id={selectedAddress?.id}
                                business_categories_id={selectedCategory}
                                handlers={{
                                  onData: fetchData => {
                                    try {
                                      setTotalCategoryPromotions(
                                        fetchData?.length
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  },
                                }}
                                limit={10}
                                scrolling_mode={false}
                              >
                                {({
                                  loading,
                                  error,
                                  data,
                                  refetchGetPromotionsForCategory,
                                }) => {
                                  const fetchData = data?.json;
                                  if (loading) {
                                    return (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flex: 1,
                                            justifyContent: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <ActivityIndicator
                                          animating={true}
                                          hidesWhenStopped={true}
                                          {...GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].props}
                                          color={palettes.App.SecondarySelected}
                                          size={'large'}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ActivityIndicatorStyles(
                                              theme
                                            )['Activity Indicator'].style,
                                            dimensions.width
                                          )}
                                        />
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color:
                                                  palettes.App
                                                    .SecondarySelected,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Loading Promotions'}
                                        </Text>
                                      </View>
                                    );
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <>
                                      {/* EmptyView */}
                                      <>
                                        {!(
                                          totalCategoryPromotions === 0
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flex: 1,
                                                justifyContent: 'center',
                                                padding: 20,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* EnterValidAddressWarning 2 */}
                                            <>
                                              {!selectedAddress?.verified ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].style,
                                                      {
                                                        color:
                                                          palettes.App
                                                            .SecondarySelected,
                                                        fontSize: 20,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    'Sorry, there are no active promotions to view under this category at the moment'
                                                  }
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                        )}
                                      </>
                                      <>
                                        {dimensions.width >=
                                        Breakpoints.Tablet ? null : (
                                          <SimpleStyleFlatList
                                            data={fetchData}
                                            horizontal={false}
                                            inverted={false}
                                            keyExtractor={(listData, index) =>
                                              listData?.id ??
                                              listData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(listData)
                                            }
                                            keyboardShouldPersistTaps={'never'}
                                            listKey={'QHCn4DNg'}
                                            nestedScrollEnabled={false}
                                            numColumns={1}
                                            onEndReachedThreshold={0.5}
                                            renderItem={({ item, index }) => {
                                              const listData = item;
                                              return (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      marginBottom: 15,
                                                      marginLeft: 20,
                                                      marginRight: 20,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* CategoryCard */}
                                                  <CardBlock
                                                    item={listData}
                                                    mode={'card'}
                                                    onCardSelect={cardId =>
                                                      onCategoryCardSelect(
                                                        cardId
                                                      )
                                                    }
                                                    useNavigate={false}
                                                  />
                                                </View>
                                              );
                                            }}
                                            showsVerticalScrollIndicator={true}
                                            showsHorizontalScrollIndicator={
                                              false
                                            }
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          dimensions.width >= Breakpoints.Tablet
                                        ) ? null : (
                                          <SimpleStyleMasonryFlashList
                                            data={fetchData}
                                            estimatedItemSize={50}
                                            keyExtractor={(
                                              masonryListData,
                                              index
                                            ) =>
                                              masonryListData?.id ??
                                              masonryListData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(masonryListData)
                                            }
                                            listKey={'ubaDTh6z'}
                                            onEndReachedThreshold={0.5}
                                            renderItem={({ item, index }) => {
                                              const masonryListData = item;
                                              return (
                                                <>
                                                  {/* View 2 */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      { marginBottom: 15 },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* CategoryCard */}
                                                    <CardBlock
                                                      item={masonryListData}
                                                      mode={'card'}
                                                      onCardSelect={cardId =>
                                                        onCategoryCardSelect(
                                                          cardId
                                                        )
                                                      }
                                                      useNavigate={false}
                                                    />
                                                  </View>
                                                </>
                                              );
                                            }}
                                            showsHorizontalScrollIndicator={
                                              true
                                            }
                                            showsVerticalScrollIndicator={true}
                                            numColumns={2}
                                          />
                                        )}
                                      </>
                                    </>
                                  );
                                }}
                              </PromotionsServiceApi.FetchGetPromotionsForCategoryGET>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          )}
        </>
      </View>
      <>
        {!(selectedCategory === 0) ? null : (
          <ExplorePromotionsBlock
            onClose={() => {
              try {
                setSelectedCategory(-1);
              } catch (err) {
                console.error(err);
              }
            }}
            address_id={selectedAddress?.id}
            limit={5}
          />
        )}
      </>
      <>
        {!(parseInt(startingCategoryPromotionId, 10) !== -1) ? null : (
          <CategoryPromotionsBlock
            onClose={() => {
              try {
                setStartingCategoryPromotionId(-1);
              } catch (err) {
                console.error(err);
              }
            }}
            address_id={selectedAddress?.id}
            business_categories_id={selectedCategory}
            limit={5}
            startingPromotionId={startingCategoryPromotionId}
          />
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(InboxScreen);
