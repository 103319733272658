import React from 'react';
import { Icon, Link, withTheme } from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import { ActivityIndicator, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  addressId: 1,
  city: 'Roseville',
  country: 'United States',
  house_number: 95765,
  isPrimary: false,
  isVerified: true,
  road: 'Fairway Drive',
  showPrimaryInformation: false,
  state: 'California',
  title: "Jonah's Home",
  titleColor: '#000000',
  zip: 68106,
};

const AddressDisplayBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const xanoServiceUpdatePrimaryAddressPOST =
    XanoServiceApi.useUpdatePrimaryAddressPOST();

  return (
    <View>
      {/* TitleRow */}
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', flexDirection: 'row', height: 35 },
          dimensions.width
        )}
      >
        <>
          {!(props.isVerified ?? defaultProps.isVerified) ? null : (
            <Icon
              color={theme.colors.branding.primary}
              name={'MaterialIcons/verified'}
              size={18}
              style={StyleSheet.applyWidth(
                { marginRight: 4 },
                dimensions.width
              )}
            />
          )}
        </>
        {/* AddressTitle */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: props.titleColor ?? defaultProps.titleColor,
              fontFamily: 'Poppins_600SemiBold',
            }),
            dimensions.width
          )}
        >
          {props.title ?? defaultProps.title}
          {/* isPrimary */}
          <>
            {!(
              (props.isPrimary ?? defaultProps.isPrimary) &&
              (props.showPrimaryInformation ??
                defaultProps.showPrimaryInformation)
            ) ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        { minWidth: Breakpoints.Mobile, value: null },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: props.titleColor ?? defaultProps.titleColor,
                        },
                      ],
                      fontFamily: 'Poppins_600SemiBold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {' (Primary)'}
              </Text>
            )}
          </>
        </Text>
        <>
          {!isUpdating ? null : (
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'small'}
              {...GlobalStyles.ActivityIndicatorStyles(theme)[
                'Activity Indicator'
              ].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].style,
                  { height: 24, marginLeft: 6, width: 24 }
                ),
                dimensions.width
              )}
            />
          )}
        </>
        <>
          {!(
            props.showPrimaryInformation ?? defaultProps.showPrimaryInformation
          ) ? null : (
            <View>
              <>
                {isUpdating ||
                (props.isPrimary ?? defaultProps.isPrimary) ? null : (
                  <Link
                    accessible={true}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          trackAction(
                            Variables,
                            segment,
                            'Clicked Make Primary',
                            props.addressId ?? defaultProps.addressId
                          );
                          setIsUpdating(
                            props.addressId ?? defaultProps.addressId
                          );
                          const newPrimaryAddress = (
                            await xanoServiceUpdatePrimaryAddressPOST.mutateAsync(
                              {
                                address_id:
                                  props.addressId ?? defaultProps.addressId,
                              }
                            )
                          )?.json;
                          setIsUpdating(null);
                          setGlobalVariableValue({
                            key: 'PRIMARY_ADDRESS',
                            value: newPrimaryAddress,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    selectable={false}
                    {...GlobalStyles.LinkStyles(theme)['Link'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'].style,
                        { marginLeft: 6 }
                      ),
                      dimensions.width
                    )}
                    title={'Make Primary'}
                  />
                )}
              </>
            </View>
          )}
        </>
      </View>
      {/* Row1 */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row' },
          dimensions.width
        )}
      >
        {/* AddressText */}
        <>
          {!(props.house_number ?? defaultProps.house_number) ? null : (
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {props.house_number ?? defaultProps.house_number}
              {'  '}
              {props.road ?? defaultProps.road}{' '}
              {props.city ?? defaultProps.city}
              {', '}
              {props.state ?? defaultProps.state}{' '}
              {props.zip ?? defaultProps.zip}
              {'\n'}
              {props.country ?? defaultProps.country}
            </Text>
          )}
        </>
      </View>
    </View>
  );
};

export default withTheme(AddressDisplayBlock);
