import React from 'react';
import {
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  Swiper,
  SwiperItem,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import NarrowHeaderWithDrawerBlock from '../components/NarrowHeaderWithDrawerBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import isLoggedIn from '../global-functions/isLoggedIn';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { ur: null };

const BusinessSearchScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [currentlyTogglingId, setCurrentlyTogglingId] = React.useState(-1);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isInitialLoad, setIsInitialLoad] = React.useState(false);
  const [isSavingAddress, setIsSavingAddress] = React.useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = React.useState(false);
  const [roadValue, setRoadValue] = React.useState('');
  const [searchBoxFocused, setSearchBoxFocused] = React.useState(false);
  const [searchDisplayValue, setSearchDisplayValue] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedBusinessCategoryId, setSelectedBusinessCategoryId] =
    React.useState(0);
  const [titleBoxFocued, setTitleBoxFocued] = React.useState(false);
  const [titleValue, setTitleValue] = React.useState('');
  const [zipcodeValue, setZipcodeValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'business_search'} />
        )}
      </>
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <NarrowHeaderWithDrawerBlock title={'Saved'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="business_search"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: palettes.App.White,
            flex: 1,
            maxWidth: 1024,
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* SearchBusinessView */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', flexDirection: 'row', padding: 20 },
            dimensions.width
          )}
        >
          {/* InputView */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* SearchInput */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onBlur={() => {
                try {
                  setSearchBoxFocused(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              onChangeText={newSearchInputValue => {
                try {
                  setSearchDisplayValue(newSearchInputValue);
                  if (newSearchInputValue === '') {
                    setSearchBoxFocused(false);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              onChangeTextDelayed={newSearchInputValue => {
                try {
                  setSearchValue(newSearchInputValue);
                  if (searchDisplayValue) {
                    setSearchBoxFocused(true);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              onFocus={() => {
                try {
                  if (searchDisplayValue) {
                    setSearchBoxFocused(true);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              clearButtonMode={'while-editing'}
              placeholder={'Search for business'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    borderBottomLeftRadius: searchBoxFocused ? 0 : 8,
                    borderBottomRightRadius: searchBoxFocused ? 0 : 8,
                    marginTop: 0,
                    paddingRight: 35,
                  }
                ),
                dimensions.width
              )}
              value={searchDisplayValue}
            />
          </View>
        </View>
        {/* SearchResults */}
        <>
          {!searchBoxFocused ? null : (
            <Surface
              elevation={0}
              {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                  {
                    backgroundColor: palettes.App.CustomGray,
                    borderBottomLeftRadius: 12,
                    borderBottomRightRadius: 12,
                    left: 20,
                    maxHeight: 350,
                    maxWidth: 980,
                    minHeight: 0,
                    position: 'absolute',
                    top: 60,
                    width: dimensions.width - 40,
                    zIndex: 50,
                  }
                ),
                dimensions.width
              )}
            >
              <XanoServiceApi.FetchGetBusinessesGET search={searchValue}>
                {({ loading, error, data, refetchGetBusinesses }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', justifyContent: 'center' },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].style,
                              {
                                marginBottom: 15,
                                marginLeft: 15,
                                marginRight: 15,
                                marginTop: 15,
                              }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    );
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <SimpleStyleFlatList
                      data={fetchData}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'uPKlO0ED'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            onPress={() => {
                              try {
                                setSearchBoxFocused(false);
                                navigation.navigate('BusinessProfileScreen', {
                                  business_id:
                                    listData?._business_profile_of_user?.id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: palettes.App['Custom #dbdbdb'],
                                  flexDirection: 'row',
                                  margin: 8,
                                  marginTop: 0,
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={imageSource(
                                  `${listData?.logo?.attachment?.url}`
                                )}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      borderRadius: 40,
                                      height: 40,
                                      marginRight: 8,
                                      width: 40,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                adjustsFontSizeToFit={false}
                                ellipsizeMode={'tail'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      flex: 1,
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                textBreakStrategy={'highQuality'}
                              >
                                {listData?._business_profile_of_user?.name}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  );
                }}
              </XanoServiceApi.FetchGetBusinessesGET>
            </Surface>
          )}
        </>
        {/* Container */}
        <SimpleStyleKeyboardAwareScrollView
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          enableAutomaticScroll={true}
          enableOnAndroid={true}
          extraHeight={50}
          extraScrollHeight={200}
          style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
        >
          {/* PromotionsSlider */}
          <View>
            <PromotionsServiceApi.FetchGetPromotionsForSearchGET>
              {({ loading, error, data, refetchGetPromotionsForSearch }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <Swiper
                    data={fetchData}
                    keyExtractor={(swiperData, index) =>
                      swiperData?.id ??
                      swiperData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(swiperData)
                    }
                    listKey={'FakPJon5'}
                    minDistanceForAction={0.2}
                    minDistanceToCapture={5}
                    renderItem={({ item, index }) => {
                      const swiperData = item;
                      return (
                        <SwiperItem>
                          <Pressable
                            onPress={() => {
                              try {
                                navigation.navigate('ViewPromotionScreen', {
                                  hideBtn: true,
                                  PromotionId: swiperData?.id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <Image
                              resizeMode={'cover'}
                              {...GlobalStyles.ImageStyles(theme)['Image']
                                .props}
                              source={imageSource(
                                `${swiperData?.hero_image?.attachment?.url}`
                              )}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image']
                                    .style,
                                  { height: '100%', width: '100%' }
                                ),
                                dimensions.width
                              )}
                            />
                          </Pressable>
                        </SwiperItem>
                      );
                    }}
                    vertical={false}
                    {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
                    dotActiveColor={'rgba(0, 0, 0, 0)'}
                    dotColor={'rgba(0, 0, 0, 0)'}
                    dotsTouchable={true}
                    loop={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                        { height: 150 }
                      ),
                      dimensions.width
                    )}
                    timeout={5}
                  />
                );
              }}
            </PromotionsServiceApi.FetchGetPromotionsForSearchGET>
          </View>
          {/* Fetch 2 */}
          <XanoServiceApi.FetchGetBusinessesGET search={''}>
            {({ loading, error, data, refetchGetBusinesses }) => {
              const fetch2Data = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* RecomendedBusinesses */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 20, paddingRight: 20 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['TitleText'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['TitleText'].style,
                          {
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 16,
                            marginBottom: 12,
                            marginTop: 12,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Recommended Businesses'}
                    </Text>
                    <SimpleStyleFlatList
                      data={fetch2Data}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'FZvF0I13'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            onPress={() => {
                              try {
                                navigation.navigate('BusinessProfileScreen', {
                                  business_id:
                                    listData?._business_profile_of_user?.id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* BusinessCircularCard */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', marginRight: 15 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: theme.colors.border.brand,
                                    borderRadius: 50,
                                    borderWidth: 1,
                                    height: 100,
                                    overflow: 'hidden',
                                    width: 100,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={imageSource(
                                    `${listData?.logo?.attachment?.url}`
                                  )}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* Title */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    { fontFamily: 'Poppins_400Regular' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?._business_profile_of_user?.name}
                              </Text>
                              {/* Title 2 */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: theme.colors.foreground.brand,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {
                                  listData?._business_profile_of_user
                                    ?._business_categories?.name
                                }
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsVerticalScrollIndicator={true}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                    />
                  </View>
                </>
              );
            }}
          </XanoServiceApi.FetchGetBusinessesGET>
          {/* FindByCategory */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 20, paddingRight: 20 },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['TitleText'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['TitleText'].style,
                  {
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 16,
                    marginBottom: 12,
                    marginTop: 12,
                  }
                ),
                dimensions.width
              )}
            >
              {'Find By Category'}
            </Text>
            {/* BusinessCategoriesFetch */}
            <XanoServiceApi.FetchGetBussinessCategoriesGET excludeEmpty={false}>
              {({ loading, error, data, refetchGetBussinessCategories }) => {
                const businessCategoriesFetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={businessCategoriesFetchData}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'H3mVOIgK'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* Row */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: [
                                  { minWidth: Breakpoints.Mobile, value: 1 },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value:
                                      listData?.id ===
                                      selectedBusinessCategoryId
                                        ? 0
                                        : 1,
                                  },
                                ],
                                borderColor: theme.colors.border.brand,
                              },
                              dimensions.width
                            )}
                          >
                            <Pressable
                              onPress={() => {
                                try {
                                  if (
                                    selectedBusinessCategoryId === listData?.id
                                  ) {
                                    setSelectedBusinessCategoryId(0);
                                  } else {
                                    setSelectedBusinessCategoryId(listData?.id);
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* CategoryRow */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    height: 40,
                                    justifyContent: 'space-between',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['Custom black'],
                                        fontFamily: 'Poppins_400Regular',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.name}
                                </Text>
                                <Icon
                                  size={24}
                                  color={palettes.App['Custom black']}
                                  name={
                                    listData?.id === selectedBusinessCategoryId
                                      ? 'Entypo/chevron-small-up'
                                      : 'Entypo/chevron-small-down'
                                  }
                                />
                              </View>
                            </Pressable>
                            <>
                              {!(
                                listData?.id === selectedBusinessCategoryId
                              ) ? null : (
                                <View>
                                  <XanoServiceApi.FetchGetBusinessesByCategoryGET
                                    business_categories_id={
                                      selectedBusinessCategoryId
                                    }
                                  >
                                    {({
                                      loading,
                                      error,
                                      data,
                                      refetchGetBusinessesByCategory,
                                    }) => {
                                      const fetchData = data?.json;
                                      if (loading) {
                                        return <ActivityIndicator />;
                                      }

                                      if (
                                        error ||
                                        data?.status < 200 ||
                                        data?.status >= 300
                                      ) {
                                        return <ActivityIndicator />;
                                      }

                                      return (
                                        <>
                                          {/* SubList */}
                                          <SimpleStyleFlatList
                                            data={fetchData}
                                            horizontal={false}
                                            inverted={false}
                                            keyExtractor={(
                                              subListData,
                                              index
                                            ) =>
                                              subListData?.id ??
                                              subListData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(subListData)
                                            }
                                            keyboardShouldPersistTaps={'never'}
                                            listKey={JSON.stringify(fetchData)}
                                            nestedScrollEnabled={false}
                                            numColumns={1}
                                            onEndReachedThreshold={0.5}
                                            renderItem={({ item, index }) => {
                                              const subListData = item;
                                              return (
                                                <Pressable
                                                  onPress={() => {
                                                    try {
                                                      navigation.navigate(
                                                        'BusinessProfileScreen',
                                                        {
                                                          business_id:
                                                            subListData
                                                              ?._business_profile_of_user
                                                              ?.id,
                                                        }
                                                      );
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                >
                                                  {/* Detail View */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flex: 1,
                                                        flexDirection: 'row',
                                                        marginBottom: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flex: 1,
                                                          flexDirection: 'row',
                                                          justifyContent:
                                                            'flex-end',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* View 2 */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            flex: 1,
                                                            flexDirection:
                                                              'row',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Logo */}
                                                        <Image
                                                          {...GlobalStyles.ImageStyles(
                                                            theme
                                                          )['Image'].props}
                                                          resizeMode={'contain'}
                                                          source={imageSource(
                                                            `${subListData?.logo?.attachment?.url}`
                                                          )}
                                                          style={StyleSheet.applyWidth(
                                                            StyleSheet.compose(
                                                              GlobalStyles.ImageStyles(
                                                                theme
                                                              )['Image'].style,
                                                              {
                                                                borderColor:
                                                                  palettes.App
                                                                    .ViewBG,
                                                                borderRadius: 6,
                                                                borderWidth: 1,
                                                                height: 50,
                                                                marginRight: 10,
                                                                width: 50,
                                                              }
                                                            ),
                                                            dimensions.width
                                                          )}
                                                        />
                                                        <View>
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'].props}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )['Text'].style,
                                                                { fontSize: 18 }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              subListData
                                                                ?._business_profile_of_user
                                                                ?.name
                                                            }
                                                          </Text>
                                                          {/* Text 2 */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'].props}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )['Text'].style,
                                                                {
                                                                  color:
                                                                    theme.colors
                                                                      .text
                                                                      .light,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              subListData
                                                                ?._business_profile_of_user
                                                                ?._business_categories
                                                                ?.name
                                                            }
                                                          </Text>
                                                        </View>
                                                      </View>

                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            justifyContent:
                                                              'center',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Pressable
                                                          onPress={() => {
                                                            const handler =
                                                              async () => {
                                                                try {
                                                                  setCurrentlyTogglingId(
                                                                    subListData
                                                                      ?._business_profile_of_user
                                                                      ?.id
                                                                  );
                                                                  (
                                                                    await XanoServiceApi.toggleFollowGET(
                                                                      Constants,
                                                                      {
                                                                        business_id:
                                                                          subListData
                                                                            ?._business_profile_of_user
                                                                            ?.id,
                                                                      }
                                                                    )
                                                                  )?.json;
                                                                  setCurrentlyTogglingId(
                                                                    -1
                                                                  );
                                                                  await refetchGetBusinessesByCategory();
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              };
                                                            handler();
                                                          }}
                                                        >
                                                          <>
                                                            {!(
                                                              currentlyTogglingId !==
                                                              subListData
                                                                ?._business_profile_of_user
                                                                ?.id
                                                            ) ? null : (
                                                              <Image
                                                                resizeMode={
                                                                  'cover'
                                                                }
                                                                {...GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image']
                                                                  .props}
                                                                source={imageSource(
                                                                  subListData
                                                                    ?._business_profile_of_user
                                                                    ?._is_following
                                                                    ? Images[
                                                                        'unfollowicon'
                                                                      ]
                                                                    : Images[
                                                                        'followicon'
                                                                      ]
                                                                )}
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.ImageStyles(
                                                                      theme
                                                                    )['Image']
                                                                      .style,
                                                                    {
                                                                      height: 32,
                                                                      width: 32,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              />
                                                            )}
                                                          </>
                                                        </Pressable>
                                                        <>
                                                          {!(
                                                            currentlyTogglingId ===
                                                            subListData
                                                              ?._business_profile_of_user
                                                              ?.id
                                                          ) ? null : (
                                                            <ActivityIndicator
                                                              animating={true}
                                                              hidesWhenStopped={
                                                                true
                                                              }
                                                              size={'small'}
                                                              {...GlobalStyles.ActivityIndicatorStyles(
                                                                theme
                                                              )[
                                                                'Activity Indicator'
                                                              ].props}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.ActivityIndicatorStyles(
                                                                  theme
                                                                )[
                                                                  'Activity Indicator'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            />
                                                          )}
                                                        </>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </Pressable>
                                              );
                                            }}
                                            showsHorizontalScrollIndicator={
                                              true
                                            }
                                            showsVerticalScrollIndicator={true}
                                          />
                                        </>
                                      );
                                    }}
                                  </XanoServiceApi.FetchGetBusinessesByCategoryGET>
                                </View>
                              )}
                            </>
                          </View>
                        </>
                      );
                    }}
                    horizontal={false}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={false}
                  />
                );
              }}
            </XanoServiceApi.FetchGetBussinessCategoriesGET>
          </View>
        </SimpleStyleKeyboardAwareScrollView>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BusinessSearchScreen);
