import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as StripeServiceApi from '../apis/StripeServiceApi.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthBankAccountsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState('');
  const [selectedAccountData, setSelectedAccountData] = React.useState({});
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, ' Bank Accounts');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { justifyContent: 'space-around' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* BackButton */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['BackButton'].style,
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['Custom Color_9']}
              name={'Ionicons/chevron-back'}
              size={28}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                  { color: palettes.App.customGreen }
                ),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
      </View>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            flex: 1,
            justifyContent: 'center',
            marginBottom: 20,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 20,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontFamily: 'Poppins_500Medium',
              marginBottom: 20,
              marginTop: 40,
            }),
            dimensions.width
          )}
        >
          {
            'You can choose one of your bank accounts as your verified address in Mailboxx'
          }
        </Text>
        {/* Text 2 */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontFamily: 'Poppins_500Medium',
            }),
            dimensions.width
          )}
        >
          {'Accounts :'}
        </Text>

        <RadioButtonGroup
          onValueChange={newRadioButtonGroupValue => {
            try {
              setSelectedAccount(newRadioButtonGroupValue);
            } catch (err) {
              console.error(err);
            }
          }}
          value={selectedAccount}
        >
          <XanoServiceApi.FetchGetBankAccountsGET>
            {({ loading, error, data, refetchGetBankAccounts }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) =>
                    listData?.id ??
                    listData?.uuid ??
                    index?.toString() ??
                    JSON.stringify(listData)
                  }
                  keyboardShouldPersistTaps={'never'}
                  listKey={'IgtZcVCB'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setSelectedAccount(listData?.stripe_account_id);
                              setIsLoading(true);
                              const result = (
                                await StripeServiceApi.getAccountDetailsGET(
                                  Constants,
                                  {
                                    stripe_account_id:
                                      listData?.stripe_account_id,
                                  }
                                )
                              )?.json;
                              trackAction(
                                Variables,
                                segment,
                                'Clicked Bank Account',
                                listData?.display_name
                              );
                              setIsLoading(false);
                              setSelectedAccountData(result);
                              if (result?.message) {
                                setErrorMessage(result?.message);
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 12 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <RadioButton
                            color={theme.colors.branding.primary}
                            selectedIcon={'MaterialIcons/radio-button-checked'}
                            size={24}
                            unselectedColor={theme.colors.branding.primary}
                            unselectedIcon={
                              'MaterialIcons/radio-button-unchecked'
                            }
                            disabled={true}
                            value={listData?.stripe_account_id}
                          />
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {listData?.display_name}
                            {'-'}
                            {listData?.institution_name}
                          </Text>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              );
            }}
          </XanoServiceApi.FetchGetBankAccountsGET>
        </RadioButtonGroup>
        {/* SelectedAddressView */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderColor: theme.colors.text.light,
              borderRadius: 8,
              borderWidth: 1,
              padding: 16,
            },
            dimensions.width
          )}
        >
          <>
            {isLoading ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {'Account Address:\n\n'}
                {selectedAccountData?.raw_address}
              </Text>
            )}
          </>
          <>
            {!isLoading ? null : (
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                size={'small'}
                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].style,
                  dimensions.width
                )}
              />
            )}
          </>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'space-evenly' },
            dimensions.width
          )}
        >
          {/* SkipButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Skip Button',
                  undefined
                );
                navigation.navigate('AddressesScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors.background.brand,
                color: theme.colors.text.light,
                flexDirection: 'row',
                fontFamily: 'Poppins_500Medium',
                justifyContent: 'center',
                marginTop: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'SKIP'}
          >
            {'Sign Up'}
          </Button>
          {/* ContinueButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Continue Button',
                  undefined
                );
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            disabled={!selectedAccountData?.raw_address}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              dimensions.width
            )}
            title={'Continue'}
          />
        </View>
      </View>
      {/* ConfirmationModal */}
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={showConfirmModal}
      >
        {/* BGView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.ViewBG,
              flex: 1,
              justifyContent: 'center',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App.CustomGray,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* Modal Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.CustomGray,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_500Medium' }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure?'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowConfirmModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'Ionicons/close'}
              />
            </View>
            {/* Modal Body */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'If you skip this step, you will not be able to able benefit the advantages of Mailboxx. You can always establish this connection from the settings screen. \n\nAre you sure that you want to skip ? '
                }
              </Text>
            </View>
            {/* Modal Footer */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* CancelView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Cancel Button',
                        undefined
                      );
                      setShowConfirmModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* ConfirmView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Yes, Skip! Button',
                        undefined
                      );
                      setShowConfirmModal(false);
                      navigation.navigate('AddAddressScreen', {
                        isOnboarding: true,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: theme.colors.text.light }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Skip!'}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(AuthBankAccountsScreen);
