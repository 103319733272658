import React from 'react';
import {
  AccordionGroup,
  Circle,
  Icon,
  Pressable,
  SimpleStyleScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { currentScreen: 'business_search' };

const DrawerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const getButtonBGColor = screenName => {
    if ((props.currentScreen || 'business_search') === screenName)
      return palettes.App['PrimarySelected'];
  };

  return (
    <Surface
      {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
      elevation={3}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'].style, {
          backgroundColor: theme.colors.background.brand,
          borderBottomRightRadius: 25,
          borderTopRightRadius: 25,
          flex: 1,
          minWidth: 230,
        }),
        dimensions.width
      )}
    >
      {/* DrawerHeader */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors.branding.primary,
            borderTopRightRadius: 25,
            height: 150,
            padding: 16,
          },
          dimensions.width
        )}
      >
        <Circle
          {...GlobalStyles.CircleStyles(theme)['Circle'].props}
          size={70}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.CircleStyles(theme)['Circle'].style,
              { backgroundColor: palettes.App.White, overflow: 'hidden' }
            ),
            dimensions.width
          )}
        >
          <>
            {!Constants['USER_DATA']?.account_image?.attachment?.url ? null : (
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(
                  `${Constants['USER_DATA']?.account_image?.attachment?.url}`
                )}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  dimensions.width
                )}
              />
            )}
          </>
          <>
            {Constants['USER_DATA']?.account_image?.attachment?.url ? null : (
              <Icon
                color={palettes.App.CustomGray}
                name={'FontAwesome/user-circle'}
                size={55}
              />
            )}
          </>
        </Circle>

        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App.White,
              fontFamily: 'Poppins_700Bold',
              fontSize: 18,
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {Constants['USER_DATA']?.first_name}{' '}
          {Constants['USER_DATA']?.last_name}
        </Text>
        {/* BusinessProfileType */}
        <>
          {!(Constants['USER_DATA']?.role === 'business') ? null : (
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: palettes.App.White }
                ),
                dimensions.width
              )}
            >
              {'Business'}
            </Text>
          )}
        </>
      </View>
      {/* AdminProfileDrawerBody */}
      <>
        {!(Constants['USER_DATA']?.role === 'admin') ? null : (
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { paddingBottom: 16, paddingTop: 16 },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AdminDashboardScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Dashboard */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('dashboard'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'AntDesign/dashboard'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Dashboard'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AdminAddressApprovalScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* AddessApproval */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('addressapproval'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/stamper'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Address Approval'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BalanceScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Balance */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('balance'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Ionicons/wallet-outline'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AdminBusinessCategoriesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* BusinessCategories */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('business_categories'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Ionicons/business'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Business Categories'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Settings */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('settings'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'AntDesign/setting'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Settings'}
                </Text>
              </View>
            </Pressable>
          </SimpleStyleScrollView>
        )}
      </>
      {/* BusinessProfileDrawerBody */}
      <>
        {!(Constants['USER_DATA']?.role === 'business') ? null : (
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { paddingBottom: 16, paddingTop: 16 },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BusinessProfileScreen', {
                    business_id:
                      Constants['USER_DATA']?._business_profile_of_user?.id,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Business Profile */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('business_profile'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialIcons/business'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Business'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('MyPromotionsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyPromotions */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('mypromotions'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'AntDesign/tags'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Promotions'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AddressesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyAddresses */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('myaddresses'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Entypo/address'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Addresses'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BankAccountsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyBankAccounts */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('mybankaccounts'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/bank-outline'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Bank Accounts'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BalanceScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Balance */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('balance'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Ionicons/wallet-outline'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
            </Pressable>
            {/* Pressable 2 */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('ContactsImportScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* ContactsImport */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('contact_import'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/database-import'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Contacts Import'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Settings */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('settings'),
                    flexDirection: 'row',
                    marginBottom: 10,
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'AntDesign/setting'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Settings'}
                </Text>
              </View>
            </Pressable>
          </SimpleStyleScrollView>
        )}
      </>
      {/* UserProfileDrawerBody */}
      <>
        {!(Constants['USER_DATA']?.role === 'user') ? null : (
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { paddingBottom: 16, paddingTop: 16 },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('InboxScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Inbox */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: getButtonBGColor('inbox') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Feather/inbox'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Inbox'}
                </Text>
              </View>
            </Pressable>

            <AccordionGroup
              caretSize={24}
              iconSize={24}
              {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
              expanded={true}
              icon={'MaterialCommunityIcons/briefcase-outline'}
              label={'Businesses'}
              style={StyleSheet.applyWidth(
                GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    navigation.navigate('BusinessSearchScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* BusinessSearch */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                      {
                        backgroundColor: getButtonBGColor('business_search'),
                        paddingLeft: 32,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    name={'MaterialCommunityIcons/briefcase-search-outline'}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, zIndex: 1 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors.text.strong,
                          fontFamily: 'Poppins_400Regular',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Business Search'}
                  </Text>
                </View>
              </Pressable>

              <Pressable
                onPress={() => {
                  try {
                    navigation.navigate('BusinessFollowingScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* FollowingBusinesses */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                      {
                        backgroundColor: getButtonBGColor('business_following'),
                        paddingLeft: 32,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    name={'MaterialCommunityIcons/briefcase-plus-outline'}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, zIndex: 1 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors.text.strong,
                          fontFamily: 'Poppins_400Regular',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Following Businesses'}
                  </Text>
                </View>
              </Pressable>
            </AccordionGroup>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SavedScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* SavedPromotions */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: getButtonBGColor('saved') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Feather/bookmark'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Saved Promotions'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AddressesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyAddresses */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: getButtonBGColor('myaddresses') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Entypo/address'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Addresses'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BalanceScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Balance */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: getButtonBGColor('balance') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Ionicons/wallet-outline'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BankAccountsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyBankAccounts */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: getButtonBGColor('mybankaccounts') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/bank-outline'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Bank Accounts'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Settings */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: getButtonBGColor('settings') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'AntDesign/setting'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Settings'}
                </Text>
              </View>
            </Pressable>
          </SimpleStyleScrollView>
        )}
      </>
      {/* DrawerFooter */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomRightRadius: 25,
            borderColor: palettes.App.CustomGray,
            borderTopWidth: 1,
            overflow: 'hidden',
            padding: 16,
          },
          dimensions.width
        )}
      >
        <Pressable
          onPress={() => {
            try {
              setGlobalVariableValue({
                key: 'AuthToken',
                value: '',
              });
              setGlobalVariableValue({
                key: 'USER_DATA',
                value: '',
              });
              setGlobalVariableValue({
                key: 'PRIMARY_ADDRESS',
                value: {},
              });
              navigation.navigate('AuthLoginScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {/* Promotions */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={theme.colors.text.strong}
              name={'AntDesign/logout'}
              style={StyleSheet.applyWidth(
                { marginRight: 8 },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_600SemiBold',
                  }
                ),
                dimensions.width
              )}
            >
              {'Logout'}
            </Text>
          </View>
        </Pressable>
      </View>
    </Surface>
  );
};

export default withTheme(DrawerBlock);
