import React from 'react';
import ReelsPlayer from './ReelsPlayer';

export const ReelsWrapper = ({
  items,
  onEndReached,
  onPageChange,
  onClose,
}) => {
  return (
    <ReelsPlayer
      items={items || []}
      pauseOnOptionsShow={false}
      onPageChange={onPageChange}
      onEndReached={onEndReached}
      onClose={onClose}
    />
  );
};
