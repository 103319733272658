import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as StripeServiceApi from '../apis/StripeServiceApi.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import * as Stripe from '../custom-files/Stripe';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const BankAccountsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [bankAccountIdToDelete, setBankAccountIdToDelete] = React.useState('');
  const [bankAccountsCount, setBankAccountsCount] = React.useState(-1);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [refetchVal, setRefetchVal] = React.useState(0);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const xanoServiceDeleteBankAccountDELETE =
    XanoServiceApi.useDeleteBankAccountDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Bank Accounts');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'mybankaccounts'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="mybankaccounts"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: 1,
            maxWidth: 1024,
            paddingTop: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
          <XanoServiceApi.FetchGetBankAccountsGET
            handlers={{
              onData: fetchData => {
                try {
                  setBankAccountsCount(fetchData?.length);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchGetBankAccounts }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* EmptyView */}
                  <>
                    {!(bankAccountsCount === 0) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            justifyContent: 'center',
                            padding: 20,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'Poppins_300Light',
                                fontSize: 18,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'You currently have no stripe connect accounts '}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!(fetchData?.length > 0) ? null : (
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'6aBEW0ew'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* ListRow */}
                              <Surface
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                elevation={2}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      alignItems: 'center',
                                      backgroundColor: palettes.App.White,
                                      borderRadius: 12,
                                      flex: 1,
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      marginBottom: 10,
                                      marginLeft: 20,
                                      marginRight: 20,
                                      marginTop: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { padding: 10 },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Title */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'TitleText'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'TitleText'
                                          ].style,
                                          { marginBottom: 12 }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.display_name}{' '}
                                    </Text>
                                  </View>
                                  {/* InstitutionNameText */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.institution_name}
                                    {/* SubcategoryText */}
                                    <>
                                      {!listData?.subcategory ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {', '}
                                          {listData?.subcategory}
                                        </Text>
                                      )}
                                    </>
                                  </Text>
                                </View>
                                {/* Actions */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  {/* DeleteButton */}
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        trackAction(
                                          Variables,
                                          segment,
                                          'Clicked Delete Icon',
                                          listData?.id
                                        );
                                        setBankAccountIdToDelete(listData?.id);
                                        setShowDeleteModal(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    size={32}
                                    icon={'Ionicons/trash-outline'}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 8 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </Surface>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    )}
                  </>
                </>
              );
            }}
          </XanoServiceApi.FetchGetBankAccountsGET>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'flex-end', padding: 20 },
            dimensions.width
          )}
        >
          <StripeServiceApi.FetchCreateSessionGET refetchSeed={refetchVal}>
            {({ loading, error, data, refetchCreateSession }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { color: theme.colors.background.danger }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'An error occured while generating the session key. Please contact support'
                    }
                  </Text>
                );
              }

              return (
                <>
                  {/* ErrorMessage */}
                  <>
                    {!errorMessage ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors.background.danger }
                          ),
                          dimensions.width
                        )}
                      >
                        {errorMessage}
                      </Text>
                    )}
                  </>
                  {/* StripeConnect */}
                  <Utils.CustomCodeErrorBoundary>
                    <Stripe.ConnectButton
                      theme={props.theme}
                      navigation={props.navigation}
                      clientSecret={fetchData['client_secret']}
                      setErrorMessage={setErrorMessage}
                      onCancel={() =>
                        setRefetchVal(
                          Math.floor(100000 + Math.random() * 900000)
                        )
                      }
                    />
                  </Utils.CustomCodeErrorBoundary>
                </>
              );
            }}
          </StripeServiceApi.FetchCreateSessionGET>
        </View>
      </View>

      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={showDeleteModal}
      >
        {/* BGView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.ViewBG,
              flex: 1,
              justifyContent: 'center',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App.CustomGray,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* Modal Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.CustomGray,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_500Medium' }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure?'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowDeleteModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'Ionicons/close'}
              />
            </View>
            {/* Modal Body */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'You are about to delete this bank account connection with Mailboxx. Are you sure that you want to continue? '
                }
              </Text>
            </View>
            {/* Modal Footer */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* CancelView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Cancel Button',
                        undefined
                      );
                      setShowDeleteModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* ConfirmView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        (
                          await xanoServiceDeleteBankAccountDELETE.mutateAsync({
                            bank_account_id: bankAccountIdToDelete,
                          })
                        )?.json;
                        setIsDeleting(false);
                        setShowDeleteModal(false);
                        trackAction(
                          Variables,
                          segment,
                          'Deleted Bank Account',
                          bankAccountIdToDelete
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: theme.colors.background.danger }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Delete!'}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(BankAccountsScreen);
