import palettes from '../themes/palettes';
import React, { useState, useRef, useEffect } from 'react';
import { View } from 'react-native';
import PhoneInput from 'react-native-international-phone-number';

export function CountryPickerComponent({
  setNumberValid,
  setNumber,
  number,
  theme,
}) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [inputValue, setInputValue] = useState(number);

  function handleInputValue(phoneNumber) {
    setInputValue(phoneNumber);
    //console.log('phoneNumber', `${selectedCountry?.callingCode}${ phoneNumber.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll('+','').replaceAll(' ','')}`)
    setNumber(
      `${selectedCountry?.callingCode}${phoneNumber
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
        .replaceAll('+', '')
        .replaceAll(' ', '')}`
    );
  }

  function handleSelectedCountry(country) {
    setSelectedCountry(country);
  }

  return (
    <View style={{ flex: 1 }}>
      <PhoneInput
        value={inputValue}
        defaultCountry="US"
        placeholder="Phone number"
        modalSearchInputPlaceholder="Search for country"
        modalNotFoundCountryMessage="Could not find the country"
        onChangePhoneNumber={handleInputValue}
        selectedCountry={selectedCountry}
        onChangeSelectedCountry={handleSelectedCountry}
        phoneInputStyles={{
          //starts
          container: {
            backgroundColor: '#F4F4F4FF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#F4F4F4FF',
            marginTop: 20,
          },
          flagContainer: {
            backgroundColor: '#F4F4F4FF',
            justifyContent: 'center',
            //  paddingHorizontal: 4,
          },
          divider: {
            paddingHorizontal: 0,
          },
          callingCode: {
            fontSize: 14,
            fontFamily: 'Poppins_200ExtraLight',
            color: theme.colors.text.strong,
          },
          caret: {
            marginTop: -5,
          },
          input: {
            color: theme.colors.text.strong,
            fontSize: 14,
            paddingHorizontal: 4,
            fontWeight: '200',
            fontFamily: 'Poppins_200ExtraLight',
          },
        }}
      />
    </View>
  );
}
