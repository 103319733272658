import React from 'react';
import {
  Button,
  Icon,
  Link,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthForgetPasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [emailValue, setEmailValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [otpValue, setOtpValue] = React.useState('');
  const [sentOtpCode, setSentOtpCode] = React.useState(false);
  const [statusText, setStatusText] = React.useState('');
  const verifyOtp = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailValue.length < 1) {
      setErrorMessage('Please enter email');
      return false;
    }

    if (!emailRegex.test(emailValue)) {
      setErrorMessage('Please enter valid email');
      return false;
    }

    if (otpValue.length < 6) {
      setErrorMessage('Please enter a valid verification code');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const validForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailValue.length < 1) {
      setErrorMessage('Please enter email');
      return false;
    }

    if (!emailRegex.test(emailValue.trim())) {
      setErrorMessage('Please enter valid email');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const xanoServiceForgetPasswordInitiatePOST =
    XanoServiceApi.useForgetPasswordInitiatePOST();
  const xanoServiceForgotPasswordVerifyPOST =
    XanoServiceApi.useForgotPasswordVerifyPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Forget Password');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.White },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  height: [
                    { minWidth: Breakpoints.Mobile, value: 30 },
                    { minWidth: Breakpoints.Tablet, value: 40 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: 120 },
                    { minWidth: Breakpoints.Tablet, value: 160 },
                  ],
                }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* BackButton */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['BackButton'].style,
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['Custom Color_9']}
              name={'Ionicons/chevron-back'}
              size={28}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                  { color: palettes.App.customGreen }
                ),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
      </View>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            flex: { minWidth: Breakpoints.Desktop, value: 1 },
            justifyContent: { minWidth: Breakpoints.Desktop, value: 'center' },
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: 32,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Forgot Password'}
        </Text>

        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: 18,
              marginTop: 10,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'We’ll send you a link to reset your password.'}
        </Text>
        {/* EmailInput */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newEmailInputValue => {
            try {
              setEmailValue(newEmailInputValue);
            } catch (err) {
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          keyboardType={'email-address'}
          placeholder={'Email'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 40 }
            ),
            dimensions.width
          )}
          value={emailValue}
        />
        {/* Text 2 */}
        <>
          {!statusText ? null : (
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { marginTop: 10, textAlign: 'center' }
                ),
                dimensions.width
              )}
            >
              {statusText}
            </Text>
          )}
        </>
        {/* OTPInput */}
        <>
          {!sentOtpCode ? null : (
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newOTPInputValue => {
                try {
                  setOtpValue(newOTPInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              keyboardType={'numeric'}
              maxLength={6}
              placeholder={'Verification Code'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  { marginTop: 20 }
                ),
                dimensions.width
              )}
              value={otpValue}
            />
          )}
        </>
        {/* ResetPassword */}
        <>
          {sentOtpCode ? null : (
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (!validForm()) {
                      return;
                    }
                    setIsLoading(true);
                    const result = (
                      await xanoServiceForgetPasswordInitiatePOST.mutateAsync({
                        email: emailValue,
                      })
                    )?.json;
                    setIsLoading(false);
                    setStatusText(result?.status);
                    if (result?.message) {
                      setErrorMessage(result?.message);
                    }
                    if (!result?.message) {
                      setSentOtpCode(true);
                    }
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Reset Password Button',
                      emailValue
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              disabled={isLoading}
              loading={isLoading}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  { marginTop: 20 }
                ),
                dimensions.width
              )}
              title={'Reset Password'}
            />
          )}
        </>
        {/* VerifyOtp */}
        <>
          {!sentOtpCode ? null : (
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (!verifyOtp()) {
                      return;
                    }
                    setIsLoading(true);
                    const result = (
                      await xanoServiceForgotPasswordVerifyPOST.mutateAsync({
                        code: otpValue,
                        email: emailValue,
                      })
                    )?.json;
                    setIsLoading(false);
                    if (result?.message) {
                      setErrorMessage(result?.message);
                    }
                    if (result?.message) {
                      return;
                    }
                    setGlobalVariableValue({
                      key: 'AuthToken',
                      value: result?.authToken,
                    });
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Verify Code Button',
                      emailValue
                    );
                    navigation.navigate('AuthForgetPasswordUpdateScreen');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              disabled={isLoading}
              loading={isLoading}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  { marginTop: 20 }
                ),
                dimensions.width
              )}
              title={'Verify Code'}
            />
          )}
        </>
        {/* errorView */}
        <>
          {!errorMessage ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['errorView 2'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['errorView 2'].style,
                dimensions.width
              )}
            >
              {/* errorText */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.danger,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {errorMessage}
              </Text>
            </View>
          )}
        </>
      </View>
      {/* Footer */}
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', height: 150, justifyContent: 'center' },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.text.strong,
              fontSize: { minWidth: Breakpoints.Laptop, value: 20 },
            }),
            dimensions.width
          )}
        >
          {'Already have an account?'}
        </Text>
        {/* LogInButton */}
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('AuthLoginScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          selectable={false}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              alignSelf: 'center',
              fontSize: [
                { minWidth: Breakpoints.Mobile, value: 18 },
                { minWidth: Breakpoints.Laptop, value: 26 },
              ],
              textAlign: 'center',
            }),
            dimensions.width
          )}
          title={'Log In'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthForgetPasswordScreen);
