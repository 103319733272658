import React from 'react';
import { Button, Pressable, Surface, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { Image, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  business: {
    id: 75,
    dob: null,
    logo: {
      id: 93,
      user_id: 75,
      adobe_urn: '',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/5YF43HVsXxyfVxcVg88m6ymKABA/2q1eQA../file-f3f42a.png',
        meta: { width: 500, height: 500 },
        mime: 'image/png',
        name: 'file-f3f42a.png',
        path: '/vault/cH9onT_o/5YF43HVsXxyfVxcVg88m6ymKABA/2q1eQA../file-f3f42a.png',
        size: 16708,
        type: 'image',
        access: 'public',
      },
      created_at: 1723728438257,
    },
    role: 'business',
    email: 'business@test.com',
    gender: '',
    is_active: true,
    last_name: 'Business',
    created_at: 1723713316382,
    first_name: 'Test',
    push_token: null,
    phone_number: '+11231231212',
    account_image: 93,
    secondary_email: 'business2@test.com',
    stripe_customer_id: 'cus_QfKLWVfC4pmeDL',
    _business_profile_of_user: {
      id: 10,
      name: 'Test',
      user_id: 75,
      created_at: 1723713316394,
      ein_number: '87654321',
      header_image: {
        id: 91,
        user_id: 75,
        adobe_urn: '',
        attachment: {
          url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/7Q5AJK2Fggo9V01vktd6knHKrmI/vpmP_Q../file-a60cb6.png',
          meta: { width: 940, height: 480 },
          mime: 'image/png',
          name: 'file-a60cb6.png',
          path: '/vault/cH9onT_o/7Q5AJK2Fggo9V01vktd6knHKrmI/vpmP_Q../file-a60cb6.png',
          size: 212253,
          type: 'image',
          access: 'public',
        },
        created_at: 1723725012263,
      },
      _business_categories: {
        id: 5,
        name: 'Beauty',
        order: 5,
        created_at: 1692966562226,
      },
      _following_users_count: 30,
    },
  },
};

const BusinessCardBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const segment = useAnalytics();
  const [businessItem, setBusinessItem] = React.useState(
    props.business ?? defaultProps.business
  );

  return (
    <View
      onLayout={event => {
        try {
          setBusinessItem(props.business ?? defaultProps.business);
        } catch (err) {
          console.error(err);
        }
      }}
    >
      {/* CARD */}
      <Surface
        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
        elevation={2}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            {
              borderColor: palettes.App['Custom #dbdbdb'],
              borderRadius: 6,
              borderWidth: 1,
              minHeight: 250,
              overflow: 'hidden',
              zIndex: 1,
            }
          ),
          dimensions.width
        )}
      >
        <Pressable
          onPress={() => {
            try {
              navigation.navigate('BusinessProfileScreen', {
                business_id: businessItem?._business_profile_of_user?.id,
              });
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(
              `${businessItem?._business_profile_of_user.header_image.attachment.url}`
            )}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 180, width: '100%' }
              ),
              dimensions.width
            )}
          />
          {/* BusinessDetailsView */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', padding: 12 },
              dimensions.width
            )}
          >
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* BusinessTitle */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.text.strong,
                      fontFamily: 'Poppins_600SemiBold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {businessItem?._business_profile_of_user.name}
                <>
                  {!(
                    businessItem?._business_profile_of_user
                      ._following_users_count > 10
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { color: palettes.App['Custom black'], fontSize: 12 }
                        ),
                        dimensions.width
                      )}
                    >
                      {' - '}
                      {
                        businessItem?._business_profile_of_user
                          ._following_users_count
                      }
                      {' followers'}
                    </Text>
                  )}
                </>
              </Text>
              {/* BusinessTitle 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors.text.strong }
                  ),
                  dimensions.width
                )}
              >
                {
                  businessItem?._business_profile_of_user._business_categories
                    .name
                }
              </Text>
            </View>
            {/* View 2 */}
            <View></View>
          </View>
        </Pressable>
      </Surface>
    </View>
  );
};

export default withTheme(BusinessCardBlock);
