import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { style: { height: 1 }, props: {} } });

export const SwiperStyles = theme =>
  StyleSheet.create({
    Swiper: { style: { height: 300, width: '100%' }, props: {} },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
  });

export const TabViewItemStyles = theme =>
  StyleSheet.create({ 'Tab View Item': { style: { flex: 1 }, props: {} } });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const ViewStyles = theme =>
  StyleSheet.create({
    AbsoluteTitle: {
      style: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        zIndex: 0,
      },
      props: {},
    },
    'Address Display 3': { style: { padding: 10 }, props: {} },
    'Address Display 4': { style: { padding: 10 }, props: {} },
    DashboardCol: {
      style: {
        backgroundColor: palettes.App.White,
        borderRadius: 16,
        flex: 1,
        paddingBottom: 12,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 12,
      },
      props: {},
    },
    DashboardRow: {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: 12,
        marginRight: 12,
        marginTop: 12,
      },
      props: {},
    },
    DobView: {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderRadius: 8,
        justifyContent: 'center',
        marginTop: 20,
        overflow: 'hidden',
        padding: 8,
      },
      props: {},
    },
    DrawerButton: {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
      },
      props: {},
    },
    FilterView: {
      style: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 10,
      },
      props: {},
    },
    Header: {
      style: {
        alignItems: 'center',
        alignSelf: 'auto',
        backgroundColor: theme.colors.background.brand,
        borderBottomWidth: 0.5,
        borderColor: theme.colors.border.brand,
        flexDirection: 'row',
        height: 50,
        justifyContent: 'space-between',
        zIndex: 50,
      },
      props: {},
    },
    'Image Selector': { style: { alignItems: 'center' }, props: {} },
    ImagePickerView: { style: { alignItems: 'center', width: 70 }, props: {} },
    ResponsiveBody: {
      style: {
        alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
        backgroundColor: theme.colors.background.brand,
        flex: 1,
        maxWidth: 1024,
        width: '100%',
      },
      props: {},
    },
    ScreenRow: {
      style: {
        backgroundColor: {
          minWidth: Breakpoints.Tablet,
          value: theme.colors.background.danger,
        },
        width: { minWidth: Breakpoints.Tablet, value: '100%' },
      },
      props: {},
    },
    SettingsRow: {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 16,
      },
      props: {},
    },
    WideMenuButtonView: {
      style: { alignItems: 'center', flexDirection: 'row', padding: 12 },
      props: {},
    },
    errorView: {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderRadius: 12,
        marginTop: '10%',
        padding: 10,
      },
      props: {},
    },
    'errorView 2': {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderRadius: 12,
        marginTop: 20,
        padding: 10,
      },
      props: {},
    },
    'errorView 3': {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderRadius: 12,
        marginTop: '10%',
        padding: 10,
      },
      props: {},
    },
    'first row': {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      props: {},
    },
  });

export const PickerStyles = theme =>
  StyleSheet.create({
    PickerStyle: {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderColor: palettes.App.CustomGray,
        fontFamily: 'Poppins_200ExtraLight',
        fontSize: 14,
        paddingLeft: -8,
      },
      props: {},
    },
  });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    PromotionCard: {
      style: {
        alignItems: 'center',
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 12,
        flex: 1,
        justifyContent: 'center',
        marginRight: 6,
        minHeight: 40,
        overflow: 'hidden',
      },
      props: {},
    },
    Surface: { style: { minHeight: 40 }, props: {} },
  });

export const LinearGradientStyles = theme =>
  StyleSheet.create({ 'Linear Gradient': { style: { flex: 1 }, props: {} } });

export const MapViewStyles = theme =>
  StyleSheet.create({
    'Map View': {
      style: { flex: 1, height: '100%', width: '100%' },
      props: {},
    },
  });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({
    'Activity Indicator': { style: { height: 36, width: 36 }, props: {} },
  });

export const CircleStyles = theme =>
  StyleSheet.create({
    Circle: {
      style: { alignItems: 'center', justifyContent: 'center' },
      props: {},
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Area': {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRadius: 8,
        borderRightWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Poppins_200ExtraLight',
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    'Text Input': {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderRadius: 8,
        fontFamily: 'Poppins_200ExtraLight',
        height: 40,
        padding: 8,
      },
      props: {},
    },
  });

export const VideoPlayerStyles = theme =>
  StyleSheet.create({ Video: { style: { height: 215 }, props: {} } });

export const PressableStyles = theme =>
  StyleSheet.create({
    BackButton: { style: { marginLeft: 10 }, props: {} },
    SelectAllButton: { style: { marginBottom: 10 }, props: {} },
  });

export const SliderStyles = theme =>
  StyleSheet.create({
    Slider: { style: { marginLeft: 12, marginRight: 12 }, props: {} },
  });

export const H4Styles = theme =>
  StyleSheet.create({
    H4: {
      style: {
        color: theme.colors.text.strong,
        fontFamily: 'Poppins_400Regular',
        fontSize: 16,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const H2Styles = theme =>
  StyleSheet.create({
    H2: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 24,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const TableStyles = theme =>
  StyleSheet.create({ Table: { style: { flex: 1 }, props: {} } });

export const TableCellStyles = theme =>
  StyleSheet.create({
    'Table Cell': { style: { flex: 1, flexDirection: 'row' }, props: {} },
  });

export const IconButtonStyles = theme =>
  StyleSheet.create({
    MenuButton: {
      style: {
        marginLeft: 10,
        marginRight: 10,
        position: 'relative',
        zIndex: 1,
      },
      props: {},
    },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    CardDetailsLabel: {
      style: {
        color: theme.colors.branding.primary,
        flex: 0.25,
        fontFamily: 'Poppins_500Medium',
        fontSize: 12,
        marginRight: 6,
        textAlign: 'right',
      },
      props: {},
    },
    HeaderTitleText: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_300Light',
        fontSize: 18,
        textAlign: 'center',
      },
      props: {},
    },
    Text: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_200ExtraLight',
        fontSize: 14,
      },
      props: {},
    },
    TitleText: {
      style: {
        color: theme.colors.text.strong,
        fontFamily: 'Poppins_500Medium',
      },
      props: {},
    },
    WideHeaderText: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_200ExtraLight',
      },
      props: {},
    },
  });

export const H3Styles = theme =>
  StyleSheet.create({
    H3: {
      style: {
        color: theme.colors.text.strong,
        fontFamily: [
          { minWidth: Breakpoints.Mobile, value: 'Poppins_400Regular' },
          { minWidth: Breakpoints.Desktop, value: 'Poppins_300Light' },
        ],
        fontSize: 18.72,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: { fontFamily: 'Poppins_500Medium', textAlign: 'center' },
      props: {},
    },
    OutlineButton: {
      style: {
        backgroundColor: '"rgba(0, 0, 0, 0)"',
        borderColor: theme.colors.branding.primary,
        borderWidth: 1,
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_500Medium',
        textAlign: 'center',
      },
      props: {},
    },
    PrimaryButton: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        fontFamily: 'Poppins_500Medium',
        marginTop: 20,
        textAlign: 'center',
      },
      props: {},
    },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_200ExtraLight',
      },
      props: {},
    },
  });

export const NumberInputStyles = theme =>
  StyleSheet.create({
    'Number Input': {
      style: {
        backgroundColor: palettes.App.CustomGray,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRadius: 8,
        borderRightWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Poppins_200ExtraLight',
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
  });

export const BlurViewStyles = theme =>
  StyleSheet.create({
    'Blur View': {
      style: { flexBasis: 0, flexGrow: 1, flexShrink: 1 },
      props: {},
    },
  });

export const IconStyles = theme =>
  StyleSheet.create({
    WideHeaderIcon: { style: { marginRight: 8, zIndex: 1 }, props: {} },
  });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({
    'Checkbox Row': {
      style: {
        color: {
          minWidth: Breakpoints.Desktop,
          value: theme.colors.text.strong,
        },
        fontFamily: {
          minWidth: Breakpoints.Desktop,
          value: 'Poppins_400Regular',
        },
        minHeight: 50,
      },
      props: {},
    },
  });

export const TabViewStyles = theme =>
  StyleSheet.create({
    TabView: { style: { flex: 1, fontFamily: 'Poppins_500Medium' }, props: {} },
  });

export const VStackStyles = theme =>
  StyleSheet.create({
    'V Stack': { style: { flexDirection: 'column' }, props: {} },
  });

export const AccordionGroupStyles = theme =>
  StyleSheet.create({
    Accordion: {
      style: {
        fontFamily: 'Poppins_400Regular',
        fontSize: 14,
        marginBottom: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
      },
      props: {
        closedColor: theme.colors.text.strong,
        openColor: palettes.App.Black,
      },
    },
  });

export const SwitchRowStyles = theme =>
  StyleSheet.create({
    'Switch Row': { style: { marginLeft: 0, marginRight: 0 }, props: {} },
  });

export const SwipeableItemStyles = theme =>
  StyleSheet.create({
    'Swipeable Item': { style: { overflow: 'hidden' }, props: {} },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({
    'HTML View': { style: { flex: 1 }, props: {} },
    'Web View': { style: { flex: 1 }, props: {} },
  });
