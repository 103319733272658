import React from 'react';
import {
  Circle,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TabView,
  TabViewItem,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Keyboard, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BalanceServiceApi from '../apis/BalanceServiceApi.js';
import * as StripeServiceApi from '../apis/StripeServiceApi.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import * as ScreenComponents from '../custom-files/ScreenComponents';
import centToDollar from '../global-functions/centToDollar';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const BalanceScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [availableBalance, setAvailableBalance] = React.useState(0);
  const [checkingConnectedAccount, setCheckingConnectedAccount] =
    React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const totalBalance = (balance, reservedBalance) => {
    return parseInt(balance) + parseInt(reservedBalance);
  };

  const groupByMonth = objects => {
    const groupedObjects = {};

    objects.forEach(object => {
      const createdAt = new Date(object.created_at);
      const month = createdAt.toLocaleString('en-US', { month: 'long' });

      if (!groupedObjects[month]) {
        groupedObjects[month] = [];
      }

      groupedObjects[month].push(object);
    });

    // console.log(Object.keys(groupedObjects).map(key=>{

    //   return {
    //     title : key,
    //     items : groupedObjects[key]
    //   }
    // }))
    return Object.keys(groupedObjects).map(key => {
      return {
        title: key,
        items: groupedObjects[key],
      };
    });
  };

  const toTimestamp = days => {
    if (days === 0) {
      return Math.floor(Date.now() / 86400000) * 86400000 + 86399999;
    } else {
      const currentDate = new Date();
      const targetDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + days
      );
      return Math.floor(targetDate.getTime() / 86400000) * 86400000;
    }

    // Explanation:
    // - The function `getTimestamp` takes one parameter `days`.
    // - If `days` is equal to 0, it returns the current timestamp value rounded down to the nearest day (00:00 of the current day).
    // - Otherwise, it calculates the target date by adding `days` to the current date and creates a new `Date` object.
    // - It then returns the timestamp value of the target date rounded down to the nearest day (00:00 of the target day).
  };

  const verifyWithdraw = (role, balance) => {
    if (role === 'user' && balance < 1000) {
      setErrorMessage('You must have at least $10 in your wallet to withdraw');
      return false;
    }
    // if(role ==='business'){
    //     setErrorMessage('Please contact with help@mailboxx.app to withdrow your balance')
    //     return false
    // }
    setErrorMessage('');
    return true;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Balance');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'balance'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="balance"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: [
              { minWidth: Breakpoints.Mobile, value: palettes.App.CustomGray },
              {
                minWidth: Breakpoints.Desktop,
                value: theme.colors.background.brand,
              },
            ],
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.background.brand,
              flex: 1,
              maxWidth: 1024,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <BalanceServiceApi.FetchGetBalanceGET
            handlers={{
              onData: fetchData => {
                try {
                  setAvailableBalance(fetchData?.balance);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchGetBalance }) => {
              const fetchData = data?.json;
              if (loading) {
                return (
                  <>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', height: 70, marginTop: 40 },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {'Gettin Balances...'}
                      </Text>
                    </View>
                  </>
                );
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* TotalBalance */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', height: 70, marginTop: 40 },
                      dimensions.width
                    )}
                  >
                    {/* label */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { color: palettes.App.customGreen }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Total Balance'}
                    </Text>
                    {/* value */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontSize: 28 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'$'}
                      {centToDollar(
                        totalBalance(
                          fetchData?.balance,
                          fetchData?.reservedbalance
                        )
                      )}
                    </Text>
                  </View>
                  {/* Row */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-evenly' },
                      dimensions.width
                    )}
                  >
                    {/* AvailableBalance */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', height: 70 },
                        dimensions.width
                      )}
                    >
                      {/* label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 11 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Available Balance'}
                      </Text>
                      {/* value */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 22 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'$'}
                        {centToDollar(fetchData?.balance)}
                      </Text>
                    </View>
                    {/* ReservedBalance */}
                    <>
                      {!(fetchData?.reservedbalance > 0) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', height: 70 },
                            dimensions.width
                          )}
                        >
                          {/* label */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { fontSize: 11 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Reserved Balance'}
                          </Text>
                          {/* value */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { fontSize: 22 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'$'}
                            {centToDollar(fetchData?.reservedbalance)}
                          </Text>
                        </View>
                      )}
                    </>
                  </View>
                </>
              );
            }}
          </BalanceServiceApi.FetchGetBalanceGET>
          {/* Actions */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            {/* AddBalancePressable */}
            <Pressable
              onPress={() => {
                try {
                  trackAction(
                    Variables,
                    segment,
                    'Clicked Add Credit Button',
                    undefined
                  );
                  navigation.navigate('BalanceUpdateScreen', {
                    mode: 'credit',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: 70 },
                  dimensions.width
                )}
              >
                <Circle
                  {...GlobalStyles.CircleStyles(theme)['Circle'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)['Circle'].style,
                      {
                        backgroundColor: palettes.App.CustomGray,
                        height: 100,
                        width: 100,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors.branding.primary}
                    name={'Entypo/circle-with-plus'}
                    size={50}
                  />
                </Circle>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { marginTop: 5, textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Add Credit'}
                </Text>
              </View>
            </Pressable>
            {/* WithdrawBalancePressable */}
            <Pressable
              onPress={() => {
                const handler = async () => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Transfer Balance Button',
                      undefined
                    );
                    setCheckingConnectedAccount(true);
                    const getConnectAccountResult = (
                      await XanoServiceApi.getConnectAccountGET(Constants)
                    )?.json;
                    if (getConnectAccountResult?.stripe_account_id) {
                      navigation.navigate('BalanceUpdateScreen', {
                        mode: 'debit',
                      });
                    } else {
                      const isVerified = verifyWithdraw(
                        Constants['USER_DATA']?.role,
                        availableBalance
                      );
                      if (isVerified) {
                        const createConnectAccountResult = (
                          await StripeServiceApi.createConnectAccountGET(
                            Constants
                          )
                        )?.json;
                        await WebBrowser.openBrowserAsync(
                          `${createConnectAccountResult?.url}`
                        );
                      } else {
                      }
                    }

                    setCheckingConnectedAccount(false);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: 70 },
                  dimensions.width
                )}
              >
                <>
                  {!checkingConnectedAccount ? null : (
                    <Circle
                      {...GlobalStyles.CircleStyles(theme)['Circle'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.CircleStyles(theme)['Circle'].style,
                          {
                            backgroundColor: palettes.App.CustomGray,
                            height: 100,
                            width: 100,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        size={'large'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                    </Circle>
                  )}
                </>
                <>
                  {checkingConnectedAccount ? null : (
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['Transfers'])}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        dimensions.width
                      )}
                    />
                  )}
                </>
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { marginTop: 5, textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Transfer Balance'}
                </Text>
              </View>
            </Pressable>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                marginBottom: 10,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors.background.danger, textAlign: 'center' }
                ),
                dimensions.width
              )}
            >
              {errorMessage}
            </Text>
          </View>
          {/* Lists */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, marginTop: 10 },
              dimensions.width
            )}
          >
            <TabView
              iconPosition={'top'}
              initialTabIndex={0}
              keyboardDismissMode={'auto'}
              pressColor={theme.colors.branding.primary}
              scrollEnabled={false}
              swipeEnabled={true}
              tabBarPosition={'top'}
              tabsBackgroundColor={theme.colors.background.brand}
              {...GlobalStyles.TabViewStyles(theme)['TabView'].props}
              activeColor={palettes.App.customGreen}
              indicatorColor={palettes.App.customGreen}
              style={StyleSheet.applyWidth(
                GlobalStyles.TabViewStyles(theme)['TabView'].style,
                dimensions.width
              )}
            >
              <TabViewItem
                {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                  dimensions.width
                )}
                title={'Week'}
              >
                {/* WeekView */}
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <BalanceServiceApi.FetchGetTransactionsGET
                    from={toTimestamp(-7)}
                    to={toTimestamp(0)}
                  >
                    {({ loading, error, data, refetchGetTransactions }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <SimpleStyleFlatList
                          data={groupByMonth(fetchData)}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'jaaLMwb6'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* MonthView */}
                                <View>
                                  {/* TitleView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors.border.brand,
                                        padding: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.title}
                                    </Text>
                                  </View>
                                  <SimpleStyleFlatList
                                    data={listData?.items}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(listData?.items)}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <>
                                          {/* TransactionRow */}
                                          <Utils.CustomCodeErrorBoundary>
                                            <ScreenComponents.TransactionRow
                                              item={listData}
                                              navigation={props.navigation}
                                            />
                                          </Utils.CustomCodeErrorBoundary>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      );
                    }}
                  </BalanceServiceApi.FetchGetTransactionsGET>
                </View>
              </TabViewItem>
              {/* Tab View Item 2 */}
              <TabViewItem
                {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                  dimensions.width
                )}
                title={'Month'}
              >
                {/* MonthView */}
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <BalanceServiceApi.FetchGetTransactionsGET
                    from={toTimestamp(-31)}
                    to={toTimestamp(0)}
                  >
                    {({ loading, error, data, refetchGetTransactions }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <SimpleStyleFlatList
                          data={groupByMonth(fetchData)}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'XbTDsUGl'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* MonthView */}
                                <View>
                                  {/* TitleView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors.border.brand,
                                        padding: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.title}
                                    </Text>
                                  </View>
                                  <SimpleStyleFlatList
                                    data={listData?.items}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(listData?.items)}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <>
                                          {/* TransactionRow */}
                                          <Utils.CustomCodeErrorBoundary>
                                            <ScreenComponents.TransactionRow
                                              item={listData}
                                              navigation={props.navigation}
                                            />
                                          </Utils.CustomCodeErrorBoundary>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      );
                    }}
                  </BalanceServiceApi.FetchGetTransactionsGET>
                </View>
              </TabViewItem>
              {/* Tab View Item 3 */}
              <TabViewItem
                {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                  dimensions.width
                )}
                title={'Year'}
              >
                {/* YearView */}
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <BalanceServiceApi.FetchGetTransactionsGET
                    from={toTimestamp(-366)}
                    to={toTimestamp(0)}
                  >
                    {({ loading, error, data, refetchGetTransactions }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <SimpleStyleFlatList
                          data={groupByMonth(fetchData)}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'B2kRuGfO'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* MonthView */}
                                <View>
                                  {/* TitleView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors.border.brand,
                                        padding: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.title}
                                    </Text>
                                  </View>
                                  <SimpleStyleFlatList
                                    data={listData?.items}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(listData?.items)}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <>
                                          {/* TransactionRow */}
                                          <Utils.CustomCodeErrorBoundary>
                                            <ScreenComponents.TransactionRow
                                              item={listData}
                                            />
                                          </Utils.CustomCodeErrorBoundary>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      );
                    }}
                  </BalanceServiceApi.FetchGetTransactionsGET>
                </View>
              </TabViewItem>
            </TabView>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BalanceScreen);
