import React, { useState } from 'react';
import { View, Text, ActivityIndicator, Alert, Platform } from 'react-native';
import { Button } from '@draftbit/ui';

import * as XanoServiceApi from '../apis/XanoServiceApi.js';

import * as DocumentPicker from 'expo-document-picker';
import * as GlobalVariableContext from '../config/GlobalVariableContext';
import * as GlobalStyles from '../GlobalStyles.js';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

import * as ShowMessage from './ShowMessage';

export const Wrapper = ({ theme, onComplete }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const variables = GlobalVariableContext.useValues();
  const dimensions = useWindowDimensions();

  // Function to pick a file
  const pickFile = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: 'text/csv',
      });

      if (result.assets?.length > 0) {
        setFile(result.assets[0]);
        // console.log('Selected file:', result.assets[0]);
      }
    } catch (err) {
      Alert.alert('No file selected', 'Error while picking a file.');
      ShowMessage.myMessage({
        message: 'Error while picking a file.',
        type: 'error',
      });
      console.log('Error while picking a file:', err);
    }
  };

  // Function to upload the file using fetch
  const uploadFile = async () => {
    if (!file) {
      Alert.alert('No file selected', 'Please select a CSV file to upload.');
      ShowMessage.myMessage({
        message: 'Please select a CSV file to upload.',
        type: 'warning',
      });
      return;
    }

    setUploading(true);

    try {
      let responseData;

      if (Platform.OS === 'web') {
        responseData = (
          await XanoServiceApi.importContactsPOST(variables, {
            csvFile: file.uri,
          })
        )?.json;
      } else {
        console.log('calling native select');
        const formData = new FormData();

        formData.append('csvFile', {
          uri: file.uri,
          name: file.name,
          type: 'text/csv',
        });

        console.log('formData', formData);

        response = await fetch(
          'https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/import_contacts',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: variables['AuthToken'],
              'X-Data-Source': variables['DATASOURCE'],
            },
            body: formData,
          }
        );
        responseData = await response.json();
      }

      // const responseData = await response.json();
      console.log('resposneData', responseData);
      if (!responseData.message) {
        setFile(null);
        onComplete?.();
      } else {
        Alert.alert(
          'Upload failed',
          responseData.message || 'Error while uploading file.'
        );
        ShowMessage.myMessage({
          message: responseData.message || 'Error while uploading file.',
          type: 'error',
        });
      }
    } catch (error) {
      console.log('Error', error);
      Alert.alert('Upload failed', 'Error while uploading file.');
      ShowMessage.myMessage({
        message: 'Error while uploading file.',
        type: 'error',
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <View
      style={{ flex: 1, justifyContent: 'center', width: 240, padding: 20 }}
    >
      {!file && (
        <Button
          iconPosition={'left'}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              { width: 200 }
            ),
            dimensions.width
          )}
          title={'Choose File'}
          onPress={pickFile}
        />
      )}

      {file && (
        <Text style={{ marginTop: 20, paddingBottom: 8 }}>
          Selected File: {file.name}
        </Text>
      )}

      {uploading ? (
        <ActivityIndicator
          size="large"
          color="#0000ff"
          style={{ marginTop: 20 }}
        />
      ) : (
        file && (
          <Button
            iconPosition={'left'}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                { width: 200 }
              ),
              dimensions.width
            )}
            title={'Upload File'}
            onPress={uploadFile}
          />
        )
      )}
    </View>
  );
};
