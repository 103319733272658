import React from 'react';
import {
  Icon,
  IconButton,
  Pressable,
  SwipeableItem,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as MailslurpAPiApi from '../apis/MailslurpAPiApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as DateUtils from '../utils/DateUtils';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  email: {
    cc: [],
    id: 'cc7d1b35-9fb5-4f8f-b711-c4beacb7d7e6',
    to: ['sefa@mailslurp.net'],
    bcc: [],
    from: 'sefa@draftbit.com',
    read: false,
    sender: {
      name: 'Sefa Okumus',
      rawValue: 'Sefa Okumus <sefa@draftbit.com>',
      emailAddress: 'sefa@draftbit.com',
    },
    created: '2024-10-17T11:28:17.313Z',
    inboxId: '702e363b-92ce-4b4a-b64b-b96bc83e6887',
    subject: 'Fwd: 🚀 Your Mailboxx app for Android has been published!',
    domainId: null,
    threadId: '78e6fd30-f126-472f-8f85-8375bc3dc993',
    createdAt: '2024-10-17T11:28:17.313Z',
    favourite: null,
    inReplyTo: '20241017083209.18b7ee5e85127388@cio51088.draftbit.com',
    messageId:
      'CALtPpbXa6BTUFTkadPThgG6iL7JF=xfkBnqjjkS3q4xJ2ryN6g@mail.gmail.com',
    recipients: {
      cc: [],
      to: [
        {
          name: null,
          rawValue: 'sefa@mailslurp.net',
          emailAddress: 'sefa@mailslurp.net',
        },
      ],
      bcc: [],
    },
    attachments: [],
    plusAddress: null,
    bodyPartContentTypes: [
      'text/plain; charset="UTF-8"',
      'text/html; charset="UTF-8"',
    ],
  },
};

const InboxRowBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [showMailDetails, setShowMailDetails] = React.useState(false);

  return (
    <View>
      <SwipeableItem
        closeOnPress={true}
        friction={20}
        onSwipedLeft={swipedIndex => {
          const handler = async () => {
            try {
              (
                await MailslurpAPiApi.deleteEmailDELETE(Constants, {
                  email_id: (props.email ?? defaultProps.email)?.id,
                })
              )?.json;
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
        onSwipedRight={swipedIndex => {
          const handler = async () => {
            try {
              (
                await MailslurpAPiApi.toggleReadGET(Constants, {
                  email_id: (props.email ?? defaultProps.email)?.id,
                  is_read: true,
                })
              )?.json;
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
        swipeActivationPercentage={80}
        swipeToClosePercent={50}
        swipeToOpenPercent={50}
        {...GlobalStyles.SwipeableItemStyles(theme)['Swipeable Item'].props}
        leftSwipeBackgroundColor={theme.colors.text.danger}
        leftSwipeIcon={'FontAwesome/trash-o'}
        leftSwipeTitle={'Delete'}
        rightSwipeBackgroundColor={theme.colors.branding.primary}
        rightSwipeIcon={'MaterialCommunityIcons/read'}
        rightSwipeTitle={'Mark As Read'}
        style={StyleSheet.applyWidth(
          GlobalStyles.SwipeableItemStyles(theme)['Swipeable Item'].style,
          dimensions.width
        )}
      >
        <Pressable
          onLongPress={() => {
            try {
              setShowMailDetails(true);
            } catch (err) {
              console.error(err);
            }
          }}
          delayLongPress={50}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            {/* LeftView */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: '65%' },
                dimensions.width
              )}
            >
              {/* Sender */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.text.strong,
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Poppins_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: (props.email ?? defaultProps.email)?.read
                            ? 'Poppins_400Regular'
                            : 'Poppins_600SemiBold',
                        },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {!(props.email ?? defaultProps.email)?.read ? '*' : ''}
                {(props.email ?? defaultProps.email)?.sender?.name}
              </Text>
              {/* Subject */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors.text.strong }
                  ),
                  dimensions.width
                )}
              >
                {(props.email ?? defaultProps.email)?.subject}
              </Text>
            </View>
            {/* RightView */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', gap: 6, justifyContent: 'flex-end' },
                dimensions.width
              )}
            >
              <>
                {(props.email ?? defaultProps.email)?.attachments?.length >
                0 ? null : (
                  <Icon
                    color={theme.colors.text.medium}
                    name={'MaterialCommunityIcons/attachment'}
                    size={20}
                  />
                )}
              </>
              {/* Date */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors.text.strong }
                  ),
                  dimensions.width
                )}
              >
                {DateUtils.formatDistanceToNow(
                  new Date((props.email ?? defaultProps.email)?.createdAt)
                )}
              </Text>
            </View>
          </View>
        </Pressable>
      </SwipeableItem>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'formSheet'}
        transparent={false}
        visible={showMailDetails}
      >
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, padding: 16 },
            dimensions.width
          )}
        >
          {/* ModalHeader */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-end' },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  setShowMailDetails(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              color={palettes.Brand.Strong}
              icon={'AntDesign/closecircleo'}
              size={30}
            />
          </View>

          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            <MailslurpAPiApi.FetchGetMailBodyGET
              email_id={(props.email ?? defaultProps.email)?.id}
            >
              {({ loading, error, data, refetchGetMailBody }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <WebView
                    allowFileAccessFromFileURLs={false}
                    allowUniversalAccessFromFileURLs={false}
                    cacheEnabled={true}
                    incognito={false}
                    javaScriptCanOpenWindowsAutomatically={false}
                    javaScriptEnabled={true}
                    mediaPlaybackRequiresUserAction={false}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    startInLoadingState={false}
                    {...GlobalStyles.WebViewStyles(theme)['HTML View'].props}
                    source={{ html: `${fetchData}` }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.WebViewStyles(theme)['HTML View'].style,
                        { borderWidth: 0 }
                      ),
                      dimensions.width
                    )}
                  />
                );
              }}
            </MailslurpAPiApi.FetchGetMailBodyGET>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withTheme(InboxRowBlock);
