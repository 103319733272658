import React from 'react';
import {
  AccordionGroup,
  Circle,
  Icon,
  IconButton,
  Pressable,
  SimpleStyleScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import BalanceButtonBlock from '../components/BalanceButtonBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  balance: '',
  currentScreen: 'mypromotions',
  withGoBack: false,
};

const WideHeaderBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const customNavigate = route => {
    props.navigation.navigate(route);
  };

  const getButtonBGColor = screenName => {
    if (props.currentScreen === screenName)
      return palettes.App['PrimarySelected'];
  };

  return (
    <Surface
      elevation={0}
      {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'].style, {
          alignItems: 'center',
          backgroundColor: theme.colors.background.brand,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          flexDirection: 'row',
          zIndex: 999,
        }),
        dimensions.width
      )}
    >
      <>
        {!showDropdown ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                left: 180,
                position: 'absolute',
                top: 50,
                zIndex: 1000,
              },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BusinessSearchScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* BusinessSearch */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: 'business_search', paddingLeft: 32 }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors.branding.primary}
                  name={'MaterialCommunityIcons/briefcase-search-outline'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_400Regular' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Business Search'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BusinessFollowingScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* FollowingBusinesses */}
              <View
                {...GlobalStyles.ViewStyles(theme)['DrawerButton'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['DrawerButton'].style,
                    { backgroundColor: 'business_following', paddingLeft: 32 }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors.branding.primary}
                  name={'MaterialCommunityIcons/briefcase-plus-outline'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_400Regular' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Following Businesses'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* DrawerHeader */}
      <View
        style={StyleSheet.applyWidth(
          { paddingLeft: 12, paddingRight: 16, width: 80 },
          dimensions.width
        )}
      >
        <>
          {props.withGoBack ?? defaultProps.withGoBack ? null : (
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['LogoOnly'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 40, width: 40 }
                ),
                dimensions.width
              )}
            />
          )}
        </>
        <>
          {!(props.withGoBack ?? defaultProps.withGoBack) ? null : (
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={theme.colors.branding.primary}
              icon={'Ionicons/arrow-back-circle-outline'}
            />
          )}
        </>
      </View>
      {/* AdminProfileDrawerBody */}
      <>
        {!(Constants['USER_DATA']?.role === 'admin') ? null : (
          <SimpleStyleScrollView
            bounces={true}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AdminDashboardScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Dashboard */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('dashboard'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors.branding.primary}
                  name={'AntDesign/dashboard'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8, zIndex: 1 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Dashboard'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AdminAddressApprovalScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* AddessApproval */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('addressapproval'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'MaterialCommunityIcons/stamper'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Address Approval'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BalanceScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Balance */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('balance'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Ionicons/wallet-outline'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AdminBusinessCategoriesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* BusinessCategories */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('business_categories'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Ionicons/business'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Business Categories'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Settings */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('settings'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'AntDesign/setting'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                      { fontFamily: 'Poppins_400Regular' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Settings'}
                </Text>
              </View>
            </Pressable>
          </SimpleStyleScrollView>
        )}
      </>
      {/* BusinessProfileDrawerBody */}
      <>
        {!(Constants['USER_DATA']?.role === 'business') ? null : (
          <SimpleStyleScrollView
            bounces={true}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BusinessProfileScreen', {
                    business_id:
                      Constants['USER_DATA']?._business_profile_of_user?.id,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyBusinessProfile */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('business_profile'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'MaterialIcons/business'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                      { color: palettes.App.PrimaryDark }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Business'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('MyPromotionsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyPromotions */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('mypromotions'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'AntDesign/tags'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                      { color: palettes.App.PrimaryDark }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Promotions'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AddressesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyAddresses */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('myaddresses'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Entypo/address'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'My Addresses'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BankAccountsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyBankAccounts */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('mybankaccounts'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'MaterialCommunityIcons/bank-outline'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'My Bank Accounts'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BalanceScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Balance */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('balance'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Ionicons/wallet-outline'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Settings */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: getButtonBGColor('settings'),
                    flexDirection: 'row',
                    padding: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'AntDesign/setting'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Settings'}
                </Text>
              </View>
            </Pressable>
          </SimpleStyleScrollView>
        )}
      </>
      {/* UserProfileDrawerBody */}
      <>
        {!(Constants['USER_DATA']?.role === 'user') ? null : (
          <SimpleStyleScrollView
            bounces={true}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('InboxScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Inbox */}
              <View
                {...GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].style,
                    { backgroundColor: getButtonBGColor('inbox') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Feather/inbox'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Inbox'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  setShowDropdown(!showDropdown);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Business */}
              <View
                {...GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].style,
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'MaterialCommunityIcons/briefcase-outline'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Business'}
                </Text>
                {/* Icon 2 */}
                <Icon
                  size={24}
                  color={theme.colors.branding.primary}
                  name={
                    showDropdown
                      ? 'Entypo/chevron-small-up'
                      : 'Entypo/chevron-small-down'
                  }
                />
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SavedScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* SavedPromotions */}
              <View
                {...GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].style,
                    { backgroundColor: getButtonBGColor('saved') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Feather/bookmark'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Saved Promotions'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('AddressesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyAddresses */}
              <View
                {...GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].style,
                    { backgroundColor: getButtonBGColor('myaddresses') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Entypo/address'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'My Addresses'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BalanceScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Balance */}
              <View
                {...GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].style,
                    { backgroundColor: getButtonBGColor('balance') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'Ionicons/wallet-outline'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BankAccountsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* MyBankAccounts */}
              <View
                {...GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].style,
                    { backgroundColor: getButtonBGColor('mybankaccounts') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'MaterialCommunityIcons/bank-outline'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'My Bank Accounts'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Settings */}
              <View
                {...GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['WideMenuButtonView'].style,
                    { backgroundColor: getButtonBGColor('settings') }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
                  color={theme.colors.branding.primary}
                  name={'AntDesign/setting'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                    dimensions.width
                  )}
                >
                  {'Settings'}
                </Text>
              </View>
            </Pressable>
          </SimpleStyleScrollView>
        )}
      </>
      {/* DrawerFooter */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            gap: { minWidth: Breakpoints.Desktop, value: 12 },
            overflow: 'hidden',
            paddingLeft: 12,
            paddingRight: 12,
          },
          dimensions.width
        )}
      >
        <>
          {!((props.balance ?? defaultProps.balance) > 0) ? null : (
            <BalanceButtonBlock
              availableBalance={props.balance ?? defaultProps.balance}
            />
          )}
        </>
        {/* Circle 2 */}
        <Circle
          {...GlobalStyles.CircleStyles(theme)['Circle'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.CircleStyles(theme)['Circle'].style,
              {
                backgroundColor: theme.colors.text.medium,
                borderColor: palettes.App.PrimarySelected,
                borderWidth: 2,
                height: 27,
                marginRight: 6,
                overflow: 'hidden',
                width: 27,
              }
            ),
            dimensions.width
          )}
        >
          <>
            {!Constants['USER_DATA']?.account_image?.attachment?.url ? null : (
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(
                  `${Constants['USER_DATA']?.account_image?.attachment?.url}`
                )}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    {
                      borderColor: {
                        minWidth: Breakpoints.Desktop,
                        value: palettes.App.PrimaryDark,
                      },
                      borderWidth: { minWidth: Breakpoints.Desktop, value: 1 },
                      height: 30,
                      width: 30,
                    }
                  ),
                  dimensions.width
                )}
              />
            )}
          </>
          <>
            {Constants['USER_DATA']?.account_image?.attachment?.url ? null : (
              <Icon
                color={palettes.App.CustomGray}
                name={'FontAwesome/user-circle'}
                size={30}
              />
            )}
          </>
        </Circle>

        <Pressable
          onPress={() => {
            try {
              setGlobalVariableValue({
                key: 'AuthToken',
                value: '',
              });
              setGlobalVariableValue({
                key: 'USER_DATA',
                value: '',
              });
              setGlobalVariableValue({
                key: 'PRIMARY_ADDRESS',
                value: {},
              });
              navigation.navigate('AuthLoginScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {/* Promotions */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              {...GlobalStyles.IconStyles(theme)['WideHeaderIcon'].props}
              color={theme.colors.branding.primary}
              name={'AntDesign/logout'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconStyles(theme)['WideHeaderIcon'].style,
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                dimensions.width
              )}
            >
              {'Logout'}
            </Text>
          </View>
        </Pressable>
      </View>
    </Surface>
  );
};

export default withTheme(WideHeaderBlock);
